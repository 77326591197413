import React from "react";
import { Controller } from "react-hook-form";
import DmmAutocomplete from "../field/DmmAutocomplete";

interface DmmAutocompleteWrapperProps {
  name: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  inputStyle?: any;
  outlineStyle?: any;
  variant?: any;
  label?: string;
  disabled?: boolean;
}

const DmmAutocompleteWrapper: React.FC<DmmAutocompleteWrapperProps> = ({
  name,
  control,
  error,
  errorMessage,
  inputStyle,
  outlineStyle,
  variant,
  label,
  disabled,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <DmmAutocomplete
        selected={field.value}
        setSelected={(value: any) => field.onChange(value)}
        inputValue={field.value?.address || ""}
        setInputValue={(value: string) =>
          field.onChange({ ...field.value, address: value })
        }
        label={label}
        error={error}
        errorMessage={errorMessage}
        inputStyle={inputStyle}
        outlineStyle={outlineStyle}
        variant={variant}
        disabled={disabled}
        onBlur={field.onBlur}
        inputProps={{}}
        placeholder="Search location"
      />
    )}
  />
);

export default DmmAutocompleteWrapper;
