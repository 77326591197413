import React, { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import axios from "axios";

const useGetReviews = (id: any) => {
  const { state, dispatch } = useAuth();

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const getReviews = async () => {
      try {
        console.log("result", id);
        if (state?.userId && id) {
          const result = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/review/getCarReviews/${id}`
          );

          setReviews(result?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getReviews();
  }, [id, state?.userId]);

  return { reviews };
};

export default useGetReviews;
