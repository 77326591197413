import React from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import WebDealSkeletonCard from "../skeletons/WebDealSkeletonCard";
import WebDealCard from "./WebDealCard";
import { Deal } from "../topdeal/TodaysTopDeals";

export interface DealCardsProps {
  showPreviousPage: () => void;
  itemsPerPage: number;
  currentPage?: number;
  displayedItems: Deal[];
  showNextPage: () => void;
}

const WebDealCards: React.FC<DealCardsProps> = ({
  showPreviousPage,
  itemsPerPage,
  displayedItems,
  showNextPage,
}) => {
  return (
    <div className="w-full max-lg:hidden">
      <div className="flex items-center justify-center w-full lg:overflow-x-none overflow-x-auto gap-4">
        <button onClick={showPreviousPage} type="button" title="button">
          <IoIosArrowDropleftCircle className="w-10 h-10 text-[#BFC4CD]" />
        </button>

        <div className="w-full flex flex-col justify-center items-center">
          <h1 className="font-medium text-xl lg:text-2xl text-left w-full mx-auto">
            Today's Top Deals
          </h1>

          <div className={`grid grid-cols-${itemsPerPage} gap-5 w-full`}>
            {/* Conditionally render skeleton component if loading */}
            {!displayedItems.length
              ? Array.from({ length: itemsPerPage }).map((_, index) => (
                  <WebDealSkeletonCard key={index} />
                ))
              : displayedItems.map((deal: Deal, index) => (
                  <WebDealCard key={deal.id || index} deal={deal} />
                ))}
          </div>
        </div>

        <button onClick={showNextPage} type="button" title="button">
          <IoIosArrowDroprightCircle className="w-10 h-10 text-[#BFC4CD]" />
        </button>
      </div>
    </div>
  );
};

export default WebDealCards;
