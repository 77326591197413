import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { handleRefresh } from "../../modal/LoginDialog";

interface AccountMenuItemsProps {
  navigate: (path: string) => void;
  handleLogout: () => void;
}

const AccountMenuItems: React.FC<AccountMenuItemsProps> = ({
  navigate,
  handleLogout,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <div>
          <button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            type="button"
            className="text-amber bg-white-700 border-[#0e1427] border rounded-full px-6 py-2"
            onClick={handleClick}
          >
            Account
          </button>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="mt-2"
        >
          <MenuItem
            className="w-[110px]"
            onClick={() => {
              navigate("/profile/wallet");

              handleClose();
            }}
          >
            Profile
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleLogout();
              handleClose();
              handleRefresh();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default AccountMenuItems;
