import React from "react";
import { Skeleton, Box } from "@mui/material";

const CarDetailsSkeleton = () => {
  return (
    <Box width="100%" p={2}>
      <Box display="flex" alignItems="center">
        <Skeleton variant="text" width={250} height={50} />
      </Box>

      <Box display="flex" mb={2}>
        <Skeleton variant="text" width={300} height={30} />
      </Box>

      <Skeleton variant="text" width={50} height={20} />
    </Box>
  );
};

export default CarDetailsSkeleton;
