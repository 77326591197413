import React, { FC, useEffect, FormEvent } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import FieldSelect, {
  booleanOptions,
} from "../../components/inputs/FieldSelect";
import FieldAutocomplete from "../../components/inputs/FieldAutocomplete";
import FieldCheckbox from "../../components/inputs/FieldCheckbox";
import FieldCurrency from "../../components/inputs/FieldCurrency";
import { removeNairaAndConvertToInt } from "../../utils/RemoveNairaAndConvertToInt";
import {
  daysAvailable,
  noticePeriod,
  serviceTypesWithId,
} from "../../utils/constants";

interface handleNextClickProps {
  errors: any;
  control: any;
  watch: any;
  setValue: any;
}

export interface ServiceType {
  createdAt: string;
  isReturned: boolean;
  name: string;
  _id: string;
  price?: number;
  uniqueId: string;
  isQuoteRequired: boolean;
}

const ServicesPricing: FC<handleNextClickProps> = ({
  errors,
  control,
  watch,
  setValue,
}) => {
  const selectedServices = watch("selectedServices.services", []);
  const canDmmSetPrice = watch("selectedServices.allowDMMSetPrice", false);

  useEffect(() => {
    const currentServices = watch("serviceTypes") || [];

    selectedServices.forEach((serviceName: string) => {
      const service = serviceTypesWithId.find(
        (service) => service.name === serviceName
      );

      if (
        service &&
        !currentServices.some(
          (s: { uniqueId: string; _id: string }) =>
            s.uniqueId === service.uniqueId || s._id === service._id
        )
      ) {
        setValue("serviceTypes", [
          ...currentServices,
          {
            ...service,
            price: watch(`selectedServices.${service.uniqueId}`) || 0,
          },
        ]);
      }
    });
  }, [selectedServices, setValue, watch]);

  const handleServicePriceChange = (serviceId: string, price: string) => {
    const service = serviceTypesWithId.find(
      (service) => service.uniqueId === serviceId
    );

    if (service) {
      const updatedService = {
        ...service,
        price: removeNairaAndConvertToInt(price),
      };

      const currentServices = watch("serviceTypes") || [];
      const updatedServices = currentServices.filter(
        (s: { uniqueId: string }) => s.uniqueId !== serviceId
      );
      setValue("serviceTypes", [...updatedServices, updatedService]);
    }
  };

  return (
    <div className=" w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader header="Service and pricing details" />

      <div className=" w-full grid lg:grid-cols-2 grid-cols-1 gap-4  opacity-70   justify-items-center justify-center ">
        <div className="flex flex-col  lg:w-full w-full gap-4">
          <FieldSelect
            options={Object.entries(booleanOptions).map(([key, value]) => ({
              value: value,
              label: key,
            }))}
            errors={errors}
            label="Availability"
            fieldName="availability"
            control={control}
          />

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={Object.values(daysAvailable)}
            fieldName="daysAvailable"
            label="Days availables"
            placeholder="Select days available"
          />
        </div>

        <div className="flex flex-col  lg:w-full w-full gap-4">
          <FieldSelect
            options={noticePeriod.map((value) => ({
              value: value,
              label: value,
            }))}
            errors={errors}
            label="Notice period"
            fieldName="noticePeriod"
            control={control}
          />

          <FieldAutocomplete
            control={control}
            errors={errors}
            options={serviceTypesWithId.map((service) => service.name)}
            fieldName="selectedServices.services"
            onChange={(e: FormEvent, value: string[]) => {
              const selectedServiceIds = value
                .map((serviceName) => {
                  const service = serviceTypesWithId.find(
                    (service) => service.name === serviceName
                  );

                  return service ? service.uniqueId : null;
                })
                .filter((uniqueId) => uniqueId !== null);

              setValue("selectedServices.servicesIds", selectedServiceIds);
            }}
            label="Subscribed service(s)"
            placeholder="Select services"
            errMessage={errors?.selectedServices?.services?.message}
          />

          {selectedServices.map((serviceName: string) => {
            const service = serviceTypesWithId.find(
              (service) => service.name === serviceName
            );

            return service && !service.isQuoteRequired && !canDmmSetPrice ? (
              <FieldCurrency
                key={service.uniqueId}
                control={control}
                errors={errors}
                name={`selectedServices.${service.uniqueId}`}
                label={`${service.name} Price`}
                errMessage={
                  errors?.selectedServices?.[`${service.uniqueId}`]?.message
                }
                onChange={(e: any) => {
                  handleServicePriceChange(service.uniqueId, e.target?.value);
                }}
              />
            ) : null;
          })}

          <div className="flex items-center gap-2 ">
            <FieldCheckbox
              control={control}
              name="selectedServices.allowDMMSetPrice"
              label="Allow DMM set price"
              errors={errors}
            />

            <FieldCheckbox
              control={control}
              name="allowOffer"
              label="Allow client offer a price"
              errors={errors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPricing;
