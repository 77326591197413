import React from "react";

const EmptyData = ({ message = "No Data" }) => {
  return (
    <div className="w-full h-full flex flex-col gap-4 justify-center items-center">
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto"
      >
        <g clipPath="url(#clip0_4388_52446)">
          <path
            d="M79.1783 16.1138H10.5322C9.60606 16.1143 8.71803 16.4825 8.06317 17.1374C7.40831 17.7922 7.04015 18.6802 7.03958 19.6064V82.5898C7.04015 83.5159 7.40831 84.404 8.06317 85.0588C8.71803 85.7137 9.60606 86.0818 10.5322 86.0824H79.1783C80.1045 86.0819 80.9926 85.7138 81.6475 85.059C82.3025 84.4041 82.6707 83.516 82.6713 82.5898V19.6064C82.6707 18.6802 82.3025 17.7921 81.6475 17.1372C80.9926 16.4824 80.1045 16.1142 79.1783 16.1138Z"
            fill="#C6C6C6"
          />
          <path
            d="M74.6594 64.1959H15.052C13.1232 64.1959 11.5594 62.6321 11.5594 60.7033V5.05411C11.5594 3.12533 13.1232 1.56152 15.052 1.56152H74.6594C76.5881 1.56152 78.1519 3.12533 78.1519 5.05411V60.7033C78.1519 62.6321 76.5881 64.1959 74.6594 64.1959Z"
            fill="#F9F9F9"
          />
          <path
            d="M12.6652 61.8087V6.15988C12.6652 4.2311 14.229 2.66729 16.1577 2.66729H75.7651C76.4464 2.66729 77.0797 2.86529 77.6167 3.20251C76.9993 2.21792 75.9072 1.56152 74.6594 1.56152H15.052C13.1232 1.56152 11.5594 3.12533 11.5594 5.05411V60.7033C11.5594 61.9515 12.2158 63.0432 13.2004 63.661C12.8632 63.1237 12.6652 62.4903 12.6652 61.8091V61.8087Z"
            fill="url(#paint0_linear_4388_52446)"
          />
          <path
            d="M67.3253 13.3193H22.3865V15.8804H67.3253V13.3193ZM67.3253 23.0982H22.3865V25.6592H67.3253V23.0982ZM67.3253 32.8791H22.3865V35.4402H67.3253V32.8791ZM67.3253 42.658H22.3865V45.2193H67.3253V42.658Z"
            fill="white"
          />
          <path
            d="M27.7417 34.3916L39.0342 45.6845H27.7417V34.3916Z"
            fill="url(#paint1_linear_4388_52446)"
          />
          <path
            d="M82.6715 46.6162V20.6335L78.1521 16.1138V46.6159H82.6715V46.6162Z"
            fill="#C6C6C6"
          />
          <path
            d="M87.965 48.9661L83.0958 83.4347C82.8524 85.1579 81.3776 86.4386 79.6375 86.4386H10.0735C8.33331 86.4386 6.85859 85.1579 6.61514 83.4347L0.0349796 36.8592C-0.26184 34.7566 1.36977 32.8779 3.49331 32.8779H24.9761C26.7163 32.8779 28.191 34.1586 28.4345 35.8815L29.2968 41.9809C29.5402 43.7041 31.0149 44.9848 32.7551 44.9848H84.5074C86.6302 44.9848 88.2622 46.8634 87.9654 48.9661H87.965Z"
            fill="url(#paint2_linear_4388_52446)"
          />
          <path
            d="M70.7128 71.0646H18.9988C18.7153 71.0645 18.4416 70.961 18.229 70.7736C18.0163 70.5861 17.8793 70.3276 17.8436 70.0464L17.1966 64.9237C17.176 64.7598 17.1905 64.5934 17.2392 64.4356C17.2878 64.2777 17.3695 64.1321 17.4787 64.0082C17.588 63.8843 17.7223 63.7851 17.8728 63.7171C18.0233 63.6491 18.1866 63.6139 18.3517 63.6138H71.3598C71.525 63.6139 71.6883 63.6491 71.8388 63.7171C71.9893 63.7851 72.1236 63.8843 72.2329 64.0082C72.3421 64.1321 72.4238 64.2777 72.4724 64.4356C72.521 64.5934 72.5356 64.7598 72.515 64.9237L71.868 70.0464C71.8323 70.3276 71.6953 70.5861 71.4826 70.7736C71.27 70.961 70.9963 71.0645 70.7128 71.0646Z"
            fill="#D5D5D5"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4388_52446"
            x1="46.9037"
            y1="34.9229"
            x2="2.73415"
            y2="-9.24697"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4388_52446"
            x1="35.2026"
            y1="47.4964"
            x2="21.5528"
            y2="33.847"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C2CECE" stopOpacity="0" />
            <stop offset="0.179" stopColor="#AFBCBC" stopOpacity="0.179" />
            <stop offset="1" stopColor="#5B6A6A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4388_52446"
            x1="44"
            y1="32.8779"
            x2="44"
            y2="86.4386"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF0F4" />
            <stop offset="0.927" stopColor="#E4E4E4" />
          </linearGradient>
          <clipPath id="clip0_4388_52446">
            <rect width="88" height="88" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <p className=" font-light">{message}</p>
    </div>
  );
};

export default EmptyData;
