import { Bounce } from "react-toastify";

export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

export function getIndexByName(name: any, array: any) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].name === name) {
      return i;
    }
  }
  // If the name is not found in any object, return -1
  return -1;
}
export function getReturnTypeByName(name: any, array: any) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].name === name) {
      return array[i].isReturned;
    }
  }
  // If the name is not found in any object, return -1
  return -1;
}
export function getReturnTypeByIndex(index: any, array: any) {
  for (let i = 0; i < array.length; i++) {
    if (i === index) {
      return array[i].isReturned;
    }
  }
  // If the name is not found in any object, return -1
  return -1;
}
export function getSecondToLastWord(input: any) {
  // Split the input string by commas
  const parts = input?.split(",");

  // Get the second-to-last part and trim any extra spaces
  const secondToLastPart = parts
    ?.slice(-2, -1)
    ?.map((part: any) => part?.trim());

  // Join the part (though there should be only one part)
  const result = secondToLastPart?.join("");

  return result;
}

export const formatTimestamp = (timestamp: string | number | Date) => {
  // Create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Get the day of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[date.getUTCDay()];

  // Get the month
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getUTCMonth()];

  // Get the day of the month
  const day = date.getUTCDate();

  // Get the year
  const year = date.getUTCFullYear();

  // Get the hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  // Determine AM/PM and adjust hours
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  // Format the time
  const time = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;

  const finalDate = `${dayOfWeek}, ${month} ${day}, ${year}`;

  // Construct the final formatted string
  return { date: finalDate, time };
};

export const getDateStringFormat = (dateString: string) => {
  // Create a Date object from the input string
  const dateObject = new Date(dateString);

  // Extract the year, month, and day
  const year = dateObject.getUTCFullYear();
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(dateObject.getUTCDate()).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  return `${year}-${month}-${day}`;
};

export const REACT_TOAST_CONFIG: any = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export function dateToEpoch(dateString: any) {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    // throw new Error("Invalid date format");
  }

  // Get the epoch time in milliseconds
  const epochTime = date.getTime();

  return epochTime;
}

export function epochToDateString(epochTime: any) {
  const date = new Date(epochTime);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

interface Location {
  address?: string;
}

interface UpdateParamsInput {
  serviceType?: string;
  fromWhereSelected?: Location;
  finalDestinationSelected?: Location;
  pickupTime?: string | Date;
  returnTime?: string | Date;
  queryParams: URLSearchParams;
}

export const updateParams = ({
  serviceType,
  fromWhereSelected,
  finalDestinationSelected,
  pickupTime,
  returnTime,
  queryParams,
}: UpdateParamsInput): URLSearchParams => {
  const convertTimestampToDate = (timestamp?: any) => {
    return timestamp ? new Date(timestamp).toISOString().slice(0, 16) : "";
  };

  const appendQueryParam = (key: string, value?: string) => {
    if (value) {
      queryParams.set(key, value);
    }
  };

  const returnTime_ = returnTime ? new Date(returnTime).getTime() : null;
  const pickupTime_ = pickupTime ? new Date(pickupTime).getTime() : null;

  appendQueryParam("serviceType", serviceType);
  appendQueryParam("fromWhere_", fromWhereSelected?.address);
  appendQueryParam("finalDestination_", finalDestinationSelected?.address);
  appendQueryParam("pickupTime_", convertTimestampToDate(pickupTime_));
  appendQueryParam("returnTime_", convertTimestampToDate(returnTime_));

  return queryParams;
};

export function getRemark(rating:any) {
  switch(rating) {
      case 5:
          return "Excellent Service";
      case 4:
          return "Good Service";
      case 3:
          return "Average Service";
      case 2:
          return "Below Average Service";
      case 1:
          return "Poor Service";
      default:
          return "Invalid Rating";
  }
}
