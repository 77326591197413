import React, { FC } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import FieldSwitch from "../../components/inputs/FieldSwitch";
import FieldCheckbox from "../../components/inputs/FieldCheckbox";

interface handleClickProps {
  control: any;
  register: any;
  errors: any;
  watch: any;
}

const Declaration: FC<handleClickProps> = ({
  control,
  register,
  errors,
  watch,
}) => {
  const needsFixing = watch("carNeedOtherFixing", false);

  return (
    <div className="w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader header="Declaration" />

      <div className="text-base w-full grid lg:grid-cols-2 grid-cols-1 gap-4  opacity-70   justify-items-center justify-center ">
        <div className="flex flex-col  lg:w-full w-full gap-4">
          <FieldSwitch
            control={control}
            label="My car's AC is in perfect condition"
            fieldName="myCarInACPerfectCondition"
          />

          <FieldSwitch
            control={control}
            label="My car has no mechanical or electrical issues"
            fieldName="myCarHasNoMechanicalOrElectricalIssue"
          />

          <FieldSwitch
            control={control}
            label="There are no cosmetic damages or decolouration of any visible
            parts of my car"
            fieldName="noCosmeticDamage"
          />

          <FieldSwitch
            control={control}
            label="My car has a functional stereo/Radio player"
            fieldName="functionalStereo"
          />

          <FieldSwitch
            control={control}
            label="Is there anything you would like to fix in this car before
            rental?"
            fieldName="carNeedOtherFixing"
          />

          {needsFixing && (
            <div className="mt-[10px]">
              <label htmlFor="commentStateOfCar" className="block mb-1">
                If yes, please state:
              </label>
              <textarea
                id="commentStateOfCar"
                className="w-full border border-gray-300 rounded-md p-2"
                name="commentStateOfCar"
                {...register("commentStateOfCar")}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col  lg:w-full w-full gap-4">
          <FieldSwitch
            control={control}
            label="My window winders are complete and functional"
            fieldName="functionalWindowWinders"
          />

          <FieldSwitch
            control={control}
            label="My vehicle does not smoke"
            fieldName="nonSmokingVehicle"
          />

          <FieldSwitch
            control={control}
            label="There is no unusual sound from either my car’s engine or other
            mechanical components"
            fieldName="noUnusualSound"
          />

          <FieldSwitch
            control={control}
            label="My car is ready for rental"
            fieldName="readyForRental"
          />

          <FieldSwitch
            control={control}
            label="My car is serviced regularly"
            fieldName="servicedRegularly"
          />
        </div>
      </div>

      <FieldCheckbox
        control={control}
        name="acknowledged"
        label=" I acknowledge that my vehicle can be called upon at any time for
              inspection. I accept that DMM reserves the right to suspend or delist
              my vehicle if any of the provided information above is false."
        errors={errors}
      />
    </div>
  );
};

export default Declaration;
