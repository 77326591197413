import React, { ChangeEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface handleNextClickProps {
  handleNextClick: () => void;
  setForms: (inputFields: any) => void;
}

const TruckGuarantorDetails: FC<handleNextClickProps> = ({
  handleNextClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    carSeries: "",
    registrationNumber: "",
    doors: "",
    vehicleValue: "",
    passengers: "",
    address: "",
    year: "",
  });
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (
    fieldName: any,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      // setSelectedFile(file);
      handleUpload(fieldName, file);
    }
  };

  const handleUpload = async (fieldName: any, file: any) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append("image", selectedFile);

      // Send a POST request to the ImgBB API to upload the image
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_KEY}`,
        formData
      );

      // Handle the response from the API
      const { data } = response.data;
      setImageUrl(data.url);
      setInputFields({
        ...inputFields,
        [fieldName]: data.url,
      });
      console.log("Image url", data.url);

      // Save the URL details to your database
      // You can send another request to your backend server to save the URL details
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  return (
    <div className="mx-auto flex flex-col gap-8  w-[1140px]  justify-between">
      <div className="flex flex-col gap-2">
        <h1 className=" font-semibold">Guarantor Details</h1>
        <p className=" font-light">
          Please provide accurate deals to speed up vehicle verification.
        </p>
      </div>

      <div className=" flex justify-between gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Full Name"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Phone number"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <label
            htmlFor="truck_make"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Relationship
            <select name="truck_make" id="" placeholder="Car make">
              <option value=" ">Select relationship</option>
            </select>
          </label>
          <input
            type="text"
            placeholder=" Business address"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Business phone number"
            className=" border-2 mb-2 rounded-lg p-2"
          />
        </div>
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Email address"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder="Current home address"
            className=" border-2 rounded-lg p-2 mb-2"
          />
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Upload Pictures
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="proof of ownership"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Proof of ownership", e)}
            />
          </div>
          <input
            type="text"
            placeholder=" Busines email address"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <label
            htmlFor="years_known"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Years known
            <select name="truck_make" id="" placeholder="Car make">
              <option value=" ">Select years</option>
            </select>
          </label>
        </div>
      </div>

      {/* <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        required
        placeholder="Email or phone number"
      /> */}
      <div>
        <button
          className=" bg-black text-white px-5 py-1 rounded-full"
          onClick={() => {
            handleNextClick && handleNextClick();
            handleAppendForm();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TruckGuarantorDetails;
