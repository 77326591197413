import React from "react";
import { formatCurrency } from "../../utils/stringFunctions";

interface Booking {
  carId: {
    carImages: string[];
    carMakeID: { make: string; mode: string };
  };
  bookingPrice: number;
  status: string;
}

interface BookingsCarCardProps {
  booking: Booking;
  userStatus?:string
  navigate: (path: string, options: { state: Booking }) => void;
}

const BookingTag = ({ booking }: { booking: { status: string } }) => {
  if (
    booking.status === "PENDING" ||
    booking.status === "CONFIRM" ||
    booking.status === "OFFER"
  ) {
    return (
      <div className="absolute bg-white text-[#9E864C] text-[12px] font-medium px-[8px] items-center rounded-full top-[20px] right-3">
        {booking.status === "PENDING"
          ? "Awaiting acceptance"
          : "Awaiting activation"}
      </div>
    );
  }

  if (booking.status === "CANCELLED" || booking.status === "FULFILLED") {
    return (
      <div
        className={`absolute bg-white ${
          booking.status === "FULFILLED" ? "text-[#529B25]" : "text-[#E30A2C]"
        } text-[12px] font-medium px-[8px] items-center rounded-full top-[20px] right-3`}
      >
        {booking.status === "FULFILLED" ? "Fulfilled" : "Cancelled"}
      </div>
    );
  }

  return (
    <div className="absolute bg-white text-[#0E1427] text-[12px] font-medium px-[8px] rounded-full top-[20px] right-3">
      {booking.status === "ONGOING" ? "Ongoing booking" : "Active booking"}
    </div>
  );
};

const BookingButton = ({
  booking,
  navigate,
  userStatus
}: {
  booking: { status: string };
  navigate: (path: string, options: { state: any }) => void;
  userStatus?:string
}) => {
  if (
    booking.status === "PENDING" ||
    booking.status === "CONFIRM" ||
    booking.status === "OFFER"
  ) {
    return (
      <button
        className="text-white text-[14px] font-light rounded-full bg-[#0E1427] px-4 flex justify-center items-center"
        onClick={() =>
          navigate(
            booking.status === "PENDING"
              ? "/profile/booking-pending"
              : booking.status === "OFFER"
              ? "/profile/booking-pending"
              : "/profile/activate-booking",
            { state: booking }
          )
        }
      >
        {booking.status === "PENDING"
          ? "View booking"
          : booking.status === "OFFER"
          ? "View offer"
          : "Activate booking"}
      </button>
    );
  }

  if (booking.status === "CANCELLED" || booking.status === "FULFILLED") {
    return (
      <button
        className="text-white text-[14px] font-light rounded-full bg-[#0E1427] px-4 flex justify-center items-center"
        onClick={() =>
          navigate(
            booking.status === "FULFILLED"
              ? "/profile/booking-completed"
              : "/profile/booking-cancelled",
            { state: booking }
          )
        }
      >
        {"View booking"}
      </button>
    );
  }

  return (
    <button
      className="text-white text-[14px] font-light rounded-full bg-[#0E1427] px-4 flex justify-center items-center"
      onClick={() =>
        navigate(
          booking.status === "ACTIVE"
            ? "/profile/ongoing-booking"
            : "/profile/ongoing-booking",
          { state: booking }
        )
      }
    >
      {booking.status === "ACTIVE" ? "View booking" : "Active booking"}
    </button>
  );
};

const BookingsCarCard: React.FC<BookingsCarCardProps> = ({
  booking,
  navigate,
  userStatus
}) => {
  return (
    <div className="bg-white flex flex-col justify-between items-center border border-1 border-[#C4CFDF] rounded-2xl my-6 h-[220px]">
      <div
        className="rounded-t-2xl object-cover relative w-full max-w-full h-[150px]"
        style={{
          backgroundImage: `url(${booking.carId.carImages[0]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <BookingTag booking={booking} />
      </div>

      <div className="flex justify-between w-full p-3">
        <div className="">
          <p className="text-[16px] font-light">
            {booking.carId.carMakeID?.make}
          </p>

          <p className="text-[15px] font-medium">
            {formatCurrency(+booking?.bookingPrice ?? 0)}
          </p>
        </div>

        <BookingButton navigate={navigate} booking={booking} userStatus={userStatus}/>
      </div>
    </div>
  );
};

export default BookingsCarCard;
