import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface FieldAutocompleteProps {
  control: Control<any>;
  errors: any;
  options: any[];
  fieldName: string;
  label: string;
  placeholder: string;
  onChange?: any;
  multiple?: boolean;
  errMessage?: string;
}

const FieldAutocomplete: React.FC<FieldAutocompleteProps> = ({
  control,
  errors,
  options,
  fieldName,
  label,
  placeholder,
  onChange,
  multiple = true,
  errMessage,
}) => {
  const errorMessage = errMessage || errors?.[fieldName]?.message;

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={multiple ? [] : ""}
      render={({ field }) => (
        <Autocomplete
          multiple={multiple}
          options={options}
          disableCloseOnSelect={multiple}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) =>
            multiple ? (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            ) : (
              <li {...props}>{option}</li>
            )
          }
          value={field.value}
          onChange={(event, newValue) => {
            if (newValue === null) {
              field.onChange("");
              onChange && onChange(event, "");
            } else {
              field.onChange(newValue);
              onChange && onChange(event, newValue);
            }
          }}
          style={{ width: "100%" }}
          freeSolo={!multiple}
          forcePopupIcon
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errorMessage}
              helperText={errorMessage || ""}
              placeholder={placeholder}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          )}
        />
      )}
    />
  );
};

export default FieldAutocomplete;
