import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import { FaAngleRight } from "react-icons/fa6";
import { IoCheckmarkOutline } from "react-icons/io5";
import Modal from "../../components/modal/Modal";
import { formatCurrency } from "../../utils/stringFunctions";
import { formatDate } from "../car-rental/CarRentalBooking";
import axios, {AxiosError } from "axios";
import { useData, useIsMobile } from "../../Hooks/useData";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import DmmDialog from "../../components/modal/DmmDialog";
import { useAuth } from "../../context/authContext";
import { useUserStatus } from "../../context/userStatusContext";
import CustomButton from "../../components/elements/CustomButton";
import { ToastContainer, toast } from "react-toastify";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";

const BookingCompleted = () => {
  const [driver, setDriver] = useState<any>({});
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [carCity, setCarCity] = useState<any>("");
  const [reviewModal, setReviewModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [rating, setRating] = useState<string>("");
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const data = location.state;
  const { userData } = useData();
  const role = userData?.role?.name;
  const { userStatus } = useUserStatus();
  const isMobile = useIsMobile();

  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);
    // const fetchCarDetails = async () => {
    //   const { data } = await axios.get(baseUrl + endPoint);
    //   // console.log("result", data.data);
    //   setTopDeals(data.data);
    //   setItems(data.data);
    // };

    const fetchDriver = async () => {
      const response = await axios.get(
        `https://dmm-backend-f562.onrender.com/api/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
      );
      
      setDriver(response.data);
      // setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    const fetchServiceType = async () => {
      const response = await axios.get(
        "https://dmm-backend-f562.onrender.com/api/general/getServiceTypes"
      );
      
      setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    // fetchCarDetails();
    fetchDriver();
    fetchServiceType();
  }, []);

  const HandlePostReviewModal = () => {
    setReviewModal(true);
  };
  const HandlePostReview = async () => {
    try {
      setIsloading(true);
      const response = await axios.post(
        `https://dmm-backend-f562.onrender.com/api/booking/reviewBooking`,
        {
          bookingId: data?._id,
          userId: state.userId,
          rating: rating,
          comment: textareaValue,
        }
      );

      if (!response.data?.isError === true) {
        setReviewModal(false);
        // navigate("/profile/bookings");
        setIsloading(false);
      }
    } catch (error:any) {
      setReviewModal(false);
      setIsloading(false);
      
    
    toast.error(error?.response?.data?.message, REACT_TOAST_CONFIG);
    }
  };
  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextareaValue(event.target.value);
  };

  const ratingsMap = {
    1: "Poor Service",
    2: "Below Average Service",
    3: "Average Service",
    4: "Good Service",
    5: "Excellent Service",
  };

  return (
    <div>
      <ToastContainer />
      <Summary data={data} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 w-full max-w-[1480px] lg:gap-20 lg:mb-10">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex items-center mb-3 gap-2">
            <IoCheckmarkOutline className="text-[40px] md:text-[50px] bg-[#15FF3B] rounded-full p-2 text-white " />

            <h2 className="text-[20px] md:text-[25px] ml-2">
              Booking completed!
            </h2>
          </div>

          <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi {data?.userId?.firstName} <span>{data?.userId?.lastName}</span>,
            the booking for{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span>{" "}
            is completed.<br></br>
            Please check below for details.
          </p>

          <hr className="mb-3 mt-1" />

          {/* details */}
          <div className=" flex justify-between mb-4">
            <div className=" flex w-full flex-col md:flex-row py-3 hover:border-orange-300 cursor-pointer md:space-x-16">
              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Booking date:</p>

                  <p>
                    {formatDate(data?.bookingStartDate)} -{" "}
                    {formatDate(data?.bookingEndDate)}
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Driver:</p>

                  <p>
                    {driver?.firstName || driver?.lastName ? (
                      <p>
                        {driver?.firstName} {driver?.lastName}
                      </p>
                    ) : (
                      <p>None Assigned</p>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Service type:</p>

                  <p className="">
                    {
                      allServiceTypeData.find(
                        (val) => val?._id === data?.serviceTypeId
                      )?.name
                    }
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Status:</p>

                  <p className="">{data?.status}</p>
                </div>
              </div>

              <div className="flex md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center w-full">
                  <p className="font-light">Amount offered:</p>

                  <p className="">{formatCurrency(data?.bookingPrice)}</p>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          {userStatus == "you" && (
            <div>
              <button
                className={` text-white text-sm  rounded-full w-[%] px-5 py-2 hover:shadow-md mb-5 
              bg-black border-indigo-950 border`}
                onClick={HandlePostReviewModal}
              >
                Write a review
              </button>
            </div>
          )}
        </div>

        <OrderSummary data={data} />
      </div>
      <DmmDialog
        open={reviewModal}
        onClose={() => {
          setReviewModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Write review</h1>
          {/* <p className="text-sm mb-4">
            What is your reason for declining this booking?
          </p> */}
          <div className="mb-4  border-gray-300 rounded-3xl">
            <textarea
              className="mb-4 border border-gray-300 rounded-3xl p-2"
              name="textarea"
              title="textarea"
              placeholder="Enter review"
              onChange={handleTextareaChange}
              cols={50}
              rows={4}
            />
            <div className=" flex flex-col gap-2 ">
              <label className=" mb-2">select ratings</label>
              <select
                className=" border px-4 py-2 rounded-lg"
                id="rating-select"
                onChange={(e) => setRating(e.target.value)}
              >
                <option value="">Select rating</option>
                {Object.entries(ratingsMap)
                  .reverse()
                  .map(([value, remark]) => (
                    <option key={value} value={value}>
                      {value} - {remark}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="flex space-x-6 mt-7 mb-3 w-full">
            <CustomButton
              loading={isloading}
              type="button"
              className="text-white text-sm bg-indigo-950 border-gray-500 border rounded-full px-3 py-1 hover:shadow-md w-full"
              onClick={() => {
                HandlePostReview();
              }}
            >
              Post and go home
            </CustomButton>
            <button
              type="button"
              className="text-indigo-950 text-sm bg-white-700 border-indigo-900 border rounded-full px-3 py-1 hover:shadow-md w-full"
              onClick={() => {
                setReviewModal(false);
              }}
            >
              Cancel and go back
            </button>
          </div>
        </div>
      </DmmDialog>
    </div>
  );
};

export default BookingCompleted;
