import { Step } from "../bookingSteps/Booking";
import Layer2 from "../../assets/images/Layer2.svg";
import VectorSmartObject from "../../assets/images/VectorSmartObject.svg";
import VectorSmartObject1 from "../../assets/images/VectorSmartObject1.svg";

export const carRentalSteps: Step[] = [
  {
    img: Layer2,
    title: "Find the Perfect Vehicle",
    description:
      "Pick the perfect automobile that matches your needs and taste! Browse through thousands of amazing vehicles.",
  },
  {
    img: VectorSmartObject,
    title: "Book on DMM",
    description:
      "Book on DMM for peace of mind and guaranteed quality cars! Free cancellations up to 24 hours before your trip",
  },
  {
    img: VectorSmartObject1,
    title: "You're Done!",
    description:
      "Have the vehicle delivered or pick up from your host. Check in with DMM, grab the keys, get ready and hit the road.",
  },
];
