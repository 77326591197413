export const getDate = () => {
  const now = new Date();

  const formatDateTime = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format for the `datetime-local` input field
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Create new dates for +2 days and +5 days
  const plusTwoDays = new Date(now);
  plusTwoDays.setDate(now.getDate() + 2);

  const plusFiveDays = new Date(now);
  plusFiveDays.setDate(now.getDate() + 5);

  return {
    start: formatDateTime(plusTwoDays),
    end: formatDateTime(plusFiveDays),
  };
};
