import React, { useState } from "react";
import { useAuth } from "../../context/authContext";
import { useData } from "../../Hooks/useData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import Field from "../../components/inputs/Field";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { WalletWithdrawalFormSchema } from "../../validations/formDataSchema";
import FieldCurrency from "../../components/inputs/FieldCurrency";
import CustomButton from "../../components/elements/CustomButton";

type Inputs = z.infer<typeof WalletWithdrawalFormSchema>;

const WalletWithdrawal = ({ setWalletModal }: { setWalletModal: any }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(WalletWithdrawalFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);
  const { state } = useAuth();
  const [amount, setAmount] = useState("");
  const [isWithdrawaLoading, setWithdrawaLoading] = useState(false);
  const navigate = useNavigate();

  const { userData } = useData();

  const PUBLIC_KEY = process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY;

  const withdrawalConfig = {
    public_key: PUBLIC_KEY as string,
    tx_ref: Date.now().toString(),
    amount: +amount, // Specify the amount to withdraw
    currency: "NGN", // Currency code (e.g., NGN for Nigerian Naira)
    payment_options: "banktransfer", // Specify bank transfer as the payment option
    meta: {
      // Add any additional metadata as needed
      userId: "USER_ID",
    },
    customer: {
      email: userData?.email ?? "",
      phone_number: userData?.phoneNumber ?? "",
      name: `${userData?.firstName} ${userData?.lastName}`,
    },
    customizations: {
      // title: `Payment for ${data?.carMakeID?.make}`,
      title: `Payment for `,
      // description: `Payment for rental of  ${data?.carMakeID?.make}`,
      description: `Payment for rental of`,
      // logo: data?.carImages[0],
      logo: "",
    },
    callback: async (response: any) => {
      if (response?.status?.toLowerCase() === "successful") {
        // Withdrawal successful, perform additional actions
        alert("Withdrawal successful!");
        // Close the payment modal
        closePaymentModal();
        // Perform additional actions like updating user balance, etc.
      } else {
        // Withdrawal failed or was cancelled by the user
        alert("Withdrawal failed or cancelled.");
        // Close the payment modal
        closePaymentModal();
      }
    },
    onClose: () => {
      // Handle modal close event if needed
      console.log("Flutterwave modal closed.");
    },
  };

  const handleFlutterWavePayment = useFlutterwave(withdrawalConfig);

  const handleWithdrawal: SubmitHandler<Inputs> = async (data) => {
    setWithdrawaLoading(true);
    // openWithModal(config);
    // openWithModal(withdrawalConfig);
    try {
      const response = await axios.post(
        "https://api.flutterwave.com/v3/transfers",
        {
          account_bank: "GTB", // Bank name or code
          account_number: 1234567894, // Recipient's account number
          amount: 2300, // Amount to withdraw
          currency: "NGN", // Currency code (Nigerian Naira)
        },
        {
          headers: {
            Authorization:
              "Bearer FLWSECK_TEST-3f71962eb4ad1fcc70501e2365eea6c1-X",
          },
        }
      );

      navigate("/profile/wallet");
    } catch (error) {
      console.error("Withdrawal error:", error);
      setWithdrawaLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleWithdrawal, onInvalid)}>
      <div className="flex flex-col w- gap-4 mt-2 mb-8 p-[10px] min-w-[300px]">
        <div className="flex flex-col gap-5 mb-5 rounded-md">
          <FieldCurrency
            control={control}
            name="amount"
            label="Enter amount"
            errors={errors}
            // onChange={(e: any) => {
            //   setAmount(removeNairaAndConvertToInt(e.target.value).toString());
            // }}
          />
          <Field
            type="select"
            label="Select Bank"
            fieldName="bank"
            errors={errors}
            control={control}
          />
          <Field
            type="text"
            label="Account Number"
            fieldName="accountNumber"
            errors={errors}
            control={control}
          />
          <Field
            type="text"
            label="Account Name"
            fieldName="accountName"
            errors={errors}
            control={control}
          />
          <Field
            type="text"
            label="Description"
            fieldName="description"
            errors={errors}
            control={control}
          />
        </div>

        <CustomButton
          loading={isWithdrawaLoading}
          type="submit"
          className="w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full mt-4"
          onClick={handleSubmit(handleWithdrawal, onInvalid)}
        >
          Withdraw
        </CustomButton>
      </div>
    </form>
  );
};

export default WalletWithdrawal;
