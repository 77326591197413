interface User {
  firstName: string;
  lastName: string;
}

interface Host {
  _id: string;
  userId: User;
}

interface CarMake {
  _id: string;
  make: string;
  mode: string;
  year: number;
}

interface Location {
  coordinates: [number, number];
  type: string;
}

interface ServiceType {
  isReturned: boolean;
  name: string;
  price: number;
  _id: string;
}

export interface CarDetailsProps {
  _id: string;
  hostID: Host;
  carCode: string;
  carImages: string[];
  carMakeID: CarMake;
  carNotes: string;
  serviceTypes: ServiceType[];
  careSpecialFeature: string[];
  dmmRating: number;
  canGuestDriveYourCar: boolean;
  wasCarUpgraded: boolean;
  availableForInterState: boolean;
  numberOfDoors: number;
  address: string;
  location: Location;
  allowDMMSetPrice: boolean;
  allowClientOfferPrice: boolean;
  noticePeriod: string;
  dayAvailable: string[];
  vehicleType: string[];
  status: string;
  createdAt: string;
  __v: number;
}

const initialHost: Host = {
  _id: "",
  userId: {
    firstName: "",
    lastName: "",
  },
};

const initialCarMake: CarMake = {
  _id: "",
  make: "",
  mode: "",
  year: 0,
};

const initialLocation: Location = {
  coordinates: [0, 0],
  type: "Point",
};

export const initialCarState: CarDetailsProps = {
  _id: "",
  hostID: initialHost,
  carCode: "",
  carImages: [],
  carMakeID: initialCarMake,
  carNotes: "",
  serviceTypes: [
    {
      isReturned: false,
      name: "",
      price: 0,
      _id: "",
    },
  ],
  careSpecialFeature: [],
  dmmRating: 0,
  canGuestDriveYourCar: false,
  wasCarUpgraded: false,
  availableForInterState: false,
  numberOfDoors: 0,
  address: "",
  location: initialLocation,
  allowDMMSetPrice: false,
  allowClientOfferPrice: false,
  noticePeriod: "",
  dayAvailable: [],
  vehicleType: [],
  status: "",
  createdAt: "",
  __v: 0,
};
