import React from "react";

interface ListingFormHeaderProps {
  header: string;
  subHeader?: string;
}

const ListingFormHeader: React.FC<ListingFormHeaderProps> = ({
  header,
  subHeader,
}) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <h1 className=" font-medium text-[20px] md:text-[25px] opacity-80">
        {header}
      </h1>

      {subHeader && (
        <p className=" font-light opacity-70 text-[15px] md:text-[18px]">
          {subHeader}
        </p>
      )}
    </div>
  );
};

export default ListingFormHeader;
