import { Button } from "@material-tailwind/react";
import Altis from "../../assets/images/altis.jpg";
import { useNavigate } from "react-router-dom";

const ListYourCar = () => {
  const navigate = useNavigate();

  return (
    <div className="relative mt-[3rem] hidden lg:flex w-full max-w-[1350px] h-[220px] mb-3 mx-auto items-center object-cover">
      <div className="absolute left-[55%] text-white z-50 my-auto">
        <h3 className="text-2xl">List your car</h3>

        <p className="mt-1 opacity-80 font-light">
          Earn big on your car! Sublease your car and earn while you
          <br></br>rest. We manage and run it on your behalf.
        </p>

        <Button
          className="rounded-full font-medium text-black text-lg bg-[#efcb76] px-10 py-1 mt-4 w-fit"
          onClick={() => {
            navigate("/car/list-vehicle", { state: 1 });
          }}
        >
          POST
        </Button>
      </div>
      <div className="absolute bg-gradient-to-l from-gray-900 via-gray-900 via-50% to-75% to-transparent h-full w-full rounded-r-3xl"></div>

      <div
        className="w-3/4 h-full rounded-l-3xl bg-no-repeat bg-gray-900"
        style={{
          backgroundImage: `url(${Altis})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom -150px right 240px",
        }}
      ></div>

      <div className="bg-gray-900 text-white w-1/4 h-full rounded-r-3xl p-6 flex flex-col justify-center"></div>
    </div>
  );
};

export default ListYourCar;
