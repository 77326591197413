import React, { useState } from "react";
import Slider from "react-slick";
import CarouselSkeleton from "../skeletons/CarouselSkeleton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import DmmDialog from "../modal/DmmDialog";

interface DmmCarouselProps {
  images: string[];
  className?: string;
  borderRadius?: string;
}

function SampleNextArrow(props: {
  className?: any;
  style?: any;
  onClick?: any;
}) {
  const { className, style, onClick } = props;
  return (
    <GoChevronRight
      className={className}
      style={{
        ...style,
        display: "block",
        color: "#fff",
        width: "40px",
        height: "40px",
        margin: "0 40 0 0",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: {
  className?: any;
  style?: any;
  onClick?: any;
}) {
  const { className, style, onClick } = props;
  return (
    <GoChevronLeft
      className={className}
      style={{
        ...style,
        display: "block",
        color: "#fff",
        width: "40px",
        height: "40px",
        zIndex: 50,
        margin: "0 0 0 40",
      }}
      onClick={onClick}
    />
  );
}

const DmmCarousel: React.FC<DmmCarouselProps> = ({
  images,
  className = "w-[40%] h-[350px]",
  borderRadius = "24px 0px 0px 0px",
}) => {
  const [showImages, setShowImages] = useState(false);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots: any) => (
      <div style={{ padding: "10px" }}>
        <ul style={{ marginBottom: "40px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className={className}>
      {!images?.length ? (
        <CarouselSkeleton />
      ) : (
        <>
          <Slider {...settings}>
            {images.map((image: string | undefined, index: any) => (
              <div key={index} className="cursor-pointer">
                <div
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "350px",
                    width: "100%",
                    borderRadius: borderRadius,
                  }}
                  onClick={() => setShowImages(true)}
                />
              </div>
            ))}
          </Slider>

          <DmmDialog
            title={""}
            open={showImages}
            onClose={() => setShowImages(false)}
            padding=""
            width={{ xs: "100%", md: "800px" }}
            carousel
          >
            <Slider {...settings} dots={false}>
              {images.map((image: string | undefined, index: any) => (
                <div key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "500px",
                      width: "100%",
                      borderRadius: "24px",
                      marginBottom: -10,
                    }}
                  />
                </div>
              ))}
            </Slider>
          </DmmDialog>
        </>
      )}
    </div>
  );
};

export default DmmCarousel;
