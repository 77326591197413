import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import "./index.css";
import Homepage from "./pages/homepage/Homepage";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import CarRental from "./pages/car-rental/CarRental";
import PaymentCard from "./pages/payment-card/PaymentCard";
import PaymentConfirmation from "./pages/payment-confirmation/PaymentConfirmation";
import TruckLandingPage from "./pages/truck-page/TruckLandingPage";
import DriversPage from "./pages/drivers-page/DriversPage";
// import TakeMeHome from './pages/drivers-page/TakeMeHome';
import AwaitingDriver from "./pages/awaiting-driver/AwaitingDriver";
import BookingConfirmation from "./pages/booking-confirmation/BookingConfirmation";
import ConfirmedBooking from "./pages/confirmedbooking/ConfirmedBooking";
import ListVehicle from "./pages/car-rental/ListVehicle";
import PageNotFound from "./pages/not found/PageNotFound";
import Profile from "./pages/profile/Profile";
import ListTruck from "./pages/truck-page/ListTruck";
// import BackToCar from './pages/car-details/CarDetails';
// import CarDetails from './pages/car-details/CarDetails';
import CarRentalBooking from "./pages/car-rental/CarRentalBooking";
import OngoingBooking from "./pages/profile/OngoingBooking";
import PendingBookingConfirmation from "./pages/profile/PendingBookingConfirmation";
import DateUnavailable from "./pages/dates-unavailable/DateUnavailable";
// import ListVehicle2 from './pages/car-rental/ListVehicle2';
import CarDetails from "./pages/car-details/CarDetails";
import DriverAssigned from "./pages/profile/DriverAssigned";
import BookingCompleted from "./pages/profile/BookingCompleted";
import BookingCancelled from "./pages/profile/BookingCancelled";
import TakeMeHome from "./pages/drivers-page/TakeMeHome";
import { useAuth } from "./context/authContext";
import Listings from "./pages/profile/Listings";
import Transactions from "./pages/profile/Transactions";
import Bookings from "./pages/profile/Bookings";
import Details from "./pages/profile/Details";
import ActivateBooking from "./pages/profile/ActivateBooking";
import Notifications from "./pages/notifications/Notifications";
import Verify from "./pages/verify/Verify";
import ScrollToTop from "./components/scrollToTop.tsx/ScrollToTop";
import HostListingCompleted from "./pages/car-rental/HostListingCompleted";
import AssignDriver from "./pages/car-rental/AssignDriver";
import EditListVehicle from "./pages/car-rental/EditListVehicle";

export const PrivateRoute = () => {
  // const { isAuthenticated } = useAuth(); // Assuming useAuth() provides authentication status
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();

  // const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  let isAuthenticated = state.isLoggedIn;
  const handleNavigate = useCallback(() => {
    navigate("/", { replace: true }); // Redirect to login page
    alert("please log in");
  }, [navigate]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleNavigate();
    }
  }, [handleNavigate, isAuthenticated]);

  return isAuthenticated ? <Outlet /> : null;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  return (
    <BrowserRouter>
      <div className="App font-roboto">
        <ScrollToTop />

        <div className="flex flex-col min-h-screen">
          <Navbar />

          <div className="flex-1">
            <Routes>
              <Route path="/" element={<Homepage />} />

              {/* Private route using PrivateRoute component */}

              {/* <Route path="/" element={<PrivateRoute />}>
        </Route> */}

              <Route path="/car" element={<CarRental />} />
              <Route
                path="/car/car-rental-booking"
                element={<CarRentalBooking />}
              />
              <Route path="/car/details/:carCode" element={<CarDetails />} />
              <Route path="/car/list-vehicle" element={<ListVehicle />} />
              <Route
                path="/car/edit-list-vehicle"
                element={<EditListVehicle />}
              />
              <Route path="/car/assign-driver" element={<AssignDriver />} />

              <Route
                path="/car/host-listing-completed"
                element={<HostListingCompleted />}
              />
              <Route
                path="/car/booking-confirmation"
                element={<BookingConfirmation />}
              />
              <Route
                path="/car/date-unavailable"
                element={<DateUnavailable />}
              />
              <Route
                path="/car/confirmed-booking"
                element={<ConfirmedBooking />}
              />
              <Route path="/payment-card" element={<PaymentCard />} />
              <Route
                path="/payment-confirmation"
                element={<PaymentConfirmation />}
              />
              <Route path="/truck" element={<TruckLandingPage />} />
              <Route path="/truck/list-truck" element={<ListTruck />} />
              <Route path="/driver" element={<DriversPage />} />
              <Route path="/driver/take-me-home" element={<TakeMeHome />} />

              <Route path="/profile" element={<Profile />}>
                <Route index element={<Transactions />}></Route>
                <Route path="wallet" element={<Transactions />}></Route>
                <Route path="bookings" element={<Bookings />}></Route>
                <Route path="listings" element={<Listings />}></Route>
                <Route path="listings/:carCode" element={<Details />}></Route>
                <Route
                  path="listings/:carCode/assign-driver"
                  element={<AssignDriver />}
                />
              </Route>

              <Route
                path="/profile/ongoing-booking"
                element={<OngoingBooking />}
              />
              <Route
                path="/profile/activate-booking"
                element={<ActivateBooking />}
              />
              <Route
                path="/profile/booking-pending"
                element={<PendingBookingConfirmation />}
              />
              <Route
                path="/profile/driver-assigned"
                element={<DriverAssigned />}
              />
              <Route
                path="/profile/booking-completed"
                element={<BookingCompleted />}
              />
              <Route
                path="/profile/booking-cancelled"
                element={<BookingCancelled />}
              />
              <Route path="/driver/take-me-home" element={<TakeMeHome />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/awaiting-driver" element={<AwaitingDriver />} />

              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </div>

          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
