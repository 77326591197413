import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const DmmSpinner = ({ size }: { size?: number }) => {
  return (
    <div className="flex">
      <CircularProgress sx={{ color: "#efcb76" }} size={size || undefined} />
    </div>
  );
};

const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-blue-500"></div>
    </div>
  );
};

export default Spinner;
