import React, { useState } from "react";
import Accordion from "./accordion";

const Faq: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="hidden lg:block mx-auto max-w-[1350px] w-full p-4 mt-12 mb-16 text-left">
      <h1 className="font-semibold text-xl text-left w-full mb-5">
        Frequently asked questions
      </h1>
      <div className="flex flex-col md:flex-row md:justify-between md:space-x-12">
        <div className="w-full md:w-1/2">
          <div>
            <hr className="mb-2" />
            <Accordion
              title="What do I need to book a car on DMM?"
              answer="You must register for a DMM account, be at least eighteen (18) years old, and possess a valid government-issued license or identification card to reserve a car on DMM. Every vehicle on the DMM platform has a driver. So, unwind and relish your journey!"
              index={1}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="What happens if I have an accident? "
              answer="Use the live chat function or the Contact Us section to get in touch with the DMM Admin if there's an emergency or any issue with the vehicle. After ensuring your safety, we'll assist you in continuing on your journey."
              index={2}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="Is my car safe on the DMM platform?"
              answer="Yes, your car is safe on the DMM platform. To confirm each guest's identification on the platform, we have a guest identity verification system in place."
              index={3}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="What happens if my driver does not show up?"
              answer=" The guest receives a replacement car, while the host is reprimanded/penalized. Our system also monitors the host's preparedness to fulfil each booked appointment on time."
              index={4}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2" />
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <div className="">
            <hr className="mb-2" />
            <Accordion
              title=" What is the cancellation policy on DMM?"
              answer="You can cancel and get a full refund up to 24 hours before your trip starts. If you book a trip with less than 24 hours’ notice, you have one hour after booking to cancel free of charge. If you cancel after the free cancellation period ends, you will be charged a small cancellation fee.
              
              In the rare event a host cancels, you’ll be notified immediately so you can book another car, or we’ll help you find one. Your refund can be temporarily held to expedite rebooking or the funds can be returned to your bank account — your choice."
              index={5}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="Do I need comprehensive insurance to list my car on DMM?"
              answer="No, comprehensive insurance is not required to list a vehicle on DMM. However, we strongly recommend this over third-party insurance coverage, as any damage to the host’s vehicle is covered in the event of an accident."
              index={6}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="I need a driver for my car bookings. Can I hire a driver on the DMM platform?"
              answer="Yes, it is possible to hire a driver on DMM to fulfil your car reservations. "
              index={7}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2 mb-2" />
          </div>
          <div>
            <Accordion
              title="What happens if my car gets stolen? "
              answer=" Tracking and Insurance are the host's responsibilities. Make an effort to fit your cars with trackers."
              index={8}
              openIndex={openIndex}
              onAccordionClick={handleAccordionClick}
            />
            <hr className="mt-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
