import Scan from "../../assets/images/scan.png";
import Appstore from "../../assets/images/appstore.jpg";
import Mobilephone from "../../assets/images/mobile phone.png";

const MobileApp = () => {
  return (
    <div className="px-6 w-full lg:mt-10 mb-2">
      <div className="bg-[#DCF3FB] rounded-3xl mb-10 w-full max-w-[1350px] px-[1px] flex mx-auto">
        <div className="flex gap-10 lg:gap-16 justify-center items-center py-5 w-full mr-5 lg:mr-20">
          <img
            src={Mobilephone}
            alt="phone"
            className="w-[90px] md:w-[130px] lg:w-64 h-auto lg:-mt-20"
          />

          <div className="size-full flex flex-col justify-center">
            <h2 className="text-[19px] lg:text-2xl">
              Get an amazing mobile app experience
            </h2>

            <p className="hidden md:block text-sm lg:text-lg font-light">
              Download our Mobile App free today to book your private charters,
              rides, boat cruises and more and get amazing deals on the app
            </p>

            <div className="flex mt-4 lg:mt-10 gap-2 justify-start items-center">
              <div className="w-[100px] lg:w-[140px]">
                <img
                  src={Appstore}
                  alt="playstore"
                  className="rounded w-full"
                />
              </div>

              <div className="w-[60px] lg:w-[80px]">
                <img src={Scan} alt="scan" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
