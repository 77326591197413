import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import { FaAngleRight } from "react-icons/fa6";
import { HiMiniXMark } from "react-icons/hi2";
import Modal from "../../components/modal/Modal";
import axios from "axios";
import { formatCurrency } from "../../utils/stringFunctions";
import { formatDate } from "../car-rental/CarRentalBooking";
import { useData } from "../../Hooks/useData";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";

const BookingCancelled = () => {
  const [driver, setDriver] = useState<any>({});
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [carCity, setCarCity] = useState<any>("");
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const location = useLocation();
  const data = location.state;
  const { userData } = useData();
  const role = userData?.role?.name;

  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);
    // const fetchCarDetails = async () => {
    //   const { data } = await axios.get(baseUrl + endPoint);
    //   // console.log("result", data.data);
    //   setTopDeals(data.data);
    //   setItems(data.data);
    // };

    const fetchDriver = async () => {
      const response = await axios.get(
        `https://dmm-backend-f562.onrender.com/api/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
      );
      console.log("fetch Driver---->", response.data);
      setDriver(response.data);
      // setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    const fetchServiceType = async () => {
      const response = await axios.get(
        "https://dmm-backend-f562.onrender.com/api/general/getServiceTypes"
      );
      console.log("service type", response.data);
      setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    // fetchCarDetails();
    fetchDriver();
    fetchServiceType();
  }, []);

  return (
    <div>
      <Summary data={data} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 w-full max-w-[1480px] lg:gap-20 lg:mb-10">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex items-center mb-3 gap-2">
            <HiMiniXMark className="text-[40px] md:text-[50px] bg-red-700 rounded-full p-1 text-white" />

            <h2 className="text-[20px] md:text-[25px] ml-2">
              Booking canceled!
            </h2>
          </div>

          <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi {data?.userId?.firstName} <span>{data?.userId?.lastName}</span>,
            the guest cancelled the booking for{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span>.
            <br></br>
            Please check below for details.
          </p>

          <hr className="mb-3 mt-1" />

          {/* details */}

          <div className=" flex justify-between mb-4">
            <div className=" flex w-full flex-col md:flex-row py-3 hover:border-orange-300 cursor-pointer md:space-x-16">
              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Booking date:</p>

                  <p>
                    {formatDate(data?.bookingStartDate)} -{" "}
                    {formatDate(data?.bookingEndDate)}
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Driver:</p>

                  <p>
                    {driver?.firstName || driver?.lastName ? (
                      <p>
                        {driver?.firstName} {driver?.lastName}
                      </p>
                    ) : (
                      <p>None Assigned</p>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Service type:</p>

                  <p className="">
                    {
                      allServiceTypeData.find(
                        (val) => val?._id === data?.serviceTypeId
                      )?.name
                    }
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Status:</p>

                  <p className="">{data?.status}</p>
                </div>
              </div>

              <div className="flex md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center w-full">
                  <p className="font-light">Amount offered:</p>

                  <p className="">{formatCurrency(data?.bookingPrice)}</p>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-4" />
        </div>

        <OrderSummary data={data} />
      </div>
    </div>
  );
};

export default BookingCancelled;
