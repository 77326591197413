import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { SeeOtherVehiclesProps } from "../../assets/jsons/see-other-vehicle";
import Rating from "../rating/Rating";

const OtherVehicles: React.FC<SeeOtherVehiclesProps> = ({
  userData,
  showButton = true,
  onClick,
  ...otherVehicle
}) => {
  const navigate = useNavigate();
  // const [showButton, setShowButton] = useState()

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row">
        <div className="w-auto h-[250px] md:h-full lg:h-[180px] lg:w-[500px] flex justify-start items-start">
          <img
            src={otherVehicle?.image}
            alt=""
            className={`w-full h-full rounded-tl-3xl max-md:rounded-tr-3xl object-cover ${otherVehicle?.classes?.imgClass}`}
          />
        </div>

        <div className="flex flex-col border border-gray-100  w-full justify-center items-start pl-10">
          <div className="flex items-center space-x-3">
            <p className="font-normal text-xl capitalize">
              {otherVehicle.name}
            </p>
            <Rating rating="4.94" trips={179} value={5} />
            {/* <CustomRating value={5} readOnly className="text-xs" /> */}
          </div>

          <div className="flex text-base mt-2 mb-2 space-x-3">
            <p className="capitalize">Hosted by {otherVehicle.host}</p>
            <p className="bg-[#0e1427] text-white px-2 rounded-md capitalize">{`${otherVehicle.rating}%`}</p>
            {/* <span className="ml-2 bg-[#0e1427] text-white rounded-md p-1 px-2 text-xs">
              DMM rating: <span className="font-light">90%</span>
            </span> */}
          </div>
          {/* <p className="text-sm">Location: {otherVehicle.location}</p> */}
          {/* <p className="mt-3 text-xs capitalize">{otherVehicle.transmission}</p> */}

          {otherVehicle.passenger ? (
            <p className="text-xs capitalize">
              {otherVehicle.passenger} passenger
            </p>
          ) : null}

          <p className=" hidden sm:flex text-[13px] capitalize">
            {otherVehicle.door} door
          </p>
        </div>

        <div
          className={`bg-[#F1F1F2] border w-[40%]  flex flex-col items-center justify-center rounded-tr-3xl ${otherVehicle?.classes?.imgClass} hidden sm:flex`}
        >
          <p className="font-bold text-[20px]">{otherVehicle.price}</p>
          <p className="text-[13px] mb-2">
            per service
            {/* {otherVehicle.serviceType} */}
          </p>
          {showButton && (
            <Button
              className="text-black text-base font-semibold rounded-full bg-[#EFCB76] px-10 py-3 mb-1"
              onClick={onClick}
            >
              Book
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherVehicles;
