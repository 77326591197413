import React from "react";

const TopOffer = () => {
  return (
    <div className="hidden lg:flex flex-col mx-auto w-[90%] p-5 mt-4 max-w-[1380px] font-light">
      <div>
        <p className="text-lg ">
          DMM is Africa's leading, all inclusive full-service online travel
          company. Our portal gives customers the power of choice from a broad
          selection of top travel deals, and the ability to research, plan and
          book their domestic and international travel from the comfort of their
          homes and everywhere they are.
        </p>
      </div>
      <div className="font-light">
        <p className="text-lg my-8">Top Offers</p>

        <div className="flex flex-col justify-between w-full ">
          <div className="flex flex-wrap justify-between gap-4">
            <div className=" mb-1">
              <h2 className="text-lg font-medium mb-1">
                Top Jet Charter Routes
              </h2>

              <div className="">
                <p>Lagos to Abuja</p>
                <p>Lagos to Port Harcourt</p>
                <p>Abuja to Lagos</p>
                <p>Lagos to London</p>
                <p>Lagos to Dubai</p>
                <p>Lagos to New York</p>
              </div>
            </div>

            <div className=" mb-1">
              <h2 className="text-lg font-medium mb-1">
                Top Car Charter Routes
              </h2>

              <div className="">
                <p>Ikeja to Lekki</p>
                <p>Ikeja to Victoria Island</p>
                <p>Lekki to Ikeja</p>
                <p>Surulere to Lagos Island</p>
                <p>Ikeja to Surulere</p>
                <p>Surulere to Lekki</p>
              </div>
            </div>

            <div className="mb-1">
              <h2 className="text-lg font-medium mb-1">
                Top Boat Charter Routes
              </h2>

              <div className="">
                <p>Apapa to Lagos Island</p>
                <p>Lekki to Takwa Bay</p>
                <p>Lagos Island to Apapa</p>
                <p>Ikorodu to Lagos Island</p>
                <p>Lagos Island to Ilashe</p>
                <p>Lekki to Inagbe</p>
              </div>
            </div>

            <div className=" mb-1">
              <h2 className="text-lg font-medium mb-1">
                Top Driver Request Routes
              </h2>
              <div className="">
                <p>Berger to Lagos Island</p>
                <p>Ikeja to Victoria Island</p>
                <p>Lekki to Ikeja</p>
                <p>Surulere to Lagos Island</p>
                <p>Ikeja to Surulere</p>
                <p>Ikeja to Lekki</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopOffer;
