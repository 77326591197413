export interface TransactionProps {
  title: string;
  price: string;
  time: string;
  date: string;
  id: number;
}

export const transactionWallet: TransactionProps[] = [
    {
      title: "Lagos to Abuja Jet charter",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 0,
    },
    {
      title: "Driver hire",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 1,
    },
    {
      title: "Rent car",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 2,
    },
    {
      title: "Driver recruit",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 3,
    },
    {
      title: "Rent car",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 4,
    },
    {
      title: "Driver recruit",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 5,
    },
    {
      title: "Lagos to London Jet charter",
      price: "N00000.00",
      time: "2:04 pm",
      date: "08/04/2020",
      id: 6,
    },
];
