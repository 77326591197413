import React from "react";
import { MdOutlineStar } from "react-icons/md";

interface RatingProps {
  rating: string;
  trips: number;
  value: number;
  readOnly?: boolean;
}

const Rating: React.FC<RatingProps> = ({ rating, trips }) => {
  return (
    <div className="flex items-center gap-1 text-[12px]">
      <span className="font-medium">{rating}</span>
      <span>
        <MdOutlineStar className="text-[#efcb76] w-4 h-4" />
      </span>

      <span className="text-gray-500 font-semibold">{`(${trips} trips)`}</span>
    </div>
  );
};

export default Rating;
