import React, { ReactNode } from "react";

const colorMap = {
  orange: {
    header: "#FBF2DC",
    body: "#FDF8EC",
  },
  green: {
    header: "#D5F7BF",
    body: "#EFFCE7",
  },
  grey: {
    header: "#C0C2C7",
    body: "#DDDEE1",
  },
};

interface ProfileTransactionCardProps {
  children: ReactNode;
  variant?: "orange" | "green" | "grey";
  title: string;
  classes?: string;
}

const ProfileTransactionCard: React.FC<ProfileTransactionCardProps> = ({
  children,
  variant = "orange",
  title,
  classes = "",
}) => {
  const { header, body } = colorMap[variant];

  return (
    <div
      style={{ backgroundColor: body }}
      className={`flex flex-col gap-2 rounded-3xl h-[600px] w-full md:w-[500px] pb-6 my-4 ${classes}`}
    >
      <div
        style={{ backgroundColor: header }}
        className="px-8 py-5 rounded-tr-3xl rounded-tl-3xl"
      >
        <p className="font-medium">{title}</p>
      </div>

      <div className="rounded-b-3xl px-6 overflow-y-auto">{children}</div>
    </div>
  );
};

export default ProfileTransactionCard;
