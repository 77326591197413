import React, { useEffect, ChangeEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuDot } from "react-icons/lu";
import axios from "axios";


interface handleNextClickProps {
  handleNextClick: () => void;
  setForms: (inputFields: any) => void;
}



const TruckDetails: FC<handleNextClickProps> = ({
  handleNextClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    carSeries: "",
    registrationNumber: "",
    doors: "",
    vehicleValue: "",
    passengers: "",
    address: "",
    year: "",
  });
  const navigate = useNavigate();


  const [selectTruckMake, setSelectTruckMake] = useState("");
  const [loading, setLoading] = useState(false);
  const [truckMake, setTruckMake] = useState<
    {
      id: number;
      make: string;
    }[]
  >([]);

  const [selectProductionYear, setSelectProductionYear] = useState("");
  const [yearMake, setYearMake] = useState([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [showList, setShowList] = useState(false);
  const [truckModel, setTruckModel] = useState<any>();
  const [selectedTruckMake, setSelectedTruckMake] = useState("");

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const handleSelectTruckMake = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectTruckMake(e.target.value);
  };
  const handleSelectProductionYear = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectProductionYear(e.target.value);
  };

  // const history = useHistory();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        // Fetch car makes
        const response = await axios.get(
           ""
        );
        console.log(response.data);
        setTruckMake(response.data.data);

        // Fetch car year
        const response2 = await axios.get(
           ""
        );
        setYearMake(response2.data.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, []);

  // useEffect(() => {
  //   const getModel = async () => {
  //     try {
  //       // Fetch car makes
  //       const response = await axios.get(
  //         `${selectTruckMake}`
  //       );
  //       console.log("response", response.data);
  //       console.log("selectCarMake", selectTruckMake);
  //       setTruckModel(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   getModel();
  // }, [selectTruckMake]);

  // if (loading) {
  //   return <div>loading...</div>;
  // }

  // const handleGoBack = () => {
  //   history.goBack();
  // };



  
  const handleCheckboxChange = (itemId: string) => {
    setCheckedItems({
      ...checkedItems,
      [itemId]: !checkedItems[itemId], // Toggle the checked state
    });
  };

  const handleTruckMakeChange = (selectedValue: any) => {
    setSelectedTruckMake(selectedValue);
    // You can perform any additional actions here based on the selected car make
  };

  return (
    <div className="mx-auto flex flex-col gap-8  w-[1140px]">
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold">Truck Details</h1>
        <p className="font-light">
          Please provide accurate deals to speed up vehicle verification
        </p>
      </div>

      <div className=" flex gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <label
            htmlFor="truck_make"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Truck type
            <select
              name="truck_make"
              id=""
              placeholder="truck make"
              className=" font-normal text-base"
              value={selectTruckMake}
              onChange={handleSelectTruckMake}
            >
              <option value=" ">Select make</option>
              {truckMake.map((data) => {
                return <option value={data.make}>{data.make}</option>;
              })}
            </select>
          </label>
          <input
            type="text"
            placeholder=" Registration number"
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <label
            htmlFor="truck_make"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Booking status
            <select name="car_make" id="" placeholder="Car make">
              <option value=" ">Select status</option>
            </select>
          </label>

          <div>
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <label htmlFor="vehicle1" className="ml-2">
              {" "}
              I agree to have the following in place
            </label>
            <div className="text-xs font-normal ml-5">
              <span className="flex ">
                <LuDot />
                <p>Reflective safety triangle or cone</p>
              </span>
              <span className="flex">
                <LuDot />
                <p>Spare tyre and tyre jack</p>
              </span>
              <span className="flex">
                <LuDot />
                <p>Free extinguisher</p>
              </span>
              <span className="flex">
                <LuDot />
                <p>Other relevant accessories</p>
              </span>
            </div>
          </div>
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <label
            htmlFor="truck_make"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Truck capacity
            <select name="car_make" id="" placeholder="Car make">
              <option value=" ">Select capacity</option>
            </select>
          </label>

          <label
            htmlFor="truck_make"
            className=" border-2 mb-2 rounded-lg p-2 flex flex-col text-xs"
          >
            {" "}
            Current truck location
            <select name="car_make" id="" placeholder="Car make">
              <option value=" ">Enter address</option>
            </select>
          </label>

          <input
            type="text"
            placeholder=" Price (NGN)"
            className=" border-2 mb-2 rounded-lg p-2"
          />
        </div>
      </div>

      {/* <input
      type="text"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
      required
      placeholder="Email or phone number"
    /> */}
      <div></div>
      <div>
        <button
          className=" bg-black text-white px-7 py-1 rounded-full mb-10"
          onClick={() => {
            handleNextClick();
            handleAppendForm();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TruckDetails;
