export interface TransactionProps {
  title: string;
  price: string;
  time: string;
  date: string;
  id: number;
  type: string;
}

export const walletHistory: TransactionProps[] = [
  {
    title: "Lagos to Abuja Jet charter",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "debit",
    id: 0,
  },
  {
    title: "Fund deposit",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "credit",
    id: 1,
  },
  {
    title: "Rent car",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "debit",
    id: 2,
  },
  {
    title: "Fund deposit",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "credit",
    id: 3,
  },
  {
    title: "Rent car",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "debit",
    id: 4,
  },
  {
    title: "Fund deposit",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "credit",
    id: 5,
  },
  {
    title: "Lagos to London Jet charter",
    price: "N00000.00",
    time: "2:04 pm",
    date: "08/04/2020",
    type: "debit",
    id: 6,
  },
];
