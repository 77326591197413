import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import Spinner from "../../components/Spinner";
import ResendVerification from "../../components/signup/ResendVerification";
import DmmDialog from "../../components/modal/DmmDialog";
import { useIsMobile } from "../../Hooks/useData";

const Verify = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState(" ");
  const [isResetVerification, setIsResetVerification] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { dispatch } = useAuth();
  const isMobile = useIsMobile();

  const verifyEmail = async () => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);

    // Get the email and code parameters from the URL
    const email = urlParams.get("email");
    const code = urlParams.get("code");

    try {
      if (code && email) {
        const { data } = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/account/verify/${email}/${code}`
          // { email: email, code: code }
        );
        if (!data?.isError === true) {
          localStorage.setItem("token", data?.data?.token?.token);
          localStorage.setItem("firstName", data?.data?.profile?.firstName);
          localStorage.setItem("lastName", data?.data?.profile?.lastName);
          localStorage.setItem("userId", data?.data?.profile?.userId);
          localStorage.setItem("role", data?.data?.profile?.role?.name);
          localStorage.setItem(
            "UserData",
            JSON.stringify({
              ...data?.data?.profile,
              token: data?.data?.token?.token,
            })
          );

          dispatch({
            type: "LOGIN",
            payload: {
              firstName: data?.data?.profile?.firstName,
              lastName: data?.data?.profile?.lastName,
              userId: data?.data?.profile?.userId,
              userImage: "path/to/image.jpg",
            },
          });
          setIsSuccess(true);
          setLoading(false);
          setMessage("Email verification successful");
        } else {
          setMessage(data.message);
          setIsError(true);
        }
      } else {
        setLoading(false);
        setMessage("Something went wrong please try again");
        setIsError(true);
        // alert("Login failed");
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setLoading(false);
      setMessage(
        (error as any).response?.data?.message ||
          "Something went wrong please try again"
      );
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <div>
      <div className=" bg-gray-200 flex py-4 justify-center  w-full  h-screen">
        <div className=" w-full md:rounded-full flex flex-col items-center  mx-auto max-w-[1280px] gap-4 p-2 flex-1 ">
          <div className=" w-full flex items-center justify-center">
            <h2 className=" text-[34px] font-bold ">Email Verification</h2>
          </div>

          {loading && <Spinner />}
          {!loading && (
            <div
              className={` w-full  h-full  flex flex-col items-center justify-center text-[30px] ${
                isError ? "text-red-600" : ""
              }`}
            >
              <p>{message}</p>

              {!isSuccess && message == "Verification code expired" && (
                <p className=" underline mt-4 cursor-pointer text-[13px]">
                  <span
                    className="underline text-blue-400 font-semibold cursor-pointer"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Click to resend verification link
                  </span>
                </p>
              )}
              {isSuccess && (
                <p
                  className=" underline mt-4 cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Click to continue
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {/* {isResetVerification &&  ( */}
      <DmmDialog
        title="Email verification"
        onClose={() => {
          setIsOpen(false);
          // setAxiosErrors("");
        }}
        open={isOpen}
        hideCloseButton={false}
        fullScreen={isMobile}
      >
        <ResendVerification />
      </DmmDialog>
      {/* )} */}
    </div>
  );
};

export default Verify;
