export function removeNairaAndConvertToInt(value: any) {
  // Remove the Naira symbol and any other non-digit characters
  let sanitizedValue = value?.replace(/[₦,]/g, "");

  // Convert the sanitized string to an integer
  let intValue = parseInt(sanitizedValue, 10);

  // Return the integer value
  return intValue;
}
