import React, { ReactNode } from "react";
import { MdKeyboardBackspace } from "react-icons/md";

interface DmmFilterProps {
  children: ReactNode;
  showMobileFilter?: boolean;
  setShowMobileFilter?: any;
}

const DmmFilter: React.FC<DmmFilterProps> = ({
  children,
  showMobileFilter = false,
  setShowMobileFilter,
}) => {
  return (
    <>
      {/* Desktop filter, only visible on desktop screens */}
      <div
        className={`hidden lg:block sticky top-0 ${
          showMobileFilter ? "hidden" : ""
        } `}
        style={{ maxHeight: "100vh", overflowY: "auto" }}
      >
        <div className={` rounded-3xl bg-[#F4F4F8] w-[280px] `}>
          <h3 className="bg-[#0E1427] text-white p-4 rounded-t-3xl">Filter</h3>

          <hr className="border-2 border-white"></hr>

          <div>{children}</div>
        </div>
      </div>

      {/* Mobile filter, only visible on mobile screens when showMobileFilter is true */}
      {showMobileFilter && (
        <div className=" absolute top-0 z-50 block lg:hidden bg-[#F4F4F8] w-full h-full">
          <div className="bg-[#0E1427] text-white border p-4 flex justify-start items-center">
            {setShowMobileFilter && (
              <MdKeyboardBackspace
                className="mr-5 cursor-pointer w-5 h-5"
                onClick={() => {
                  setShowMobileFilter(false);
                }}
              />
            )}
            <h3>Filter</h3>
          </div>

          <hr className="border-2 border-white"></hr>

          <div>{children}</div>
        </div>
      )}
    </>
  );
};

export default DmmFilter;
