import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import CustomButton from "../elements/CustomButton";
import DmmDialog from "./DmmDialog";
import { SignupFormSchema } from "../../validations/formDataSchema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Field from "../inputs/Field";
import ResendVerification from "../signup/ResendVerification";
import { useIsMobile } from "../../Hooks/useData";

type Inputs = z.infer<typeof SignupFormSchema>;

interface SignupModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLoginDialog: () => void;
  hideCloseButton?: boolean;
}

const SignupDialog: React.FC<SignupModalProps> = ({
  isOpen,
  onClose,
  setLoginDialog,
  hideCloseButton,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(SignupFormSchema),
    mode: "onBlur",
  });

  const onInvalid = (errors: any) => console.error(errors);

  const [loading, setLoading] = useState(false);
  const [isResendVerificationloading, setIsResendVerificationLoading] =
    useState(false);
  const [axiosErrors, setAxiosErrors] = useState("");
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);
  const [isResetVerification, setIsResetVerification] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const isMobile = useIsMobile();

  const handleLogin = () => {
    setLoginDialog();
    setAxiosErrors("");
    onClose();
  };

  const handleSignup: SubmitHandler<Inputs> = async (data: any) => {
    setLoading(true);

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
    } = data;

    const formattedData = {
      firstName,
      lastName,
      email: email.toLowerCase(),
      phoneNumber,
      password,
      confirmPassword,
    };

    try {
      const response = await axios.post(
        "https://dmm-backend-f562.onrender.com/api/account/createUser",
        formattedData
      );

      if (!response.data?.isError) {
        setLoading(false);
        setAxiosErrors("");
        reset();
        setIsSignUpSuccessful(true);
      } else {
        setLoading(false);
        setAxiosErrors(response.data?.message);
      }
    } catch (error) {
      setLoading(false);
      handleAxiosError(error);
    }
  };

  const handleResetVerification = async () => {
    const userEmail = watch("email");
    setIsResendVerificationLoading(true);
    setVerificationEmailSent(false);
    try {
      const response = await axios.post(
        `https://dmm-backend-f562.onrender.com/api/account/reSendVerificationLink/${userEmail}`
      );

      console.log("response", response);

      if (!response.data?.isError) {
        setVerificationEmailSent(true);
        setIsResendVerificationLoading(false);
      }
    } catch (error) {
      handleAxiosError(error);
      setIsResendVerificationLoading(false);
    }
  };

  const handleAxiosError = (error: any) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        setAxiosErrors((axiosError.response.data as any)?.message);
      } else if (axiosError.request) {
        setAxiosErrors("Request made but no response received");
      } else {
        setAxiosErrors("An unknown Axios error occurred");
      }
    } else {
      setAxiosErrors("An unknown error occurred");
    }
  };

  return (
    <DmmDialog
      title={!isSignUpSuccessful ? "Sign Up" : "Success"}
      onClose={() => {
        onClose();
        setAxiosErrors("");
        setIsResetVerification(false);
        setIsSignUpSuccessful(false);
      }}
      open={isOpen}
      hideCloseButton={hideCloseButton}
      fullScreen={isMobile}
    >
      {!isSignUpSuccessful && (
        <>
          <form
            onSubmit={handleSubmit(handleSignup, onInvalid)}
            className="flex flex-col gap-3"
          >
            <div className="flex flex-col md:flex-row md:justify-between items-center w-full gap-3 pt-2">
              <Field
                type="text"
                label="First Name"
                fieldName="firstName"
                errors={errors}
                control={control}
              />

              <Field
                type="text"
                label="Last Name"
                fieldName="lastName"
                errors={errors}
                control={control}
              />
            </div>

            <Field
              type="text"
              label="Email address"
              fieldName="email"
              errors={errors}
              control={control}
            />

            <Field
              type="text"
              label="Phone Number"
              fieldName="phoneNumber"
              errors={errors}
              control={control}
            />

            <Field
              type="password"
              label="Password"
              fieldName="password"
              errors={errors}
              control={control}
            />

            <Field
              type="password"
              label="Confirm Password"
              fieldName="confirmPassword"
              errors={errors}
              control={control}
            />

            <div className="text-left my-2">
              <p className="text-[14px] font-light">
                By signing up, you agree to service{" "}
                <span className="text-[#5CB2FC] font-medium">
                  terms and conditions
                </span>
              </p>
            </div>

            {!!axiosErrors && (
              <p className="text-[13px] text-red-700 mb-2">
                {`${axiosErrors} `}
                {axiosErrors !==
                "This account is yet to be verified, check email for verification link " ? (
                  <span
                    className="underline text-blue-400 font-semibold cursor-pointer"
                    onClick={() => {
                      setIsSignUpSuccessful(true);
                      setIsResetVerification(true);
                    }}
                  >
                    click to resend verification link
                  </span>
                ) : null}
              </p>
            )}

            <CustomButton
              loading={loading}
              type="submit"
              className="w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full"
              onClick={handleSubmit(handleSignup, onInvalid)}
            >
              Sign Up
            </CustomButton>
          </form>

          <div className="text-center mt-8">
            <p className="text-[19px] font-light">
              Already have an account?{" "}
              <span
                onClick={handleLogin}
                className="font-semibold text-[#5CB2FC] cursor-pointer"
              >
                Log in
              </span>
            </p>
          </div>
        </>
      )}
      {isResetVerification && <ResendVerification />}

      {isSignUpSuccessful && !isResetVerification && (
        <div className="flex flex-col items-center justify-center p-4">
          <p className="text-lg font-medium mb-4">
            Your account has been created successfully!
          </p>
          <p className="text-sm mb-4 text-center">
            Please check your email to verify your account. I haven't received a
            verification email?{" "}
            <span
              className="underline text-blue-400 font-semibold cursor-pointer"
              onClick={() => {
                setIsResetVerification(true);
              }}
            >
              Resend verification
            </span>
          </p>

          <CustomButton
            onClick={onClose}
            className="w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full"
          >
            Close
          </CustomButton>
        </div>
      )}
    </DmmDialog>
  );
};

export default SignupDialog;
