import React from "react";
import SkylineSketch from "../../assets/images/skyline-sketch.jpg";

const Welcome = () => {
  return (
    <div
      className="relative text-center h-[200px] lg:h-[250px] w-full"
      style={{
        backgroundImage: `url(${SkylineSketch})`,
        backgroundSize: "contain",
      }}
    >
      <div className="absolute inset-0 z-0 bg-white opacity-95"></div>

      <div className="absolute inset-0 flex items-center justify-center z-0  mx-auto max-w-[1480px] max-lg:px-6">
        <div>
          <h1 className="text-3xl lg:text-6xl font-bold font-playfairdisplay text-balance">
            Explore vehicles for any occassion
          </h1>
          <p className="mt-2 lg:mt-5 lg:text-3xl text-[#26272b] italic">
            Premium rental services from trusted, local hosts
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
