import React, { useState } from "react";

interface AccordionProps {
  title: string;
  answer: string;
  index: number;
  openIndex: number | null;
  onAccordionClick: (index: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  answer,
  index,
  openIndex,
  onAccordionClick,
}) => {
  //   const [accordionOpen, setAccordionOpen] = useState(false);
  const isOpen = openIndex === index;
  const handleClick = () => {
    onAccordionClick(index);
  };

  return (
    <div>
      <div>
        <div>
          <div className="py-2">
            <button
              //   onClick={() => setAccordionOpen(!accordionOpen)}
              onClick={handleClick}
              className="flex justify-between w-full"
              type="button"
            >
              <span className="font-normal text-left mb-3">{title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${
                  isOpen ? "rotate-180" : ""
                } h-5 w-5 transition-transform`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
            {/* {isOpen && <div>{answer}</div>} */}
            <div
              className={`grid overflow-hidden transition-all duration-300 ease-in-out text-sm ${
                // accordionOpen
                isOpen
                  ? "grid-rows-[1fr] opacity-100"
                  : "grid-rows-[0fr] opacity"
              }`}
            >
              {/* <div className="text-sm overflow-hidden">{answer}</div> */}
              {isOpen && (
                <div className="text-sm overflow-hidden">{answer}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
