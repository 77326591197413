import VehicleType from "../../components/card/VehicleType";
import MobileApp from "../../components/mobileapp/MobileApp";
import Newsletter from "../../components/newsletter/Newsletter";
import TopOffer from "../../components/top-offers/Topoffer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Faq from "../../components/FAQ/Faq";
import { useDataContext } from "../../providers/DataProvider";
import HeroSection from "../../components/hero/HeroSection";
import Booking from "../../components/bookingSteps/Booking";
import TodaysTopDeals from "../../components/topdeal/TodaysTopDeals";
import MobileSearchListCar from "../../components/mobileSearchListCar/MobileSearchListCar";
import ListYourCar from "../../components/listCar/ListYourCar";
import Welcome from "../../components/welcome/Welcome";
import { carRentalSteps } from "../../components/steps/steps";

const Homepage = () => {
  const { items } = useDataContext();

  return (
    <div>
      <ToastContainer />

      <div className="mx-auto flex flex-col items-center">
        <HeroSection />

        <Welcome />

        <Booking steps={carRentalSteps} />

        {/* <MobileServiceButtons
          menuItems={menuItems}
          selectedItem={selectedItem}
          handleItemHover={handleItemHover}
        /> */}

        <MobileSearchListCar />

        <TodaysTopDeals items={items} />

        <VehicleType />

        <ListYourCar />

        <Faq />

        <MobileApp />

        <Newsletter />

        <TopOffer />
      </div>
    </div>
  );
};

export default Homepage;
