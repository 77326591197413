import React, { FC, useEffect } from "react";
import { useData } from "../../Hooks/useData";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import FileUpload from "../../components/inputs/FileUpload";
import Field from "../../components/inputs/Field";
import FieldSwitch from "../../components/inputs/FieldSwitch";
import FieldDate from "../../components/inputs/FieldDate";
import FieldSelect from "../../components/inputs/FieldSelect";
import { acceptedIDs } from "../../utils/constants";

interface handleNextClickProps {
  register: any;
  errors: any;
  watch: any;
  control: any;
  setValue: any;
}

export const getFormattedErrors = (
  currentErrs: Record<string, string[]>
): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(currentErrs).map(([key, value]) => [key, value[0]])
  );
};

const HostDetails: FC<handleNextClickProps> = ({
  register,
  errors,
  watch,
  control,
  setValue,
}) => {
  const watchRegisterCompany = watch("companyDetails.registerCompany", false);

  const { userData } = useData();

  useEffect(() => {
    setValue("firstName", userData?.firstName as string);
    setValue("lastName", userData?.lastName as string);
    setValue("email", userData?.email as string);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="w-full flex flex-col items-center gap-8 max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader
        header="Host details"
        subHeader="Please provide accurate deals to speed up vehicle verification"
      />

      <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-4  opacity-70 justify-items-center justify-center">
        <Field
          type="text"
          label="First Name"
          fieldName="firstName"
          errors={errors}
          control={control}
        />

        <Field
          type="text"
          label="Last Name"
          fieldName="lastName"
          errors={errors}
          control={control}
        />

        <Field
          type="text"
          label="Email Address"
          fieldName="email"
          disabled
          errors={errors}
          control={control}
        />

        <Field
          type="text"
          label="Phone Number"
          fieldName="phoneNumber"
          errors={errors}
          control={control}
        />

        <Field
          type="text"
          label="Current Address"
          fieldName="address"
          errors={errors}
          control={control}
        />

        <FieldSwitch
          label="Are you registering as a company?"
          fieldName="companyDetails.registerCompany"
          control={control}
        />

        {watchRegisterCompany && (
          <FileUpload
            control={control}
            name="companyDetails.cacDocument"
            errors={errors}
            watch={watch}
            label="Upload CAC document"
            errMessage={errors?.companyDetails?.cacDocument?.message}
          />
        )}

        <FieldDate
          label="Date of birth"
          fieldName="hostDateOfBirth"
          errors={errors}
          register={register}
        />

        <FileUpload
          control={control}
          name="passport"
          errors={errors}
          watch={watch}
          label="Upload passport photo"
        />

        <FieldSelect
          control={control}
          errors={errors}
          options={acceptedIDs.map((id) => ({ value: id, label: id }))}
          label="Select ID type"
          fieldName="idType"
        />

        <FileUpload
          control={control}
          name="validIDCard"
          errors={errors}
          watch={watch}
          label="Upload valid ID"
        />

        <FileUpload
          control={control}
          name="utilityBill"
          errors={errors}
          watch={watch}
          label="Upload utility bill"
        />
      </div>
    </div>
  );
};

export default HostDetails;
