import React from "react";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { getRoutesList } from "./NavigationButtons";
import { useDataContext } from "../../providers/DataProvider";

interface UserAssetsProps {
  navigate: (path: string, state?: any) => void;
}

const UserAssets: React.FC<UserAssetsProps> = ({ navigate }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { listingData } = useDataContext();

  const parts = getRoutesList(currentPath);

  return (
    <div
      className={`w-full lg:w-[550px] ${
        !parts.includes("Wallet") ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center w-[100%]">
        <div className="md:border-[rgb(151,150,150)] md:border-1 md:border sm:rounded-2xl flex-wrap md:px-3 py-4 flex items-center w-[100%] justify-between gap-6 bg-white">
          <div>
            <p className="text-[12px] font-light">Assets listed</p>
            <p className="text-[18px] -mt-1">{listingData.length}</p>
          </div>
          <div
            className="lg:flex cursor-pointer border rounded-full px-5 text-[12px] md:text-[14px] py-2 bg-[#0E1427] text-white lg:mt-0 flex justify-center items-center gap-3"
            onClick={() => navigate("/car/list-vehicle", { state: 1 })}
          >
            <span>
              <FiPlus />
            </span>

            <p>Add asset</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAssets;
