import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { activeBookings } from "../../assets/jsons/active-bookings";
import { upcomingBookings } from "../../assets/jsons/upcoming-bookings";
import { completedBookings } from "../../assets/jsons/completed-bookings";
import { CardProps } from "../../assets/jsons/completed-bookings";
import { useData } from "../../Hooks/useData";
import axios from "axios";
import ProfileTransactionCard from "../../components/card/ProfileTransactionCard";
import BookingsCarCard from "../../components/card/BookingsCarCard";
import BookingsUserToggle from "../../components/bookings/BookingsUserToggle";
import { useUserStatus } from "../../context/userStatusContext";

interface StatusProps {
  component: CardProps[];
  title: string;
  id: number;
}

const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const [currentComponent, setCurrentComponent] = useState<CardProps[] | null>(
    activeBookings
  );
  const [userBookingData, setUserBookingData] = useState<any[]>([]);
  const [hostBookingData, setHostBookingData] = useState<any[]>([]);
  const { userData } = useData();
  const { userStatus, setUserStatus } = useUserStatus();
  const role = userData?.role?.name;

  const status: StatusProps[] = [
    { title: "Active", component: activeBookings, id: 1 },
    { title: "Upcoming", component: upcomingBookings, id: 2 },
    { title: "Completed", component: completedBookings, id: 3 },
  ];

  const handleSetComponent = (component: CardProps[]) => {
    setCurrentComponent(component);
  };

  const fetchWalletData = async () => {
    try {
      if (userData?.userId) {
        const outgoingBookings = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/booking/getUserBookings/${userData?.userId}`
        );

        setUserBookingData(outgoingBookings.data?.data);

        if (role === "Host") {
          const incommingBookings = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/booking/getHostBookings/${userData?.userId}`
          );

          setHostBookingData(incommingBookings.data?.data);
        }
      }
    } catch (error) {
      console.log("error fetching account balance data --> ", error);
    }
  };
  useEffect(() => {
    fetchWalletData();
  }, [userData]);

  const displayedBookings =
    userStatus === "you" ? userBookingData : hostBookingData;

  const categorizeBookings = () => {
    const completedBooking: any[] = [];
    const upcomming: any[] = [];
    const activeBooking: any[] = [];

    displayedBookings?.forEach((booking) => {
      if (booking.status === "CANCELLED" || booking.status === "FULFILLED") {
        completedBooking.push(booking);
      } else if (
        booking.status === "PENDING" ||
        booking.status === "CONFIRM" ||
        booking.status === "OFFER"
      ) {
        upcomming.push(booking);
      } else {
        activeBooking.push(booking);
      }
    });

    return { completedBooking, upcomming, activeBooking };
  };

  const { completedBooking, upcomming, activeBooking } = categorizeBookings();

  return (
    <>
      {role === "Host" && !!userBookingData.length && (
        <BookingsUserToggle value={userStatus} setValue={setUserStatus} />
      )}

      <div className="bg-[#f4f4f4] w-full py-6 md:hidden mt-4">
        <div className="grid grid-cols-2 gap-2 items-center justify-center px-2">
          {status.map((status, i) => (
            <button
              key={status.id}
              className={`${
                status.component === currentComponent
                  ? "bg-[#efcb76] border-none font-normal"
                  : "bg-white-600 font-light"
              }  border border-1 border-[#8B8989] text-[14px] rounded-full py-2 px-10 whitespace-nowrap`}
              onClick={() => handleSetComponent(status.component)}
            >
              {status.title}
            </button>
          ))}
        </div>
      </div>

      <div className="w-full block md:hidden sm:px-16">
        <div
          className={`${
            currentComponent === activeBookings ? "block" : "hidden"
          }`}
        >
          {activeBooking.map((val, idx) => (
            <BookingsCarCard key={idx} navigate={navigate} booking={val} />
          ))}
        </div>

        <div
          className={`${
            currentComponent === upcomingBookings ? "block" : "hidden"
          }`}
        >
          {upcomming.reverse().map((val, idx) => (
            <BookingsCarCard key={idx} navigate={navigate} booking={val} />
          ))}
        </div>

        <div
          className={`${
            currentComponent === completedBookings ? "block" : "hidden"
          }`}
        >
          {completedBooking.reverse().map((val, idx) => (
            <BookingsCarCard key={idx} navigate={navigate} booking={val} />
          ))}
        </div>
      </div>

      <div className="hidden md:flex flex-col w-full lg:flex-row gap-8 px-6 justify-center items-center max-w-[1350px]">
        <ProfileTransactionCard title="Active Booking">
          {activeBooking.reverse().map((val, idx) => (
            <BookingsCarCard
              key={idx}
              navigate={navigate}
              booking={val}
              userStatus={userStatus}
            />
          ))}
        </ProfileTransactionCard>

        <ProfileTransactionCard variant="green" title="Upcoming Booking">
          {upcomming.map((val, idx) => (
            <BookingsCarCard key={idx} navigate={navigate} booking={val} />
          ))}
        </ProfileTransactionCard>

        <ProfileTransactionCard variant="grey" title="Completed Booking">
          {completedBooking.map((val, idx) => (
            <BookingsCarCard key={idx} navigate={navigate} booking={val} />
          ))}
        </ProfileTransactionCard>
      </div>
    </>
  );
};

export default Bookings;
