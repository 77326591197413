import React, { ChangeEvent, FC, useState } from "react";

interface handleClickProps {
  handleListAssetClick: () => void;
  setForms: (inputFields: any) => void;
}
const Declaration: FC<handleClickProps> = ({
  handleListAssetClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    accountName: "",
    accountNum: "",
    bank: "",
    sortCode: "",
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  return (
    <div className=" flex flex-col gap-8 w-[1140px]">
      <div className="flex flex-col gap-2">
        <h1 className=" font-bold mt-8">Declaration</h1>
        {/* <p className=" font-semibold">Please note all details provided will be used for remittance of monies you generate from your asset.</p> */}
      </div>

      {/* <div className=" flex gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <div className="flex items-center gap-4">
            <p>My car's AC is in perfect condition:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="ac_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="ac_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p>My car has no mechanical or electrical issues:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p className="text-">
              There are no cosmetic damages or decolouration of any visible
              parts of my car:{" "}
            </p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p>My car has a functional stereo/Radio player:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p>My window winders are complete and functional:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <div className="flex items-center gap-4">
            <p>My vehicle does not smoke:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p>
              There is no unusual sound from either my car’s engine or other
              mechanical components:
            </p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p> My Car is serviced regularly:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          
          <div className="flex flex-col gap-4">
            <p>
              Is there anything you would like to fix in this car before rental?
            </p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
            <div className="mt-4">
              <label className="block">If yes, please state:</label>
              <textarea className="w-full border border-gray-300 rounded-md p-2" />
            </div>
          </div>

          <div className="flex items-center gap-4">
            <p>My car is ready for rental:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      <div className="text-base flex justify-between space-x-16">
        <div className=" flex flex-col w-1/2 gap-4">
          <div className="flex justify-between items-center gap-4">
            <p>My car's AC is in perfect condition:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="ac_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="ac_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p>My car has no mechanical or electrical issues:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p className="text-">
              There are no cosmetic damages or decolouration of any visible
              parts of my car:{" "}
            </p>
            <div className="flex items-center gap-2 mt-[-18px]">
              <div className="flex">
                <input
                  type="checkbox"
                  name="vehicle_condition"
                  value="yes"
                  title="checkbox"
                />
                <label className="">
                  <span className="ml-2">Yes</span>
                </label>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="vehicle_condition"
                  value="no"
                  title="checkbox"
                />
                <label>
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p>My car has a functional stereo/Radio player:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p>My window winders are complete and functional:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <div className="flex justify-between items-center gap-4">
            <p>My vehicle does not smoke:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p className="">
              There is no unusual sound from either my car’s engine or other
              mechanical components:
            </p>
            <div className="flex items-center gap-2 mt-[-18px]">
              <div className="flex">
                <input
                  type="checkbox"
                  name="vehicle_condition"
                  value="yes"
                  title="checkbox"
                />
                <label className="">
                  <span className="ml-2">Yes</span>
                </label>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="vehicle_condition"
                  value="no"
                  title="checkbox"
                />
                <label>
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p> My Car is serviced regularly:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center gap-4">
            <p>My car is ready for rental:</p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-between flex-col gap-4">
            <p>
              Is there anything you would like to fix in this car before rental?
            </p>
            <div className="flex items-center gap-2">
              <label>
                <input type="checkbox" name="vehicle_condition" value="yes" />
                <span className="ml-2">Yes</span>
              </label>
              <label>
                <input type="checkbox" name="vehicle_condition" value="no" />
                <span className="ml-2">No</span>
              </label>
            </div>
            <div className="mt-[-4px]">
              <label className="block mb-1">If yes, please state:</label>
              <textarea
                className="w-full border border-gray-300 rounded-md p-2"
                title="textarea"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="font-bold mt-5">
          I acknowledge that my vehicle can be called upon at any time for
          inspection. I accept that DMM reserves the right to suspend or delist
          my vehicle if any of the provided information above is false.
        </p>
      </div>
      <div>
        <button
          className=" bg-black text-white px-5 py-1 rounded-full"
          onClick={() => {
            handleListAssetClick();
            handleAppendForm();
          }}
        >
          List asset
        </button>
      </div>
    </div>
  );
};

export default Declaration;
