import { Avatar } from "@mui/material";
import React from "react";
import { stringAvatar } from "./UserDetails";
import { User } from "../../utils/types";
import profilePlaceholder from "../../assets/images/avatar.webp";
import UserWallet from "./UserWallet";
import UserAssets from "./UserAssets";

interface UserDetailsProps {
  userData: User | null;
  walletData: {
    balance: number;
  };
  setWalletModal: (value: boolean) => void;
  navigate: (path: string) => void;
}

const MobileProfile: React.FC<UserDetailsProps> = ({
  userData,
  walletData,
  setWalletModal,
  navigate,
}) => {
  return (
    <div className="flex md:hidden gap-6 justify-start px-6 mt-6 items-start w-full">
      <div>
        <Avatar
          {...stringAvatar(
            `${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`
          )}
          src={profilePlaceholder}
          alt="Profile image"
          className="border border-1 border-black"
        />
      </div>

      <div className="flex flex-col justify-start items-start font-medium py-2 w-full">
        <div>
          <h2>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</h2>

          {/* <p className="text-[12px] font-light">User ID: {userData?.userId}</p> */}
        </div>

        <hr className="mt-3 border-[1/3px] border-[#E2E6F0] w-full" />

        <div className="w-full">
          <UserWallet walletData={walletData} setWalletModal={setWalletModal} />

          <UserAssets navigate={navigate} />
        </div>
      </div>
    </div>
  );
};

export default MobileProfile;
