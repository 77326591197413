import React, { Ref, forwardRef, memo, useId } from "react";
import MoonLoader from "react-spinners/MoonLoader";

interface CustomButtonProps {
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  // ref?: Ref<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
  className?: string;
  loaderclasses?: string;
  onClick?: any;
  color?: string;
}

const CustomButton: React.FC<CustomButtonProps> = forwardRef(
  (
    {
      loading = false,
      disabled,
      children,
      type = "submit",
      className,
      loaderclasses,
      onClick,
      color = "#ffffff",
    },
    ref: Ref<HTMLButtonElement>
  ) => {
    const override = {
      borderColor: "#ffffff",
    };
    const id = useId();
    return (
      <button
        type={type}
        ref={ref}
        disabled={disabled}
        className={`flex items-center justify-center gap-5 ${className}`}
        onClick={onClick}
      >
        <>
          <MoonLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={20}
            className={loaderclasses}
            // aria-label="Loading Spinner"
            data-testid={id}
          />

          {children && <span>{children}</span>}
        </>
      </button>
    );
  }
);

export default CustomButton;
