import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import { FiPhone } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { MdOutlineMailOutline, MdWarningAmber } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";

const SupportButton = () => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      title={<SupportContent />}
      arrow
      PopperProps={{
        disablePortal: true,
        sx: {
          [`& .${tooltipClasses.arrow}`]: {
            fontSize: "40px",
            color: "#F3F4F6",
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "rgba(0, 0, 0, 0.87)",
            border: "1px solid #F3F4F6",
            borderRadius: "25px",
            minWidth: "350px",
            padding: 0,
          },
        },
      }}
      onClose={handleTooltipClose}
      open={open}
    >
      <li
        className="block py-2 px-4 text-[#0e1427] rounded-full mr-2 cursor-pointer"
        onClick={handleTooltipOpen}
      >
        Support
      </li>
    </Tooltip>
  );
};

export default SupportButton;

export const SupportContent = () => {
  const iconStyle = "text-[25px] text-black";

  return (
    <div className=" ">
      <div className="lg:px-8 pt-10 pb-6 flex flex-col justify-start gap-5">
        <div className="support-item">
          <FiPhone className={iconStyle} />
          <span className="text-[20px] font-normal">0818 5500 DMM</span>
        </div>

        <div className="support-item">
          <FiPhone className={iconStyle} />
          <span className="text-[20px] font-normal">0818 5500 366</span>
        </div>

        <hr className="border-1 border-black my-2" />

        <div className="support-item">
          <IoLogoWhatsapp className={iconStyle} />
          <span className="text-[20px] font-normal">WhatsApp</span>
        </div>

        <div className="support-item">
          <RiCustomerService2Line className={iconStyle} />
          <span className="text-[20px] font-normal">Contact Support</span>
        </div>

        <div className="support-item">
          <MdOutlineMailOutline className={iconStyle} />
          <span className="text-[20px] font-normal">hello@dmm.ng</span>
        </div>
      </div>

      <div>
        <div className="flex justify-start items-center w-full gap-6 bg-[#FF0214] max-md:rounded-full rounded-b-[25px] px-8 py-1 lg:py-3">
          <MdWarningAmber className="text-[25px] text-white" />

          <span className="text-[20px] text-white">
            <div>
              <p className="text-[13px]">Emergency</p>
              <p className="-mt-1 font-normal">0906 000 7284</p>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
