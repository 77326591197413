import { ReactNode } from "react";
import { Breakpoint, DialogContent, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Close } from "@mui/icons-material";

interface DmmDialogProps {
  children: ReactNode;
  title: string;
  open: boolean;
  onClose: () => void;
  maxWidth?: false | Breakpoint | undefined;
  width?: string | number | object;
  borderRadius?: string | number;
  padding?: string;
  containerClasses?: string;
  hideCloseButton?: boolean;
  carousel?: boolean;
  fullScreen?: boolean;
}

const DmmDialog: React.FC<DmmDialogProps> = ({
  children,
  title,
  open,
  onClose,
  maxWidth = "sm",
  width = "550px",
  borderRadius = "30px",
  padding = "p-6 lg:p-10",
  containerClasses,
  hideCloseButton = false,
  carousel = false,
  fullScreen = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width,
          maxWidth: "100%",
          borderRadius: borderRadius,
          // pt: 2,
          "& .css-ypiqx9-MuiDialogContent-root": {
            padding: 0,
          },
        },
      }}
    >
      <div className={`${containerClasses} ${padding} relative`}>
        {!hideCloseButton && (
          <div className="absolute flex justify-end items-center w-full right-6 top-5 z-50">
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                border: 1,
                borderRadius: 50,
                borderColor: "#E5C486",
                boxShadow: 1,
              }}
            >
              <Close sx={{ fontSize: { xs: 15, md: 20 }, color: "black" }} />
            </IconButton>
          </div>
        )}

        <div className="flex flex-col justify-center items-center h-full w-full">
          <div className={`${carousel ? "" : "mt-12 md:mt-6"} w-full`}>
            {title && (
              <DialogTitle
                id="login-dialog-title"
                sx={{
                  textAlign: "left",
                  width: "100%",
                  "&.MuiDialogTitle-root": {
                    paddingX: 0,
                    paddingY: 0,
                  },
                }}
              >
                <div className="dialog-title">
                  <Typography variant="h5">{title}</Typography>
                </div>
              </DialogTitle>
            )}
          </div>

          <DialogContent className="h-full w-full">{children}</DialogContent>
        </div>
      </div>
    </Dialog>
  );
};

export default DmmDialog;
