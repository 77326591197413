export const formatCurrency = (number: number, noDecimal = false): string => {
  if (!number && number !== 0) return "";

  let formattedNumber: string;

  if (noDecimal) {
    formattedNumber = number
      ?.toFixed(0)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    formattedNumber = number
      ?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return `₦${formattedNumber}`;
};
