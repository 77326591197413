import React, { useMemo } from "react";
import { formatCurrency } from "../../utils/stringFunctions";
import { useCheckCurrentCarHost, useData } from "../../Hooks/useData";
import { useDataContext } from "../../providers/DataProvider";
import { useUserStatus } from "../../context/userStatusContext";

interface User {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  userData?: any;
}

interface BookingData {
  userId: User;
  hostId?: string;
  offerStatus?: string;
  prevPrice?: number;
  bookingPrice: number;
}

interface OrderSummaryProps {
  data: BookingData;
  title?: string;
  otherData?: BookingData;
  showContactDetails?: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  data,
  title,
  otherData,
  showContactDetails = true,
}) => {
  const { userData } = useData();
  const { userStatus } = useUserStatus();
  const { VAT_RATE } = useDataContext();
  const role = userData?.role?.name;

  const { isCurrentCarHost, hostData } = useCheckCurrentCarHost({
    hostId: data?.hostId,
  });

  const contact = useMemo(
    () => ({
      title: isCurrentCarHost ? "User Details" : "Host Details",
      firstName: isCurrentCarHost
        ? data?.userId?.firstName
        : hostData?.firstName,
      lastName: isCurrentCarHost ? data?.userId?.lastName : hostData?.lastName,
      phoneNumber: isCurrentCarHost
        ? data?.userId?.phoneNumber
        : hostData?.phoneNumber,
      email: isCurrentCarHost ? data?.userId?.email : hostData?.email,
    }),
    [isCurrentCarHost, data?.userId, hostData]
  );

  const TransactionDetails = () => (
    <div className="p-2 border-t-2 border-t-white">
      <p>Transaction Details</p>
      {role === "Host" &&
        data?.offerStatus === "PENDING" &&
        isCurrentCarHost && (
          <>
            <>
              <p className="text-xs mt-2">Previous Transaction Details</p>
              <div className="text-xs flex justify-between gap-4 font-light mt-2">
                <p className="text-xs">Service payment</p>
                <p className="text-xs">
                  {otherData?.prevPrice &&
                    formatCurrency(
                      (otherData?.prevPrice / (1 + VAT_RATE)) * 0.8
                    )}
                </p>
              </div>
              <div className="text-xs flex justify-between gap-4 font-light">
                <p>Service Charge</p>
                <p>
                  {otherData?.prevPrice &&
                    formatCurrency(
                      (otherData?.prevPrice / (1 + VAT_RATE)) * 0.2
                    )}
                </p>
              </div>
              <div className="flex justify-between font-medium mt-4">
                <p className="text-sm">Subtotal</p>
                <p className="text-xs">
                  {otherData?.prevPrice &&
                    formatCurrency(otherData?.prevPrice / (1 + VAT_RATE))}
                </p>
              </div>
              <div className="text-xs flex justify-between gap-4 font-light">
                <p>VAT(7.5%)</p>
                <p>
                  {otherData?.prevPrice &&
                    formatCurrency(
                      (otherData?.prevPrice / (1 + VAT_RATE)) * VAT_RATE
                    )}
                </p>
              </div>
              <div className="flex justify-between font-medium mt-2 border-b-2 border-white">
                <p>Total</p>
                <p>
                  {otherData?.prevPrice && formatCurrency(otherData?.prevPrice)}
                </p>
              </div>
            </>
            <>
              <p className="text-xs mt-2">Current Transaction Details</p>
            </>
          </>
        )}
      <div className="text-xs flex justify-between gap-4 font-light mt-2">
        <p className="text-xs">Service payment</p>
        <p className="text-xs">
          {formatCurrency((data?.bookingPrice / (1 + VAT_RATE)) * 0.8)}
        </p>
      </div>
      <div className="text-xs flex justify-between gap-4 font-light">
        <p>Service Charge</p>
        <p>{formatCurrency((data?.bookingPrice / (1 + VAT_RATE)) * 0.2)}</p>
      </div>
      <div className="flex justify-between font-medium mt-4">
        <p className="text-sm">Subtotal</p>
        <p className="text-xs">
          {formatCurrency(data?.bookingPrice / (1 + VAT_RATE))}
        </p>
      </div>
      <div className="text-xs flex justify-between gap-4 font-light">
        <p>VAT(7.5%)</p>
        <p>
          {formatCurrency((data?.bookingPrice / (1 + VAT_RATE)) * VAT_RATE)}
        </p>
      </div>
      <div className="flex justify-between font-medium mt-4">
        <p>Total</p>
        <p>{formatCurrency(data?.bookingPrice)}</p>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-2 lg:rounded-md bg-[#f4f4f4] lg:w-[25%] lg:h-[70%] mt-[50px] md:mt-0 p-4 lg:p-2 mb-10 lg:mb-0">
      <div className="p-2 ">
        <p>{title || "Order Summary"}</p>
      </div>

      {showContactDetails && (
        <div className="p-2 border-t-2 border-t-white">
          <p>{contact.title}</p>
          <p className="text-xs font-light mt-2">
            {contact.firstName} <span>{contact.lastName}</span>
          </p>
          <p className="text-xs">{contact?.email}</p>
          <p className="text-xs">{contact.phoneNumber}</p>
        </div>
      )}

      {!!data?.bookingPrice && !!VAT_RATE && <TransactionDetails />}
    </div>
  );
};

export default OrderSummary;
