import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import DetailsHeader from "./DetailsHeader";

interface DmmDetailsProps {
  onClick?: () => void;
  handleDetailsClick?: () => void;
  handleStatusChange?: (id: string) => void;
  data: any;
  children: ReactNode;
  serviceType?: string;
  HostId?: any;
  IdNo?: any;
  Type?: any;
  price: any;
  backToListingButton?: boolean;
  showBookButton?: boolean;
  // footerChildren?: ReactNode;
}

const DmmDetails: React.FC<DmmDetailsProps> = ({
  handleDetailsClick,
  handleStatusChange,
  children,
  data,
  price,
  backToListingButton,
  onClick,
  showBookButton,
  serviceType,
  HostId,
  Type,
  IdNo,
  // footerChildren,
}) => {
  const navigate = useNavigate();

  const childrenArray = React.Children.toArray(children);
  const midChildren = childrenArray[0];
  const footerChildren = childrenArray[1];

  return (
    <div className="flex flex-col items-center md:px-6 w-full">
      <div className="flex flex-col w-full max-w-[1350px] min-h-[700px] border border-1 border-[#C4CFDF] rounded-3xl">
        <DetailsHeader
          data={data}
          price={price}
          serviceType={serviceType}
          onClick={onClick}
        />

        {midChildren}

        <div className="bg-[#0E1427] flex flex-col md:flex-row items-center justify-between w-full px-2 md:px-10 py-7 rounded-br-3xl rounded-bl-3xl mt-6 h-full">
          {footerChildren}
        </div>
      </div>

      {backToListingButton && (
        <div className="mt-6 w-full max-w-[1250px]">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="flex justify-center items-center gap-2 rounded-full border border-1 px-5 py-1 text-[14px] border-[#C4CFDF]"
          >
            <MdOutlineKeyboardBackspace /> Back to listings
          </button>
        </div>
      )}
    </div>
  );
};

export default DmmDetails;
