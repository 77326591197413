import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { IoLocation } from "react-icons/io5";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useEffect, useRef, useState } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

interface DmmAutocompleteProps {
  selected: any;
  setSelected: any;
  inputValue: any;
  setInputValue: any;
  disabled?: any;
  inputStyle?: any;
  error?: any;
  errorMessage?: string;
  variant?: any;
  outlineStyle?: any;
  onBlur?: any;
  label?: string;
  placeholder?: string;
  inputProps?: any;
}

// interface Part {
//   text: string;
//   highlight: boolean;
// }

const DmmAutocomplete: React.FC<DmmAutocompleteProps> = ({
  selected,
  setSelected,
  inputValue,
  setInputValue,
  disabled,
  error,
  errorMessage,
  inputStyle = {
    fontWeight: 300,
    fontSize: 15,
  },
  outlineStyle = {},
  variant = "standard",
  onBlur,
  label,
  placeholder = "Enter location",
  inputProps = { endAdornment: null, disableUnderline: true },
}) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService?.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService?.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = async (event: any, newValue: any) => {
    const address = newValue?.description;

    try {
      const [addressComp] = await getGeocode({ address });

      const { lat, lng } = getLatLng(addressComp);

      setOptions(newValue ? [newValue, ...options] : options);

      setValue(newValue);

      setSelected({ lat, lng, address });
    } catch (error: any) {
      console.error("Error fetching geocode:", error);
      if (error instanceof Error) {
        if (error?.message === "ZERO_RESULTS") {
          // alert("No results found. Please try another location.");
        } else {
          // alert("An error occurred while searching for the place. Please try again.");
        }
      }
    }
  };

  const handleBlur = () => {
    const match = options.find((option) => option.description === inputValue);

    if (!match) {
      setInputValue("");
    }

    onBlur && onBlur();
  };

  return (
    <Autocomplete
      className="w-full"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={inputValue}
      disabled={disabled}
      noOptionsText="No locations"
      onChange={handleChange}
      freeSolo
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={!!label ? label : undefined}
          variant={variant}
          error={error}
          helperText={errorMessage}
          onBlur={handleBlur}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
          }}
          sx={{
            "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": inputStyle,
            "& .MuiOutlinedInput-root": outlineStyle,
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <div className="flex justify-start items-start gap-3">
              <div className="w-[25px] h-[25px]">
                <IoLocation className="w-full h-full text-[#D5B25F]" />
              </div>

              <div className="flex-1">
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};

export default DmmAutocomplete;
