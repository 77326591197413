import React from "react";

const BookingStep = ({
  src,
  title,
  description,
}: {
  src: string;
  title: string;
  description: string;
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={src} alt="" className="mb-3" />

      <h2 className="text-xl font-medium text-center">{title}</h2>

      <p className="text-base font-light text-balance text-center whitespace-normal max-w-[320px]">
        {description}
      </p>
    </div>
  );
};

export default BookingStep;
