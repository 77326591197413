import { createTheme } from "@mui/material/styles";

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "black", // Text color in form fields
        },
        input: {
          "::placeholder": {
            color: "black", // Placeholder color
            opacity: 0.5, // Make sure the placeholder is not semi-transparent
          },
        },
      },
    },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: "black", // Label color
    //     },
    //   },
    // },
  },
});

export default theme;
