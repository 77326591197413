import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/authContext";
import { DataProvider } from "./providers/DataProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import "@fontsource/roboto"; // Defaults to weight 400
import { UserStatusProvider } from "./context/userStatusContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
    <UserStatusProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DataProvider>
          <App />
        </DataProvider>
      </ThemeProvider>
    </UserStatusProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
