import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { truckTopDeals } from "../../assets/jsons/truck-top-deals";
import { Button } from "@material-tailwind/react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import SkeletonLoading from "../../components/SkeletonLoading";

const TruckTopDeal = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const showNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const showPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems: any[] = items?.slice(startIndex, endIndex);

  return (
    <div>
      <div className="max-w-[1240px] mx-auto">
        <h1 className="font-semibold text-xl">Today's Top Deals</h1>

        <div className="flex items-center justify-between mt-5 mb-10 w-full space-x-5">
          {truckTopDeals.map((deal) => (
            <div className="w-full flex-1" key={deal.id}>
              <div className="border rounded-3xl overflow-hidden">
                <div className="w-80 h-48 relative">
                  <img
                    src={deal.image}
                    alt=""
                    className="rounded-tl-3xl rounded-tr-3xl w-full h-full object-cover"
                  />
                </div>

                <div className="p-4 bg-white rounded-b-3xl">
                  <div className="flex items-center justify-between">
                    <h2 className="text-base font-bold">{deal.name}</h2>
                    <div className="flex items-center">
                      <deal.icon className="w-5 h-5 mr-1" />
                      <span className="text-gray-500">{deal.rating}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <p className="text-sm">Location: {deal.location}</p>
                    <Button
                      className="px-4 py-2 bg-blue-950 text-white rounded-full"
                      onClick={() => {
                        // navigate("");
                      }}
                    >
                      Request quote
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TruckTopDeal;
