import React from "react";
import Rating from "../rating/Rating";
import { getSecondToLastWord, updateParams } from "../../utils/utilsFunctions";
import { formatCurrency } from "../../utils/stringFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { CarDetailsProps } from "../../utils/carDetailsProps";
import { useDataContext } from "../../providers/DataProvider";
import { serviceTypesWithQuoteRequired } from "../../utils/constants";

const CarCard = ({
  item,
  queryParams,
}: {
  item: CarDetailsProps;
  queryParams: URLSearchParams;
}) => {
  const {
    fromWhereSelected,
    finalDestinationSelected,
    returnTime,
    pickupTime,
    serviceType,
  } = useDataContext();

  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const params = updateParams({
    serviceType,
    fromWhereSelected,
    finalDestinationSelected,
    pickupTime,
    returnTime,
    queryParams,
  });

  const handleViewCar = (item: any) => {
    const url = `/car/details/${item.carCode}?${params.toString()}`;

    navigate(url, { state: { data: item, from: location.pathname } });
  };

  const Tag = ({ message }: { message: string }) => {
    return (
      <div className="bg-white text-[#0E1427] text-[12px] font-medium px-[8px] rounded-full">
        {message}
      </div>
    );
  };

  const TagsContainer = ({ tags }: { tags: any }) => {
    return (
      <div className="absolute top-[15px] right-3 flex gap-[8px]">
        {tags.map((tag: string, index: number) => (
          <Tag key={index} message={tag} />
        ))}
      </div>
    );
  };

  const currentServiceType = currentQueryParams.get("serviceType");

  return (
    <>
      <div className="hidden lg:flex flex-row items-center justify-between w-full h-[200px] rounded-[2rem] overflow-hidden border border-1 border-[#E5E7EB]">
        <div
          className="w-[35%] h-full flex-shrink-0 bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${item?.carImages?.[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <TagsContainer
            tags={[
              item?.canGuestDriveYourCar && "Self-drive",
              item?.allowClientOfferPrice && "Negotiable",
            ].filter(Boolean)}
          />
        </div>

        <div className="w-[45%] pl-5 h-full flex flex-col justify-center items-start">
          <div className="mb-3">
            <span className="font-medium text-lg">
              {item?.carMakeID?.make} {item?.carMakeID?.mode}{" "}
              {item?.carMakeID?.year}
            </span>
            <span>
              <Rating rating="4.94" trips={179} value={5} />
            </span>
          </div>

          <p className="text-sm mt-1 font-light space-x-2">
            Hosted by:{" "}
            <span className="font-medium capitalize">{`${item?.hostID?.userId.firstName} ${item?.hostID?.userId.lastName}`}</span>
            {/* <span className="bg-[#0e1427] text-white px-2 rounded-md capitalize">{`${item.dmmRating}%`}</span> */}
          </p>
          <p className="text-sm font-light mt-1">
            Location:{" "}
            <span className="font-medium">
              {getSecondToLastWord(item?.address)}
            </span>
          </p>
          <p className="text-sm font-light mt-1">
            Body type:{" "}
            <span className="font-medium">{item?.vehicleType?.join(", ")}</span>
          </p>
        </div>

        <div className="w-[20%] h-full flex flex-col justify-center items-center gap-2 bg-[#F1F1F2] p-4 text-center">
          {!!currentServiceType &&
            !serviceTypesWithQuoteRequired.includes(currentServiceType) && (
              <>
                <h2 className="font-medium text-xl">
                  {formatCurrency(
                    item.serviceTypes?.filter((val: any) => {
                      return val?.name === serviceType;
                    })[0]?.price ?? item?.serviceTypes?.[0]?.price,
                    true
                  )}
                </h2>
                <p className="font-light">
                  per{" "}
                  <span className="lowercase">
                    {item.serviceTypes?.filter((val: any) => {
                      return val?.name === serviceType;
                    })[0]?.name ?? item?.serviceTypes?.[0]?.name}
                  </span>
                </p>
              </>
            )}

          <button
            className="bg-[#efcb76] px-7 py-1 rounded-full font-medium"
            onClick={() => {
              handleViewCar(item);
            }}
          >
            VIEW
          </button>
        </div>
      </div>

      <div className="flex lg:hidden flex-col justify-between items-center space-x-2 my-1 rounded-[2rem] min-w-[300px] md:min-w-[350px] lg:min-w-[400px] overflow-hidden border border-1 border-black/70">
        <div
          className="w-full h-[160px] md:h-[200px] flex-shrink-0 bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${item?.carImages?.[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <TagsContainer
            tags={[
              item?.canGuestDriveYourCar && "Self-drive",
              item?.allowClientOfferPrice && "Negotiable",
            ].filter(Boolean)}
          />
        </div>
        <div className="flex flex-col w-full p-4">
          <div className="flex justify-between items-center font-bold">
            <p>
              {item?.carMakeID?.make} {item?.carMakeID?.mode}{" "}
              {item?.carMakeID?.year}
            </p>

            <div>
              {item.serviceTypes?.length > 0 && (
                <p className="text-[16px]">
                  {formatCurrency(
                    item.serviceTypes?.filter((val: any) => {
                      return val?.name === serviceType;
                    })[0]?.price ?? item?.serviceTypes?.[0]?.price,
                    true
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p>Location: {getSecondToLastWord(item?.address)}</p>
          </div>
          <div className="w-full mt-2">
            <button
              className="w-full border rounded-full bg-[#0E1427] text-white px-4 py-2"
              onClick={() => {
                handleViewCar(item);
              }}
            >
              VIEW
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarCard;
