import React from "react";
import { FaCar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface LoggedOutButtonGrpProps {
  setLoginModalOpen: (open: boolean) => void;
  openDialog: () => void;
}

const LoggedOutButtonGrp: React.FC<LoggedOutButtonGrpProps> = ({
  setLoginModalOpen,
  openDialog,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      <button
        type="button"
        className="text-amber bg-white-700 border-[#0e1427] border rounded-full px-6 py-2"
        onClick={() => setLoginModalOpen(true)}
      >
        Login
      </button>

      <button
        type="button"
        className="text-gray-300 bg-[#0e1427] border rounded-full px-6 py-2 hover:bg-gray-700"
        onClick={openDialog}
      >
        Sign Up
      </button>

      <button
        type="button"
        className="text-gray-300 bg-[#0e1427] border rounded-full px-6 py-2 hover:bg-gray-700 flex gap-2 justify-center items-center"
        onClick={() => {
          navigate("/car/list-vehicle", { state: 1 });
        }}
      >
        <FaCar className="h-6 w-6" />I have a car
      </button>
    </div>
  );
};

export default LoggedOutButtonGrp;
