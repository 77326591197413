import React, { useState, useCallback } from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

interface FieldProps {
  control: any;
  errors: any;
  fieldName: string;
  type: string;
  label: string;
  watch?: any;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  defaultValue?: any;
  multiline?: boolean;
  rows?: number;
  errMessage?: string;
}

const Field: React.FC<FieldProps> = ({
  control,
  errors,
  fieldName,
  type,
  label,
  disabled,
  onChange,
  onBlur,
  defaultValue,
  multiline,
  rows = 6,
  errMessage,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const errorMessage = errMessage || errors?.[fieldName]?.message;

  const handleFocus = useCallback(() => setIsFocused(true), []);

  const handleBlur = useCallback(
    (e: any) => {
      setIsFocused(false);

      if (onBlur) onBlur(e);
    },
    [onBlur]
  );

  const handleChange = useCallback(
    (e: any) => {
      if (onChange) onChange(e);
    },
    [onChange]
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          type={type}
          label={label}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          multiline={multiline}
          minRows={rows}
          value={field.value ?? ""}
          onFocus={handleFocus}
          onBlur={(e) => {
            field.onBlur();
            handleBlur(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            handleChange(e);
          }}
          InputLabelProps={{
            shrink: isFocused || !!field.value || !!defaultValue,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
            width: "100%",
          }}
        />
      )}
    />
  );
};

export default Field;
