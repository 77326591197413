import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listingsProps } from "../../assets/jsons/listed-cars";
import { carList } from "../../assets/jsons/listed-cars";
import { reviewProps } from "../../assets/jsons/reviews";
// import { reviews } from "../../assets/jsons/reviews";
import { useData } from "../../Hooks/useData";
import ProfileTransactionCard from "../../components/card/ProfileTransactionCard";
import ListingsCarCard from "../../components/card/ListingsCarCard";
import CarReviewCard from "../../components/card/CarReviewCard";
import { useDataContext } from "../../providers/DataProvider";
import EmptyData from "../../assets/icons/EmptyData";
import useGetReviews from "../../Hooks/useGetReviews";

interface StatusProps {
  component: listingsProps[] | reviewProps[];
  title: string;
  id: number;
}

const Listings: React.FC = () => {
  const [currentComponent, setCurrentComponent] = useState<
    listingsProps[] | reviewProps[]
  >(carList);

  const navigate = useNavigate();
  const { userData } = useData();
  const [selectedCarId, setSelectedCarId] = useState("");

  const { reviews } = useGetReviews(selectedCarId);

  const { listingDetails, listingData, handleStatusChange, fetchWalletData } =
    useDataContext();

  const status: StatusProps[] = [
    { title: "Listings", component: carList, id: 1 },
    { title: "Reviews", component: reviews, id: 2 },
  ];
  const handleSetComponent = (component: listingsProps[] | reviewProps[]) => {
    if (component) {
      setCurrentComponent(component);
    }
  };

  useEffect(() => {
    fetchWalletData();
  }, [userData]);

  const handleSelectCarId = (val: any) => {
    setSelectedCarId(val);
  };

  return (
    <>
      <div
        className={`${!listingDetails.showDetails ? "block" : "hidden"} w-full`}
      >
        <div className="bg-[#f4f4f4] w-full py-6 md:hidden">
          <div className="grid grid-cols-2 gap-2 items-center justify-center px-2">
            {status.map((status, i) => (
              <button
                key={status.id}
                className={`${
                  status.component === currentComponent
                    ? "bg-[#efcb76] border-none font-normal"
                    : "bg-white-600 font-light"
                }  border border-1 border-[#8B8989] text-[14px] rounded-full py-2 px-10 whitespace-nowrap`}
                onClick={() => handleSetComponent(status.component)}
              >
                {status.title}
              </button>
            ))}
          </div>
        </div>

        <div className="block md:hidden py-10 w-full">
          <div
            className={`${
              currentComponent === carList ? "block" : "hidden"
            } w-full sm:px-16`}
          >
            {listingData?.map((car: any, idx: number) => (
              <ListingsCarCard
                key={idx}
                car={car}
                navigate={navigate}
                selectedCarId={selectedCarId}
                handleStatusChange={handleStatusChange}
                handleSelectCarId={handleSelectCarId}
              />
            ))}
          </div>

          <div
            className={`${
              currentComponent === reviews ? "block" : "hidden"
            } w-full`}
          >
            {reviews?.length > 0 ? (
              reviews?.map((review, idx) => (
                <CarReviewCard key={idx} review={review} />
              ))
            ) : (
              <div className="">
                <EmptyData message="No reviews yet" />
              </div>
            )}
          </div>
        </div>

        <div className="hidden md:flex-col lg:flex-row md:flex justify-center items-center gap-8 max-w-[1350px] px-6">
          <ProfileTransactionCard title="Listings">
            <div className="mt-5">
              {listingData?.map((car: any, idx: number) => (
                <ListingsCarCard
                  key={idx}
                  car={car}
                  navigate={navigate}
                  selectedCarId={selectedCarId}
                  handleStatusChange={handleStatusChange}
                  handleSelectCarId={handleSelectCarId}
                />
              ))}
            </div>
          </ProfileTransactionCard>

          {/* ....REVIEWS...... */}
          <ProfileTransactionCard title="Reviews" variant="green">
            {reviews?.length > 0 ? (
              reviews.map((review, idx) => (
                <CarReviewCard key={idx} review={review} />
              ))
            ) : (
              <div className="h-full flex justify-center items-center py-10">
                <EmptyData message="No reviews yet" />
              </div>
            )}
          </ProfileTransactionCard>
        </div>
      </div>
    </>
  );
};
export default Listings;
