import { Avatar } from "@mui/material";
import LoggedInButtonGrp from "./LoggedInButtonGrp";
import LoggedOutButtonGrp from "./LoggedOutButtonGrp";
import SupportButton from "./SupportButton";
import { stringAvatar } from "../../profile/UserDetails";
import profilePlaceholder from "../../../assets/images/avatar.webp";
import { useData } from "../../../Hooks/useData";

interface LoginSignUpProps {
  state: {
    isLoggedIn: boolean;
    isUserType?: boolean;
  };
  isNotifications: boolean;
  fetchNotifications: () => void;
  navigate: (path: string) => void;
  handleLogout: () => void;
  setLoginDialogOpen: (open: boolean) => void;
  openDialog: () => void;
}

const LoginSignUp: React.FC<LoginSignUpProps> = ({
  state,
  isNotifications,
  fetchNotifications,
  navigate,
  handleLogout,
  setLoginDialogOpen,
  openDialog,
}) => {
  const { userData } = useData();

  return (
    <div className="hidden lg:flex items-center justify-end gap-3 md:order-2 flex-grow min-w-[500px] text-[15px]">
      <SupportButton />

      {state.isLoggedIn && (
        <LoggedInButtonGrp
          isNotifications={isNotifications}
          fetchNotifications={fetchNotifications}
          navigate={navigate}
          state={state}
          handleLogout={handleLogout}
        />
      )}

      {!state.isLoggedIn && (
        <LoggedOutButtonGrp
          setLoginModalOpen={setLoginDialogOpen}
          openDialog={openDialog}
        />
      )}

      {state.isLoggedIn && (
        <div>
          <Avatar
            {...stringAvatar(
              `${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`
            )}
            sx={{
              height: 45,
              width: 45,
            }}
            onClick={() => {
              navigate("/profile/wallet");
            }}
            src={userData?.pictureUrl ?? profilePlaceholder}
            alt="Profile image"
            className="border border-1 border-black cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default LoginSignUp;
