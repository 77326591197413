import React from "react";
import { formatCurrency } from "../../utils/stringFunctions";
import { getSecondToLastWord } from "../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import { Deal } from "../topdeal/TodaysTopDeals";
import { useDataContext } from "../../providers/DataProvider";

const WebDealCard = ({ deal }: { deal: Deal }) => {
  const { handleFromWhereSelected, updateFromWhereInputValue } =
    useDataContext();

  const carAddress: any = {
    address: deal.address,
    lng: deal?.location.coordinates[0],
    lat: deal?.location.coordinates[1],
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-between items-center mt-5 mb-10 rounded-3xl w-full outline outline-[0.5px] outline-[#BFC4CD]/50">
      <div className="w-full flex-1">
        <img
          src={deal.carImages[0]}
          alt=""
          className="rounded-t-3xl w-full object-cover h-[160px] lg:h-[200px]"
        />
      </div>
      <div className=" flex flex-col w-full py-6 px-4">
        <div className="flex justify-between items-center font-medium text-lg mb-1">
          <div className=" flex-1">
            <p className="text-[16px]">
              {deal?.carMakeID?.make} {deal?.carMakeID?.year}{" "}
              {deal?.carMakeID?.mode}
            </p>
          </div>

          <div>
            {deal.serviceTypes.length > 0 && (
              <p className="text-[16px]">
                {formatCurrency(deal?.serviceTypes[0]?.price)}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center font-light">
          <div>
            {/* <p>{deal.carSeries}</p> */}
            {/* <p className="text-[15px]">Location: {deal?.address}</p> */}
            <p className="text-[15px] ">
              Location:{" "}
              <span className="text-[15px] font-medium">
                {" "}
                {getSecondToLastWord(deal?.address)}{" "}
              </span>
            </p>
          </div>

          <div>
            <button
              className="border rounded-full bg-black text-white px-5 py-2"
              onClick={() => {
                handleFromWhereSelected(carAddress);
                updateFromWhereInputValue(carAddress.address);
                navigate(`/car/details/${deal.carCode}`);
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDealCard;
