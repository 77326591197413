import { FC, useEffect, useState, useCallback } from "react";
import ListingFormHeader from "../../components/listingProcess.tsx/ListingFormHeader";
import Field from "../../components/inputs/Field";
import FieldAutocomplete from "../../components/inputs/FieldAutocomplete";
import axios from "axios";
import _ from "lodash";

interface handleClickProps {
  errors: any;
  control: any;
  watch: any;
  setValue: any;
}

type BankItem = {
  id: string;
  name: string;
  code: string;
};

const SECRET_TOKEN = process.env.REACT_APP_PAYSTACK_TEST_SECRET_KEY as string;
const STANDARD_ACCT_NUMBER = 10;

const BankDetails: FC<handleClickProps> = ({
  errors,
  control,
  watch,
  setValue,
}) => {
  const [bankList, setBankList] = useState<BankItem[]>([]);

  const accountNumber = watch("accountNum", "");
  const bankName = watch("bankName", "");
  const bankCode = bankList.find((bank) => bank.name === bankName)?.code;

  useEffect(() => {
    const getBankList = async () => {
      try {
        const { data } = await axios.get(
          "https://api.paystack.co/bank?country=nigeria&currency=NGN"
        );

        if (data.status) {
          setBankList(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getBankList();
  }, []);

  const verifyAccount = useCallback(
    _.debounce(async () => {
      try {
        if (accountNumber.length === STANDARD_ACCT_NUMBER && bankCode) {
          const { data } = await axios.get(
            `https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`,
            {
              headers: {
                Authorization: `Bearer ${SECRET_TOKEN}`,
              },
            }
          );

          if (data.status) {
            setValue("accountName", data.data?.account_name);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 500),
    [accountNumber, bankCode]
  );

  useEffect(() => {
    verifyAccount();
  }, [accountNumber, verifyAccount]);

  return (
    <div className="w-full flex flex-col items-center gap-8 lg:max-w-[1140px] max-w-[1140px] lg:px-0 px-6">
      <ListingFormHeader
        header="Bank details"
        subHeader="Please note all details provided will be used for remittance of monies
        you generate from your asset."
      />

      <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-4 opacity-70 justify-items-center justify-center">
        <FieldAutocomplete
          control={control}
          errors={errors}
          multiple={false}
          options={bankList.map((bank) => bank.name)}
          fieldName="bankName"
          label="Bank Name"
          placeholder="Search Bank"
        />

        <Field
          type="text"
          label="Account Number"
          fieldName="accountNum"
          errors={errors}
          control={control}
        />

        <Field
          type="text"
          label="Account Name"
          fieldName="accountName"
          errors={errors}
          control={control}
          disabled
        />

        <Field
          type="text"
          label="Sort code"
          fieldName="sortCode"
          errors={errors}
          control={control}
        />
      </div>

      <div className="w-full opacity-70 font-light">
        Please review our{" "}
        <span className="font-medium">terms and conditions </span>before
        proceeding. Also note Hosts are charged 00% of every booking.
      </div>
    </div>
  );
};

export default BankDetails;
