import React from "react";
import { formatCurrency } from "../../utils/stringFunctions";

type Value = {
  transActionTypeId: { name: string };
  createdAt: string;
  amount: number;
};

interface TransactionEntryProps {
  value: Value;
}

const TransactionEntry: React.FC<TransactionEntryProps> = ({ value }) => {
  const convertDateFormat = (originalDateString: string) => {
    const originalDate = new Date(originalDateString);

    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();

    let hours = originalDate.getHours();
    const minutes = originalDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const time =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ampm;

    return `${day}/${month}/${year} ${time}`;
  };

  return (
    <div className="md:px-8">
      <div className="flex items-center justify-between gap-10 py-6 w-full text-[15px]">
        <div className="flex items-center justify-between gap-10 w-full">
          <div className="text-[#323232] font-light ">
            <p className="capitalize">
              {value?.transActionTypeId?.name?.toLowerCase()}
            </p>
            <p>{convertDateFormat(value?.createdAt)}</p>
          </div>

          <div>{formatCurrency(value?.amount)}</div>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default TransactionEntry;
