import React, { ChangeEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";

interface handleClickProps {
  handleNextClick?: () => void;
  setForms: (inputFields: any) => void;
}

const BankDetailsForTruck: FC<handleClickProps> = ({
  handleNextClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    accountName: "",
    accountNum: "",
    bank: "",
    sortCode: "",
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  return (
    <div className="mx-auto flex flex-col gap-8 w-[1140px]">
      <div className="flex flex-col gap-2">
        <h1 className=" font-semibold">Bank detailss</h1>
        <p className=" font-light">
          Please note all details provided will be used for remittance of monies
          you generate from your asset.
        </p>
      </div>

      <div className=" flex gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Account Name"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "accountName")}
          />
          <input
            type="text"
            placeholder="Account Number"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "accountNum")}
          />
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Bank"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "bank")}
          />
          <input
            type="text"
            placeholder="Sort code"
            className=" border-2 mb-2 rounded-lg p-2"
            onChange={(e) => handleOnChange(e, "sortCode")}
          />
        </div>
      </div>

      <div>
        <p className="font-light mb-2">
          Please review our{" "}
          <span className="font-semibold">terms and conditions</span> before
          proceeding. Also note Hosts are charged 00% of every booking.
        </p>
        <div>
          <input
            type="checkbox"
            id="truck-terms"
            name="truck-condition"
            value="upfront"
            title="terms"
          />
          <label htmlFor="truck-condition" className="font-light ml-2">
            {" "}
            I agree to a <span className="font-semibold">50%</span> upfront
            payment at the point of loading
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="truck-condition"
            name="truck-condition"
            value="upfront"
          />
          <label htmlFor="truck-condition" className="font-light ml-2">
            {" "}
            I agree to pay the driver and other personnel used in carrying out
            trips.{" "}
          </label>
        </div>
      </div>
      <div>
        <button
          className=" bg-black text-white px-5 py-1 rounded-full"
          onClick={() => {
            handleNextClick && handleNextClick();
            handleAppendForm();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BankDetailsForTruck;
