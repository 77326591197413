import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const BookingsUserToggle = ({
  value,
  setValue,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setValue(value);
    sessionStorage.setItem("userStatus",value )
  };

  

  return (
    <FormControl className="w-full flex justify-center items-center lg:items-start">
      <div className="px-6">
        <RadioGroup
          name="bookings-toggle"
          row
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="guest"
            control={<Radio />}
            label="Guest Bookings"
          />

          <FormControlLabel
            value="you"
            control={<Radio />}
            label="Your Bookings"
          />
        </RadioGroup>
      </div>
    </FormControl>
  );
};

export default BookingsUserToggle;
