import { Link } from "react-router-dom";
import logo from "../../../assets/images/logoresized.jpg";

const Logo = () => {
  return (
    <Link className="min-w-[150px] w-[150px]" to={"/"}>
      <img src={logo} alt="DMM logo" className="object-cover object-center" />
    </Link>
  );
};

export default Logo;
