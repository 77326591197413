import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TransactionProps,
  transactionWallet,
} from "../../assets/jsons/transaction-wallet";
import { walletHistory } from "../../assets/jsons/wallet-history";
import { useData } from "../../Hooks/useData";
import ProfileTransactionCard from "../../components/card/ProfileTransactionCard";
import TransactionEntry from "../../components/profile/TransactionEntry";

interface StatusProps {
  component: TransactionProps[];
  title: string;
  id: number;
}

const Transactions = () => {
  const [external, setExternal] = useState([]);
  const [internal, setInternal] = useState([]);
  const { state, dispatch } = useAuth();
  const { userData } = useData();
  const [city, setCity] = useState<any[]>([]);
  const location = useLocation();
  const { carResp: bookingData, rentDetails } = location?.state ?? {};

  useEffect(() => {
    const getState = async () => {
      try {
        // Fetch car makes
        const { data } = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/general/countries`
        );

        if (data) {
          const response = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/general/countries/${data.data[158].code}`
          );
          // console.log("response.data.data", response.data.data);

          // setStates(response.data.data);

          if (response.data.data) {
            const response = await axios.get(
              `https://dmm-backend-f562.onrender.com/api/general/cities/Lagos/LA`
            );
            // console.log("response.data.data", response.data.data);

            if (response.data.data) {
              setCity(response.data.data);
            }
            // const response = await axios.get(
            //   `https://dmm-backend-f562.onrender.com/api/general/cities/${selectState}/${selectStateCode}`
            // );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getState();
  }, []);

  useEffect(() => {
    const getWallet = async () => {
      try {
        if (userData?.userId) {
          const internalResponse = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/wallet/internalTransctions/${userData?.userId}/660521261fef07f66ba0e2df`
          );
          const externalResponse = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/wallet/externalTransctions/${userData?.userId}/660520708eb0dce655438c8d`
          );
          setInternal(internalResponse?.data?.data?.reverse());
          setExternal(externalResponse?.data?.data?.reverse());
        }
      } catch (error) {
        console.log("error fetching  data --> ", error);
      }
    };

    getWallet();
  }, [userData]);

  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState<
    "internal" | "external"
  >("internal");
  const [currentComponent, setCurrentComponent] = useState<
    TransactionProps[] | null
  >(transactionWallet);

  const status: StatusProps[] = [
    { title: "Transactions", component: transactionWallet, id: 1 },
    { title: "Deposit", component: walletHistory, id: 2 },
  ];
  const handleSetComponent = (component: TransactionProps[]) => {
    setCurrentComponent(component);
    console.log(currentComponent);
  };
  const handleInternalClick = () => {
    setSelectedComponent("internal");
  };
  const handleExternalClick = () => {
    setSelectedComponent("external");
  };

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="w-full md:hidden">
        <div className="bg-[#f4f4f4] w-full py-6">
          <div className="grid grid-cols-2 gap-2 items-center justify-center px-2">
            {status.map((status, i) => (
              <button
                key={status.id}
                className={`${
                  status.component === currentComponent
                    ? "bg-[#efcb76] border-none font-normal"
                    : "bg-white-600 font-light"
                }  border border-1 border-[#8B8989] text-[14px] rounded-full py-2 px-10 whitespace-nowrap`}
                onClick={() => handleSetComponent(status.component)}
              >
                {status.title}
              </button>
            ))}
          </div>
        </div>

        <div className="rounded-b-3xl px-0 md:px-6 overflow-y-auto">
          {currentComponent === transactionWallet &&
            internal?.map((val: any, idx) => (
              <TransactionEntry value={val} key={idx} />
            ))}
        </div>

        <div className="rounded-b-3xl px-0 md:px-6 overflow-y-auto">
          {currentComponent === walletHistory &&
            external.map((val: any, idx) => (
              <TransactionEntry value={val} key={idx} />
            ))}
        </div>

        <div className="flex lg:hidden w-full flex-col px-8 justify-center ">
          {/* {currentComponent?.map((component) => (
            <TransactionItem component={component} />
          ))} */}
        </div>
      </div>

      <div className="hidden flex-col lg:flex-row md:flex gap-8 justify-center items-center w-full">
        {/* .....LEFT.... */}
        <ProfileTransactionCard title="Transaction">
          {internal?.map((val: any, idx) => (
            <TransactionEntry value={val} key={idx} />
          ))}
        </ProfileTransactionCard>

        {/* .....RIGHT.... */}
        <ProfileTransactionCard variant="green" title="Transaction">
          {external?.map((val: any, idx) => (
            <TransactionEntry value={val} key={idx} />
          ))}
        </ProfileTransactionCard>
      </div>
    </div>
  );
};

export default Transactions;
