import Audi from "../images/topdeals/AudiA6.jpg"
import Toyota from "../images/topdeals/AudiA6.jpg"

export interface CardProps {
  image: string
  price: string
  name: string
  id: number
  navDestination?: string
  smallButtonName: string
  bigButtonName: string
  route: string
  status: string
}

export const activeBookings: CardProps[] = [
  {
    name: "Audi A6",
    image: Audi,
    price: "N00,00",
    id: 0,
    smallButtonName: "ongoing",
    bigButtonName: "",
    route: "",
    status: "active",
  },
  {
    name: "Audi A6",
    image: Audi,
    price: "N00,00",
    id: 1,
    smallButtonName: "ongoing",
    bigButtonName: "",
    route: "",
    status: "active",
  },
]
