import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface CurrencyInputProps {
  control: any;
  name: string;
  label: string;
  onChange?: any;
  errors: any;
  errMessage?: string;
}

const FieldCurrency: React.FC<CurrencyInputProps> = ({
  control,
  name,
  label,
  onChange,
  errors,
  errMessage,
}) => {
  const errorMessage = errMessage || errors?.[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <NumericFormat
          getInputRef={field.ref}
          name={field.name}
          onBlur={field.onBlur}
          onChange={onChange}
          value={field.value}
          customInput={TextField}
          label={label}
          fullWidth
          thousandSeparator={true}
          prefix={"₦"}
          error={!!errorMessage}
          helperText={errorMessage}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
          onValueChange={(values: { value: any }) => {
            field.onChange(values.value);
          }}
        />
      )}
    />
  );
};

export default FieldCurrency;
