import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import { FaAngleRight } from "react-icons/fa6";
import { TbCircleDotted } from "react-icons/tb";
import Modal from "../../components/modal/Modal";
import { LuCalendarDays } from "react-icons/lu";
import { CiClock2 } from "react-icons/ci";
import { BiBriefcaseAlt } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa";
import { FaRegDotCircle } from "react-icons/fa";
import { FiCircle } from "react-icons/fi";
import { useData } from "../../Hooks/useData";

const DriverAssigned = () => {
  const navigate = useNavigate();
  const [partialInfoModal, setPartialInfoModal] = useState(false);
  const [pleaseConfirmModal, setPleaseConfirmModal] = useState(false);
  const [partialAvailableConfirmModal, setPartialAvailableConfirmModal] =
    useState(false);
  const [declineBookingModal, setDeclineBookingModal] = useState(false);
  const [declineReasonModal, setDeclineReasonModal] = useState(false);
  const [confirmBookingModal, setConfirmBookingModal] = useState(false);
  const [bookingConfirmedModal, setBookingConfirmedModal] = useState(false);
  const { userData } = useData();
  const role = userData?.role?.name;

  const HandlePartialinfo = () => {
    setPartialInfoModal(true);
  };
  const HandlePleaseConfirmModal = () => {
    setPleaseConfirmModal(true);
    setPartialInfoModal(false);
  };

  const HandlePartialAvailableConfirmModal = () => {
    setPartialAvailableConfirmModal(true);
    setPleaseConfirmModal(false);
  };

  const HandleDeclineBookingModal = () => {
    setDeclineBookingModal(true);
  };

  const HandleDeclineReason = () => {
    setDeclineReasonModal(true);
    setDeclineBookingModal(false);
  };

  const HandleConfirmBooking = () => {
    setConfirmBookingModal(true);
  };

  const HandleBookingConfirmed = () => {
    setBookingConfirmedModal(true);
    setConfirmBookingModal(false);
  };

  return (
    <div>
      <div className=" bg-gray-200 flex p-4 justify-evenly w-full">
        <p>Toyota Camry</p>
        <div>
          <p>5 Ojodu Abiodun Street, off Isheri Road, Ojodu Berger</p>
          <p>Mon, Aug 24, 2020 | 07:00 am</p>
        </div>
        <FaAngleRight />
        <div>
          <p>256 Broad Street, Lagos Island</p>
          <p>Tue, Aug 25, 2020 | 07:00 pm</p>
        </div>
      </div>

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 mb-32 gap-20 w-[85%]">
        <div className=" flex flex-col">
          <div className="flex items-center p-3 gap-2">
            <TbCircleDotted className="w-6 h-6" />
            <h2 className="font-semibold">Driver assigned!</h2>
          </div>
          <p className="text-sm mb-4 p-3">
            Hi James Brown, the booking for{" "}
            <span className="font-semibold">
              Toyota Camry assigned a driver
            </span>
            has been .<br></br>
            Please check below for details.
          </p>
          <hr />
          {/* details */}
          <div className=" flex flex-col justify-between">
            <div className=" flex  p-3 hover:border-orange-300 cursor-pointer space-x-16 mb-2">
              <div className="text-sm">
                <p className="">Booking date:</p>
                <p className="font-semibold">24/08/2020 - 29/08/2020</p>
                <p className="mt-2">Driver:</p>
                <p className="font-semibold">Chinedu Okagbere</p>
              </div>
              <div className="text-sm">
                <p className="">Service type:</p>
                <p className="font-semibold">Full day hire</p>
                <p className="mt-2">status:</p>
                <p className="font-semibold">Pending</p>
              </div>
              <div className="text-sm">
                <p>Amount offered:</p>
                <p className="font-semibold">N00,000</p>
              </div>
            </div>
            <div className="flex justify-between items-center space-x-">
              <button
                type="button"
                className="text-white text-sm bg-indigo-950  border rounded-full w-[30%] px-2 py-1 hover:shadow-md mb-5"
                onClick={HandleConfirmBooking}
              >
                Confirm availability
              </button>
              <button
                type="button"
                className="text-black text-sm border-indigo-950 border rounded-full w-[%] px-2 py-1 hover:shadow-md mb-5"
                onClick={HandlePartialinfo}
              >
                Confirm part availability
              </button>
              <button
                className="text-red-600 -mt-4"
                onClick={HandleDeclineBookingModal}
              >
                Decline booking
              </button>
            </div>

            <hr />

            {role === "Host" && (
              <p className="text-sm mt-3">
                Please note that you will have to assign a driver to complete
                booking confirmstion.
              </p>
            )}
          </div>
        </div>

        <div className=" flex flex-col gap-2 rounded-md bg-gray-200 w-[25%] h-[60%] p-2">
          <div className="  p-2 border-b-2 border-b-white">
            <p className="  font-semibold">Order Summary</p>
          </div>
          <div className="  p-2 border-b-2 border-b-white">
            <p className="  font-semibold">User Details</p>
            <p className="text-xs">John Smith</p>
            <p className="text-xs">johnsmith@gmail.com</p>
            <p className="text-xs">0700 000 0000</p>
          </div>
          <div className="p-2">
            <p className="  font-semibold">Transaction Details</p>
            <div className="text-xs flex justify-between">
              <p>Trip fare per day</p>
              <p>N 0000000</p>
            </div>
            <div className="text-xs flex justify-between">
              <p>Trip fare per day</p>
              <p>N 0000000</p>
            </div>
            <div className="text-xs flex justify-between">
              <p>Extra request</p>
              <p>N 0000000</p>
            </div>
            <div className="flex justify-between font-medium mt-4">
              <p className="text-sm">Subtotal</p>
              <p className="text-xs">N 0000000</p>
            </div>
            <div className="text-xs flex justify-between gap-4">
              <p>WAT(7.5%)</p>
              <p>N 0000000</p>
            </div>
            <div className="  flex justify-between font-medium mt-4">
              <p>Total</p>
              <p>N 0000000</p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={confirmBookingModal}
        isCloseButton={true}
        onClose={() => {
          setConfirmBookingModal(false);
        }}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm!</h1>
          <p>Are you sure you want to confirm this booking?</p>
          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleBookingConfirmed}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setPleaseConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={bookingConfirmedModal}
        isCloseButton={true}
        onClose={() => {
          setBookingConfirmedModal(false);
        }}
      >
        <div>
          <h1 className="text-lg font-semibold mb-2">Booking confirmed!</h1>
          <p className="mt-2 mb-8">Booking has been confirmed successfully.</p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md ml-4"
            onClick={() => {
              setBookingConfirmedModal(false);
              navigate("/profile/bookings");
            }}
          >
            Go home
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={partialInfoModal}
        isCloseButton={true}
        onClose={() => {
          setPartialInfoModal(false);
        }}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold">Partial availability info</h1>

          <div className="flex flex-col justify-center">
            <div className="flex gap-5">
              <div className="flex flex-col items-center mt-9">
                <FaRegDotCircle />
                <span className="border-black border-dashed border-l w-[10px] h-[120px] ml-2"></span>
                <FaRegCircle />
              </div>
              <div>
                <div className="text-sm mt-4 border rounded-xl p-3">
                  <p>From</p>
                  <div className="flex justify-between space-x-16">
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <LuCalendarDays />
                        <p>Aug 24, 2020</p>
                      </div>
                      <hr />
                    </div>
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <CiClock2 />
                        <p>07:00 am</p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="text-sm mt-10 border rounded-xl p-3">
                  <p>To</p>
                  <div className="flex justify-between">
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <LuCalendarDays />
                        <p>Aug 25, 2020</p>
                      </div>
                      <hr />
                    </div>
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <CiClock2 />
                        <p>07:00 pm</p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md mt-10"
              onClick={HandlePleaseConfirmModal}
            >
              Reschedule
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={pleaseConfirmModal}
        isCloseButton={true}
        onClose={() => {
          setPleaseConfirmModal(false);
        }}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm!</h1>
          <p>
            Are you sure you want to make this booking<br></br> partially
            available?
          </p>
          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandlePartialAvailableConfirmModal}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setPleaseConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={partialAvailableConfirmModal}
        isCloseButton={true}
        onClose={() => {
          setPartialAvailableConfirmModal(false);
        }}
      >
        <div>
          <h1 className="text-lg font-semibold mb-2">
            Partial availability confirmed!
          </h1>
          <p className="mt-2 mb-8">
            Booking has been made partially available.
          </p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md ml-4"
            onClick={() => {
              setPartialAvailableConfirmModal(false);
              navigate("/profile/bookings");
            }}
          >
            Go home
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DriverAssigned;
