import React from "react";
import Skeleton from "@mui/material/Skeleton";

const WebDealSkeletonCard: React.FC = () => {
  return (
    <div className="flex flex-col justify-between items-center mt-5 mb-10 rounded-3xl w-full outline outline-[0.5px] outline-[#BFC4CD]/50">
      <div className="w-full flex-1">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          className="rounded-t-3xl"
        />
      </div>
      <div className="flex flex-col w-full py-6 px-4">
        <div className="flex justify-between items-center font-medium text-lg mb-1">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={80} />
        </div>

        <div className="flex justify-between items-center font-thin">
          <div>
            <Skeleton variant="text" width={100} />
            {/* <Skeleton variant="text" width={30} /> */}
          </div>

          <div>
            <Skeleton variant="rectangular" width={80} height={42} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDealSkeletonCard;
