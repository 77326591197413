import React from "react";
import DmmCarousel from "../carousel/DmmCarousel";
import Rating from "../rating/Rating";
import { formatCurrency } from "../../utils/stringFunctions";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import CarDetailsSkeleton from "../skeletons/CarDetailsSkeleton";
import { serviceTypesWithQuoteRequired } from "../../utils/constants";

interface DetailsHeaderProps {
  data: any;
  price: any;
  serviceType: any;
  showBookButton?: boolean;
  onClick?: any;
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  data,
  price,
  serviceType,
  showBookButton = true,
  onClick = () => {
    navigate(-1);
  },
}) => {
  const navigate = useNavigate();
  const {
    carImages,
    carMakeID,
    hostID,
    dmmRating,
    numberOfPassanger,
    numberOfDoors,
  } = data || {};

  return (
    <>
      <div className="hidden lg:flex  h-[350px] w-full justify-center items-center border-b border-b-1 border-[#C4CFDF] ">
        <DmmCarousel images={carImages} />

        {!carMakeID?.make ? (
          <CarDetailsSkeleton />
        ) : (
          <div className={`${!!price ? "w-[40%]" : "w-full"} p-10`}>
            <div className="flex flex-col justify-start items-start mb-4">
              <span className="font-medium text-xl capitalize">
                {carMakeID?.make +
                  " " +
                  carMakeID?.mode +
                  " " +
                  carMakeID?.year}
              </span>

              <Rating rating="4.94" trips={179} value={5} />
            </div>

            <div className="flex text-base mt-2 mb-2 space-x-3">
              Hosted by{" "}
              <p className="capitalize ml-1 font-medium">
                {`${hostID?.userId?.firstName} ${hostID?.userId?.lastName}`}
              </p>
              {/* <p className="bg-[#0e1427] text-white px-2 rounded-md capitalize">{`${dmmRating}%`}</p> */}
            </div>

            {numberOfPassanger ? (
              <p className="text-xs capitalize">
                {numberOfPassanger} passenger
              </p>
            ) : null}

            <p className=" hidden sm:flex text-[13px] capitalize">
              {numberOfDoors} door
            </p>
          </div>
        )}

        {!!price && (
          <div className="bg-[#F2F3F5] w-[20%] h-full rounded-tr-3xl flex flex-col justify-center items-center">
            {!serviceTypesWithQuoteRequired.includes(serviceType) && (
              <>
                <p className="font-medium text-[20px]">
                  {formatCurrency(price)}
                </p>
                <p className="text-[13px] mb-2">
                  per{" "}
                  <span className="lowercase">
                    {" "}
                    {serviceType ?? "service"}{" "}
                  </span>
                </p>
              </>
            )}

            {showBookButton && (
              <Button
                className="text-black text-base font-semibold rounded-full bg-[#EFCB76] md:px-10 md:py-3 mb-1"
                onClick={onClick}
              >
                CLOSE
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="block lg:hidden">
        <div className="w-full  h-[350px] rounded-t-3xl">
          <DmmCarousel
            images={carImages}
            className="h-full w-full object-cover rounded-t-3xl"
            borderRadius="24px 24px 0px 0px"
          />
        </div>

        <div className="h-[50px] bg-[#F4F4F4] mb-4 text-[15px] flex justify-between px-4 items-center">
          <span className="font-light capitalize">
            {carMakeID?.make + " " + carMakeID?.mode}
          </span>

          <span className="font-semibold">{formatCurrency(price)}</span>
        </div>
      </div>
    </>
  );
};

export default DetailsHeader;
