export interface listingsProps {
  carImage: string;
  carType: string;
  carPrice: string;
  carStatus: boolean;
  negotiable: boolean;
  displayStatus: string;
}
export const carList: listingsProps[] = [
  {
    carImage: "",
    carType: "Audi A6",
    carPrice: "00000",
    carStatus: true,
    negotiable: false,
    displayStatus: "",
  },
  {
    carImage: "",
    carType: "Audi A6",
    carPrice: "00000",
    carStatus: false,
    negotiable: true,
    displayStatus: "Pending Approval",
  },
  {
    carImage: "",
    carType: "Audi A6",
    carPrice: "00000",
    carStatus: false,
    negotiable: true,
    displayStatus: "Suspended",
  },
  {
    carImage: "",
    carType: "Audi A6",
    carPrice: "00000",
    carStatus: true,
    negotiable: false,
    displayStatus: "Declined",
  },
];
