import React, { ChangeEvent, FC, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface handleNextClickProps {
  handleNextClick: () => void;
  setForms: (inputFields: any) => void;
}

const TruckDocument: FC<handleNextClickProps> = ({
  handleNextClick,
  setForms,
}) => {
  const [inputFields, setInputFields] = useState({
    carSeries: "",
    registrationNumber: "",
    doors: "",
    vehicleValue: "",
    passengers: "",
    address: "",
    year: "",
  });
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (
    fieldName: any,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      // setSelectedFile(file);
      handleUpload(fieldName, file);
    }
  };

  const handleUpload = async (fieldName: any, file: any) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append("image", selectedFile);

      // Send a POST request to the ImgBB API to upload the image
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_KEY}`,
        formData
      );

      // Handle the response from the API
      const { data } = response.data;
      setImageUrl(data.url);
      setInputFields({
        ...inputFields,
        [fieldName]: data.url,
      });
      console.log("Image url", data.url);

      // Save the URL details to your database
      // You can send another request to your backend server to save the URL details
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };

  return (
    <div className="mx-auto flex flex-col gap-8 w-[1140px]">
      <div className="flex flex-col gap-2 ">
        <h1 className="font-semibold">Truck documents</h1>
        <p className="font-light">Please provide clear images/pdfs.</p>
      </div>

      <div className=" flex gap-8 w-full ">
        <div className=" flex flex-col w-1/2 gap-4">
          {/* <label htmlFor="car_make"> Car make</label> */}
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Share vehicle license{" "}
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="Vehicle license"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Vehicle license", e)}
            />
          </div>
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Share road worthiness
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="goods insurance"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Road worthiness", e)}
            />
          </div>
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Share road worthiness
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="road worthiness"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Road worthiness", e)}
            />
          </div>
        </div>

        <div className=" flex flex-col w-1/2 gap-4">
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Share vehicle insurance
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="vehicle insurance"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Vehicle insurance", e)}
            />
          </div>
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            <div>
              Share proof of ownership
              <span className=" text-black">(image, pdf)</span>{" "}
            </div>
            <input
              type="file"
              placeholder="proof of ownership"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("Proof of ownership", e)}
            />
          </div>
        </div>
      </div>

      {/* <input
      type="text"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
      required
      placeholder="Email or phone number"
    /> */}
      <div>
        <button
          className=" bg-black text-white px-5 py-1 rounded-full"
          onClick={() => {
            handleNextClick && handleNextClick();
            handleAppendForm();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TruckDocument;
