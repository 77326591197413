import React from "react";
import { GrMenu } from "react-icons/gr";

interface MenuIconButtonProps {
  handleOpenSideModal: () => void;
}

const MenuIconButton: React.FC<MenuIconButtonProps> = ({
  handleOpenSideModal,
}) => {
  return (
    <button
      data-collapse-toggle="navbar-sticky"
      type="button"
      title="menu"
      className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg lg:hidden hover:bg-gray-100 "
      aria-controls="navbar-sticky"
      aria-expanded="false"
      onClick={handleOpenSideModal}
    >
      <GrMenu className="w-[60px] h-[60px]" />
    </button>
  );
};

export default MenuIconButton;
