import React from "react";

interface FieldDateProps {
  register: any;
  errors: any;
  fieldName: string;
  label: string;
}

const FieldDate: React.FC<FieldDateProps> = ({
  register,
  errors,
  fieldName,
  label,
}) => {
  const isError = !!errors?.[fieldName]?.message;

  return (
    <div className="w-full">
      <div
        className={`w-full flex flex-col justify-center border border-1 ${
          isError ? "border-red-700" : "border-[#BABABA]"
        } min-h-[55px] rounded-[8px] px-3`}
      >
        <label
          className={`text-[11px] font-light ${isError ? "text-red-700" : ""}`}
        >
          {label}
        </label>

        <input
          type="date"
          className="text-sm lg:text-[15px] px-2 w-full flex-1 mb-1 rounded-[4px]"
          required
          {...register(fieldName)}
        />
      </div>

      {isError && (
        <p className="text-[12px] mt-1 text-red-700 px-3">
          {errors?.[fieldName]?.message}
        </p>
      )}
    </div>
  );
};

export default FieldDate;
