import React from "react";
import { formatTimestamp } from "../../utils/utilsFunctions";
import { FaAngleRight } from "react-icons/fa6";

// Define the shape of the data prop
interface CarMake {
  make: string;
  mode: string;
  year: number;
}

interface CarId {
  carMakeID: CarMake;
}

interface BookingData {
  bookingStartDate: string;
  bookingEndDate: string;
  carId: CarId;
  startLocation: string;
  endLocation: string;
}

interface SummaryProps {
  data: BookingData;
}

const Summary: React.FC<SummaryProps> = ({ data }) => {
  const { date: startDate, time: startTime } = formatTimestamp(
    data?.bookingStartDate
  );
  const { date: endDate, time: endTime } = formatTimestamp(
    data?.bookingEndDate
  );

  return (
    <>
      <div className="w-full bg-[#F1F1F2] hidden justify-center h-[100px] md:flex">
        <div className="max-w-[1480px] flex justify-start w-full items-center gap-10 font-light text-[15px] px-10">
          <p className="border-r border-r-1 border-black pr-20 lg:w-[300px]">
            {data?.carId?.carMakeID?.make} {data?.carId?.carMakeID?.mode}{" "}
            {data?.carId?.carMakeID?.year}
          </p>

          <div className="lg:w-[350px] px-10">
            <p>{data?.startLocation}</p>
            <p>
              {startDate} <span className="mx-3">|</span>{" "}
              <span>{startTime}</span>
            </p>
          </div>

          <FaAngleRight />

          <div className="lg:w-[350px] px-10">
            <p>{data?.endLocation}</p>
            <p>
              {endDate} <span className="mx-3">|</span> <span>{endTime}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full bg-[#F1F1F2] justify-center items-center h-[100px] md:hidden">
        <div className="flex justify-start w-full items-center gap-10 font-light text-[14px] px-6">
          <div>
            <p>{data?.startLocation}</p>

            <p>
              {startDate}
              <span className="ml-3">{startTime}</span>
            </p>
          </div>

          <div>
            <p>{data?.endLocation}</p>

            <p>
              {endDate} <span className="ml-3">{endTime}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
