import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import axios from "axios";

const baseUrl = "https://dmm-backend-f562.onrender.com";

const endPoint = "/api/account/subscribe";

const Newsletter = () => {
  const [newsLetter, setNewsLetter] = useState<any>();
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmail("");
  };

  const fetchNewsLetter = async () => {
    const { data } = await axios.post(baseUrl + endPoint, { email: email });

    setNewsLetter(data.message);
    if (data) {
      setEmail("");
    }
  };

  return (
    <div className="bg-[#f4f4f4] lg:rounded-3xl p-5 borde max-w-[1370px] mx-auto w-full">
      <div className="p-4 ">
        <div className="lg:flex lg:flex-row flex flex-col mb-6 justify-between gap-4 items-center w-full">
          <div className="w-full">
            <h2 className="text-xl lg:text-2xl">
              Be the first to get the latest offers
            </h2>
            <p className="font-light">
              Enter your email and we'll send them your way
            </p>
          </div>

          <form
            className="flex bg-white items-center justify-between rounded-full p-2 border border-[#0E1427] lg:border-[#CDD5E2] w-full"
            onSubmit={handleSubmit}
          >
            <input
              title="text"
              type="text"
              value={email}
              placeholder="Enter email address"
              className="rounded-full lg:py-4 w-[200px] mr-2 lg:w-[500px] pl-4 flex-1"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Button
              className="hidden md:flex rounded-full bg-[#0e1427] px-8 py-2 lg:py-4 text-center text-base font-light normal-case"
              type="submit"
              value={newsLetter}
              onClick={fetchNewsLetter}
            >
              Subscribe
            </Button>
          </form>

          <Button
            className="max-md:flex hidden rounded-full bg-[#0e1427] w-full py-2 lg:py-4 text-center text-base font-light normal-case justify-center"
            type="submit"
            value={newsLetter}
            onClick={fetchNewsLetter}
          >
            Subscribe
          </Button>
        </div>

        <hr className="border-t-1 border-black" />

        <p className="hidden lg:block font-light mt-4">
          Your privacy is important to us, so we'll never spam you or share your
          info with third parties. Take a look at our{" "}
          <span className="font-medium">privacy policy.</span>
          <br></br>You can unsubscribe at any time.
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
