import React from "react";
import Skeleton from "@mui/material/Skeleton";

const MobileDealSkeletonCard: React.FC = () => {
  return (
    <div className="flex flex-col justify-between items-center mt-5 mb-10 rounded-3xl w-full outline outline-[0.5px] outline-black/50">
      <div className="w-full flex-1 md:hidden">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={90}
          className="rounded-t-3xl"
        />
      </div>

      <div className="w-full flex-1 hidden md:flex">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={140}
          className="rounded-t-3xl"
        />
      </div>

      <div className="flex flex-col w-full pt-2 px-2">
        <div className="flex justify-between items-center text-[14px] md:text-[18px]">
          <Skeleton variant="text" width={100} />
          <div>
            <Skeleton variant="text" width={80} />
          </div>
        </div>

        <div className="flex justify-between items-center text-[12px] md:text-[15px]">
          <div>
            <Skeleton variant="text" width={100} />
            {/* <Skeleton variant="text" width={60} /> */}
          </div>
        </div>
      </div>

      <div className="min-w-full p-2">
        <Skeleton variant="rectangular" width="100%" height={40} />
      </div>
    </div>
  );
};

export default MobileDealSkeletonCard;
