import React from "react";
import { Deal } from "../topdeal/TodaysTopDeals";
import { IoStarSharp } from "react-icons/io5";
import { getSecondToLastWord } from "../../utils/utilsFunctions";
import { formatCurrency } from "../../utils/stringFunctions";
import { useNavigate } from "react-router-dom";

export interface DealCardProps {
  navigate: (path: string, options?: { state: any }) => void;
  deal: Deal;
}

const MobileDealCard = ({ deal }: { deal: Deal }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col justify-between items-center mt-5 mb-10 rounded-3xl w-full outline outline-[0.5px] outline-black/50">
        <div className="w-full flex-1">
          <img
            src={deal.carImages[0]}
            alt=""
            className="rounded-t-3xl w-full object-cover h-[90px] md:h-[140px]"
          />
        </div>

        <div className="flex flex-col w-full pt-2 px-2">
          <div className="flex flex-col lg:flex-row justify-start lg:justify-between items-start lg:items-center text-[14px] md:text-[18px] w-full mb-1">
            <div className="flex justify-between items-center gap-1 space-x-4 w-full">
              <div className=" flex-1 text-xs">
                <p>
                  {deal?.carMakeID?.make} {deal?.carMakeID?.year}{" "}
                  {deal?.carMakeID?.mode}
                </p>
              </div>
              <p className="flex justify-center items-center">
                <IoStarSharp />
                {`${deal.dmmRating > 0 ? deal.dmmRating : 5}`}
              </p>
            </div>

            <div>
              {deal.serviceTypes.length > 0 && (
                <p className="text-[12px]">
                  {formatCurrency(deal?.serviceTypes[0]?.price, true)}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center text-[10px] sm:text-[12px] lg:text-[15px] font-light">
            <div>
              {/* <p>{deal.carSeries}</p> */}
              <p className="">
                <span className="hidden lg:block">Location: </span>
                <span className=" font-medium">
                  {" "}
                  {getSecondToLastWord(deal?.address)}{" "}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="min-w-full p-2">
          <button
            className="border rounded-full text-[12px] md:text-[15px] w-full bg-black text-white py-1"
            onClick={() => {
              navigate(`/car/details/${deal.carCode}`);
            }}
          >
            VIEW
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileDealCard;
