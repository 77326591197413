import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface FieldCheckboxProps {
  control: any;
  name: string;
  label: string;
  errors: any;
}

const FieldCheckbox: React.FC<FieldCheckboxProps> = ({
  control,
  name,
  label,
  errors,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={false} // Ensure default value is set
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={!!field.value} // Ensure boolean value
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                fontWeight: "300",
                fontSize: { xs: 13, md: 16 },
              },
            }}
            label={label}
          />
        )}
      />
      {!!errors?.[name]?.message && (
        <FormHelperText sx={{ color: "red", px: 2 }}>
          {errors?.[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FieldCheckbox;
