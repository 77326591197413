import React from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const CarouselSkeleton = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "350px",
        borderRadius: "24px 0 0 0",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        animation="wave"
        sx={{ borderRadius: "24px 0 0 0" }}
      />
    </Box>
  );
};

export default CarouselSkeleton;
