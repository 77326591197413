import React, { useEffect } from "react";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import { useLocation } from "react-router-dom";
import { getDateStringFormat } from "../../utils/utilsFunctions";

const HostListingCompleted = () => {
  const location = useLocation();
  const data = location.state;
  const userDataString = localStorage.getItem("UserData");
  const userData = JSON.parse(userDataString as string);

  useEffect(() => {
    if (
      !!data?.data?.roleInfo &&
      userData?.role?.name !== data?.data?.roleInfo?.name
    ) {
      const newUserData = { ...userData, role: data?.data?.roleInfo };

      localStorage.setItem("UserData", JSON.stringify(newUserData));
    }
  }, [data?.data?.roleInfo, userData]);

  const orderData = {
    hostId: data?.data?.host?._id,
    userId: data?.data?.user,
    bookingPrice: 0,
  };

  return (
    <div>
      <div className="w-full bg-[#F1F1F2] justify-center h-[100px] mb-16"></div>

      <div className="mx-auto lg:flex-row flex flex-col justify-center mt-10 mb-32 lg:gap-20 lg:w-[85%]">
        <div className="w-full flex flex-col max-lg:px-6">
          <div className="flex items-center mb-3 gap-2">
            <h2 className="text-[20px] md:text-[25px]">
              Listing complete, awaiting admin approval!
            </h2>
          </div>

          <p className="text-[15px] md:text-[18px] font-light my-4 py-3">
            Thanks,
            <span className="font-medium">{` ${data?.data?.user?.firstName} ${data?.data?.user?.lastName} `}</span>
            for indicating to list your asset with DMM GO. Your provided
            information will be processed and a mail will be sent to confirm the
            outcome of your registration.
          </p>

          <hr className="mb-3 mt-1" />

          <div className="flex justify-between items-center w-full flex-col md:flex-row py-3">
            <div className="flex flex-col md:flex-row md:space-x-24 w-full">
              <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                <p className="font-light">Car Code:</p>
                <p className="font-medium">{data?.data?.car?.carCode || ""}</p>
              </div>

              <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                <p className="font-light">Registration number:</p>
                <p className="font-medium">
                  {data?.data?.car?.registerationNumber?.toUpperCase() || ""}
                </p>
              </div>

              <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                <p className="font-light">Registration date:</p>
                <p className="font-medium">
                  {getDateStringFormat(data?.data?.car?.createdAt) || ""}
                </p>
              </div>

              <div className="text-[14px] md:text-[16px] flex justify-between md:block items-center">
                <p className="font-light">Status:</p>
                <p className="font-medium">{data?.data?.car?.status || ""}</p>
              </div>
            </div>
          </div>

          <hr className="mt-3 mb-7" />

          <div className="max-lg:hidden">
            <p className="text-[15px] md:text-[18px] font-light">
              You can access your registration online using the above booking
              number kindly reach us on{" "}
              <span className="font-medium">info@dmm.com</span> for anything you
              might need us for.
            </p>

            <p className="font-medium mt-4">Thanks for registering with us</p>
          </div>
        </div>

        <OrderSummary data={orderData} title="Account Summary" />

        <div className="block lg:hidden px-6">
          <p className="text-[15px] md:text-[18px] font-light">
            You can access your registration online using the above booking
            number kindly reach us on{" "}
            <span className="font-medium">info@dmm.com</span> for anything you
            might need us for.
          </p>

          <p className="font-medium mt-4">Thanks for registering with us</p>
        </div>
      </div>
    </div>
  );
};

export default HostListingCompleted;
