import Audi from "../images/topdeals/AudiA6.jpg";
import Toyota from "../images/topdeals/AudiA6.jpg";

export interface CardProps {
  image: string;
  price: string;
  name: string;
  id: number;
  route: string;
  navDestination?: string;
  smallButtonName: string;
  bigButtonName: string;
  status: string;
}

export const completedBookings: CardProps[] = [
  {
    name: "Audi A6",
    image: Audi,
    price: "N00,00",
    smallButtonName: "Fulfilled",
    bigButtonName: "View booking",
    status: "completed",
    route: "/profile/booking-completed",
    id: 0,
  },
  {
    name: "Audi A6",
    image: Audi,
    price: "N00,00",
    smallButtonName: "Cancelled",
    bigButtonName: "View booking",
    status: "completed",
    route: "/profile/booking-cancelled",
    id: 1,
  },
];
