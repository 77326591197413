import React, { ReactNode } from "react";

interface ListingButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  handleClick?: () => void;
  children?: ReactNode;
  skip?: boolean;
}

const ListingButton: React.FC<ListingButtonProps> = ({
  name,
  handleClick,
  skip = false,
  type = "button",
  children,
  ...props
}) => {
  return (
    <button
      className={`${
        skip
          ? "bg-white text-[#0E1427] border border-1 border-[#0E1427]"
          : "text-white"
      } ${
        props.disabled ? "bg-[#F1F1F2]" : "bg-[#0E1427]"
      } text-[20px] p-3 min-w-full md:min-w-[140px] rounded-full`}
      onClick={(e) => {
        if (type !== "submit") {
          e.preventDefault();
        }
        handleClick && handleClick();
      }}
      type={type}
      {...props}
    >
      <div className="flex justify-center items-center gap-3 px-2">
        {children}
        {name}
      </div>
    </button>
  );
};

export default ListingButton;
