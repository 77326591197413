import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useData } from "../../Hooks/useData";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TopUpFormSchema } from "../../validations/formDataSchema";
import { z } from "zod";
import FieldCurrency from "../../components/inputs/FieldCurrency";
import { removeNairaAndConvertToInt } from "../../utils/RemoveNairaAndConvertToInt";
import { handleRefresh } from "../../components/modal/LoginDialog";

type Inputs = z.infer<typeof TopUpFormSchema>;

const TopUp = ({ setWalletModal }: { setWalletModal: any }) => {
  const {
    control,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    resolver: zodResolver(TopUpFormSchema),
    mode: "onBlur",
  });

  const { state } = useAuth();
  const [topUpLoading, setTopUpLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [walletData, setWalletData] = useState<any>(0);
  const { userData } = useData();

  const PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY || "";

  const onSuccess = async (reference: any) => {
    setTopUpLoading(true);
    const formattedAmount = removeNairaAndConvertToInt(amount);

    try {
      const response = await axios.post(
        "https://dmm-backend-f562.onrender.com/api/wallet/deposit",
        {
          userId: state.userId,
          walletId: walletData?._id,
          paystackTrans: reference.reference,
          amount: formattedAmount,
          transTypeId: "660517f161f690c1f42fdfbf",
          transActionTypeId: "66051ce38eb0dce655438c7e",
        }
      );

      if (!response?.data?.isError) {
        handleRefresh();
      }
    } catch (error) {
      setTopUpLoading(false);

      console.log("error", error);
    }
  };

  const onClose = () => {
    setWalletModal(false);
  };

  const config = {
    publicKey: PUBLIC_KEY,
    email: userData?.email || "",
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    amount: +amount * 100,
    reference: new Date().getTime().toString(),
    text: "Top Up",
    className:
      "w-full bg-[#0e1427] text-white py-3 hover:bg-gray-700 focus:outline-none rounded-full",
    onSuccess: () => {
      onSuccess(config.reference);
    },
    onClose: () => {
      onClose();
    },
  };

  const fetchWalletData = async () => {
    try {
      if (userData?.userId) {
        const { data } = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/wallet/accountBalance/${userData?.userId}`
        );

        setWalletData(data?.data?.wallet);
      }
    } catch (error) {
      console.log("error fetching account balance data --> ", error);
    }
  };

  useEffect(() => {
    fetchWalletData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="flex flex-col w-full gap-4 mt-2 mb-8 p-[10px]">
      <FieldCurrency
        control={control}
        name="amount"
        label="Enter amount"
        errors={errors}
        onChange={(e: any) => {
          setAmount(removeNairaAndConvertToInt(e.target.value).toString());
        }}
      />

      <div className="relative mt-4">
        {/*Manually disabling button*/}
        {(!isValid || topUpLoading) && (
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 z-10 cursor-not-allowed rounded-full" />
        )}

        <PaystackButton {...config} />
      </div>
    </div>
  );
};

export default TopUp;
