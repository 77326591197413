import React from "react";
import BookingStep from "./BookingStep";

export type Step = {
  img: string;
  title: string;
  description: string;
};

interface BookingProps {
  steps: Step[];
}

const Booking: React.FC<BookingProps> = ({ steps }) => {
  return (
    <div className="hidden lg:flex bg-gray-100 justify-center items-center py-10 px-5 w-full ">
      <div className="grid grid-cols-3 justify-center items-center w-full max-w-[1480px]">
        {steps.map((step) => (
          <BookingStep
            key={step.title}
            src={step.img}
            title={step.title}
            description={step.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Booking;
