import React from "react";
import { formatCurrency } from "../../utils/stringFunctions";
import { GrDown } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import { getRoutesList } from "./NavigationButtons";

interface UserWalletProps {
  walletData: {
    balance: number;
  };
  setWalletModal: (value: boolean) => void;
}

const UserWallet: React.FC<UserWalletProps> = ({
  walletData,
  setWalletModal,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = getRoutesList(currentPath);

  return (
    <div
      className={`w-full lg:w-[550px] ${
        parts.includes("Wallet") ? "block" : "hidden"
      }`}
    >
      <div className="md:border-[rgb(151,150,150)] md:border-1 md:border sm:rounded-2xl flex-wrap md:px-3 py-4 flex items-center w-[100%] justify-between gap-6 bg-white">
        <div>
          <p className="text-[12px] font-light">Wallet Balance</p>
          <p className="text-[15px] md:text-[18px] font-medium -mt-1">
            {walletData.balance ? formatCurrency(walletData.balance) : 0}
          </p>
        </div>

        <div
          className="lg:flex cursor-pointer border rounded-full px-5 text-[12px] md:text-[14px] py-2 bg-[#0E1427] text-white lg:mt-0 flex justify-center items-center gap-3"
          onClick={() => setWalletModal(true)}
        >
          <p>Wallet Options</p>
          <span>
            <GrDown />
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserWallet;
