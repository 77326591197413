// SideMenu.tsx
import React, { ReactNode } from "react";
import { MdClose } from "react-icons/md";
import { Drawer, IconButton, Box } from "@mui/material";
import LogoFull from "../../assets/images/logoFull.png";

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose, children }) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{ display: { xs: "block", lg: "none" } }}
    >
      <Box
        sx={{
          width: "70vw",
          height: "100%",

          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F3F5F6",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 16, right: 16 }}
            onClick={onClose}
          >
            <MdClose size={25} />
          </IconButton>
          <img src={LogoFull} alt="logo" style={{ height: 160, width: 160 }} />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
