import React from "react";
import { VscBell } from "react-icons/vsc";
import { VscBellDot } from "react-icons/vsc";
import AccountMenuItems from "./AccountMenuItems";
import { FaCar } from "react-icons/fa";

interface LoggedInButtonGrpProps {
  state: {
    isLoggedIn: boolean;
    isUserType?: boolean;
  };
  isNotifications: boolean;
  fetchNotifications: () => void;
  navigate: (path: string) => void;
  handleLogout: () => void;
}

const LoggedInButtonGrp: React.FC<LoggedInButtonGrpProps> = ({
  isNotifications,
  fetchNotifications,
  navigate,
  state,
  handleLogout,
}) => {
  return (
    <div className="relative flex items-center justify-between gap-5">
      <div className="flex items-center w-full h-full">
        {!isNotifications && (
          <button
            type="button"
            title="notification"
            onClick={() => {
              fetchNotifications();
              navigate("/notifications");
            }}
          >
            <VscBell className="h-[30px] w-[30px]" />
          </button>
        )}

        {isNotifications && (
          <button
            type="button"
            title="notification"
            onClick={() => {
              fetchNotifications();
              navigate("/notifications");
            }}
          >
            <VscBellDot className="h-[30px] w-[30px]" />
          </button>
        )}

        <button
          type="button"
          className="text-gray-300 bg-[#0e1427] border rounded-full ml-5 px-6 py-2 hover:bg-gray-700 flex gap-2 justify-center items-center w-full"
          onClick={() => {
            navigate("/car/list-vehicle");
          }}
        >
          <FaCar className="h-6 w-6" />I have a car
        </button>
      </div>

      <AccountMenuItems navigate={navigate} handleLogout={handleLogout} />
    </div>
  );
};

export default LoggedInButtonGrp;
