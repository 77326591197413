import { TouchEventHandler, useState } from "react";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { Deal } from "../topdeal/TodaysTopDeals";
import MobileDealSkeletonCard from "../skeletons/MobileDealSkeletonCard";
import MobileDealCard from "./MobileDealCard";
import { DealCardsProps } from "./WebDealCards";

interface MobileDealCardProps extends DealCardsProps {
  endIndex: number;
  items: Deal[];
}

const MobileDealCards: React.FC<MobileDealCardProps> = ({
  showPreviousPage,
  itemsPerPage,
  currentPage,
  endIndex,
  items,
  displayedItems,
  showNextPage,
}) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75 && !lastPage) {
      showNextPage();
    }

    if (touchStart - touchEnd < -75 && !firstPage) {
      showPreviousPage();
    }
  }

  const firstPage = currentPage === 0;
  const lastPage = endIndex >= items.length;

  return (
    <div className="w-full lg:hidden">
      <div className="flex justify-between items-center">
        <h1 className="font-medium text-xl lg:text-2xl text-left w-full max-w-[1350px] mx-auto">
          Today's Top Deals
        </h1>

        <button
          onClick={showPreviousPage}
          type="button"
          title="button"
          disabled={firstPage}
          className={`${firstPage ? "text-gray-200" : "text-black"}`}
        >
          <IoIosArrowRoundBack className="w-7 h-7" />
        </button>

        <button
          onClick={showNextPage}
          type="button"
          title="button"
          disabled={lastPage}
          className={`${lastPage ? "text-gray-200" : "text-black"}`}
        >
          <IoIosArrowRoundForward className="w-7 h-7" />
        </button>
      </div>

      <div className="flex items-center justify-center w-full lg:overflow-x-none overflow-x-auto gap-4 ">
        <div
          className={`grid grid-cols-2 justify-between gap-3 w-full px-1`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {!displayedItems.length
            ? Array.from({ length: itemsPerPage }).map((_, index) => (
                <MobileDealSkeletonCard key={index} />
              ))
            : displayedItems.map((deal, index) => (
                <MobileDealCard key={deal.id || index} deal={deal} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default MobileDealCards;
