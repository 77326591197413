import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDividerVertical } from "react-icons/rx";
import { FaAngleRight } from "react-icons/fa6";
import { AiOutlineExclamation } from "react-icons/ai";
import Modal from "../../components/modal/Modal";
import { IoIosArrowDown } from "react-icons/io";
import { TfiLocationPin } from "react-icons/tfi";
import { LuCalendarDays } from "react-icons/lu";
import { CiClock2 } from "react-icons/ci";
import { BiBriefcaseAlt } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa";
import { FaRegDotCircle } from "react-icons/fa";
import { FiCircle } from "react-icons/fi";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import {
  useCheckCurrentCarHost,
  useData,
  useIsMobile,
} from "../../Hooks/useData";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../components/elements/CustomButton";
import { MoonLoader } from "react-spinners";
import { formatCurrency } from "../../utils/stringFunctions";
import { formatDate } from "../car-rental/CarRentalBooking";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import DmmDialog from "../../components/modal/DmmDialog";
import { REACT_TOAST_CONFIG, dateToEpoch } from "../../utils/utilsFunctions";
import { useDataContext } from "../../providers/DataProvider";
import RescheduleSearchbox from "../../components/rescheduleSearchbox/rescheduleSearchbox";
import { removeNairaAndConvertToInt } from "../../utils/RemoveNairaAndConvertToInt";
import { useUserStatus } from "../../context/userStatusContext";

const PendingBookingConfirmation = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    textArea: "",
    issueDate: "",
    expiryDate: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { userData } = useData();
  const { state, dispatch } = useAuth();
  const role = userData?.role?.name;
  const [partialInfoModal, setPartialInfoModal] = useState(false);
  const [price, setPrice] = useState("");
  const [pleaseConfirmModal, setPleaseConfirmModal] = useState(false);
  const [partialAvailableConfirmModal, setPartialAvailableConfirmModal] =
    useState(false);
  const [declineBookingModal, setDeclineBookingModal] = useState(false);
  const [declineReasonModal, setDeclineReasonModal] = useState(false);
  const [assignDriverModal, setAssignDriverModal] = useState(false);
  const [listDriverModal, setListDriverModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const [finalDestination, setFinalDestination] = useState<any>(null);
  const [carCity, setCarCity] = useState<any>("");
  const [driver, setDriver] = useState<any>({});
  const [isDriverAvailable, setIsDriverAvailable] = useState<any>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [done, setDone] = useState(false);
  const [confirmRescheduleModal, setConfirmRescheduleModal] = useState(false);
  const [cancelBookingModal, setCancelBookingModal] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const [userOpenOffer, setUserOpenOffer] = useState(false);
  const [counterOffer, setCounterOffer] = useState("");
  const [offerDeclineReason, setOfferDeclineReason] = useState("");
  const [isCounterOffer, setIsCounterOffer] = useState(false);
  const [isDeclineOffer, setIsDeclineOffer] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const [balance, setBalance] = useState<number>(0);
  const [bookingData, setBookingData] = useState();
  const isMobile = useIsMobile();
  const { userStatus} = useUserStatus();
  const {
    VAT_RATE,
    fromWhereSelected,
    finalDestinationSelected,
    returnTime,
    pickupTime,
    serviceType,
    setFromWhereSelected,
    setFromWhereInputValue,
    setFromWhere,
    setFinalDestinationInputValue,
    setFinalDestinationSelected,
    setPickupTime,
    setReturnTime,
  } = useDataContext();
  const [rescheduleLoading, setRescheduleLoading] = useState<any>(false);
  // const [loading, setLoading] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const id = useId();

  const HandleCancelBookingModal = () => {
    setCancelBookingModal(true);
    setConfirmRescheduleModal(false);
  };
  // const handleReturnTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setReturnTime(e.target.value);
  // };
  const handleSelectFromWhereCity = (
    e: ChangeEvent<HTMLSelectElement>,
    fieldName: any,
    itemId?: string
  ) => {
    setFromWhere(e.target.value);
  };
  // const handlePickupTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPickupTime(e.target.value);
  // };
  const handleSelectDestinationCity = (
    e: ChangeEvent<HTMLSelectElement>,
    fieldName: any,
    itemId?: string
  ) => {
    setFinalDestination(e.target.value);
  };
  // const id = useId();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const HandlePartialinfo = () => {
    setPartialInfoModal(true);
  };

  // const handleServiceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setServiceType(e.target.value);
  // };

  const HandlePleaseConfirmModal = () => {
    setPleaseConfirmModal(true);
    setPartialInfoModal(false);
  };

  const HandlePartialAvailableConfirmModal = () => {
    setPartialAvailableConfirmModal(true);
    setPleaseConfirmModal(false);
  };

  const HandleDeclineBookingModal = () => {
    setDeclineBookingModal(true);
  };

  const HandleDeclineReason = () => {
    setDeclineReasonModal(true);
    setDeclineBookingModal(false);
  };

  const HandleAssignDriver = () => {
    setAssignDriverModal(true);
  };
  const HandleListDriver = () => {
    setListDriverModal(true);
    setAssignDriverModal(false);
  };
  const handlePriceChange = (days: any) => {
    let servicePrice = data?.carId?.serviceTypes.filter(
      (val: any) => val.name === serviceType
    )[0]?.price;

    setPrice(servicePrice);
    const totalPrice = servicePrice * (days ?? numberOfDays) * (1 + VAT_RATE);
    setTotalPrice(totalPrice);
  };
  const calculateDays = () => {
    const returnDate = new Date(returnTime);
    const pickupDate = new Date(pickupTime);

    const timeDifference = returnDate.getTime() - pickupDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const days =
      !isNaN(daysDifference) && daysDifference > 0.1
        ? Math.round(daysDifference)
        : 1;

    setNumberOfDays(days);
    handlePriceChange(days);
    return days;
  };
  const handleEditClicked = () => {
    // handlePriceChange();
    calculateDays();
    setDone(true);
  };
  useEffect(() => {
    setDone(false);
    handleEditClicked();
  }, [serviceType, pickupTime, returnTime, fromWhereSelected]);

  const { isCurrentCarHost, hostData } = useCheckCurrentCarHost({
    hostId: data?.hostId,
  });
  const checkBalance = (payablePrice: any) => {
    if (state?.userId) {
      return (
        balance + data?.bookingPrice >
        removeNairaAndConvertToInt(payablePrice.toString())
      );
    } else {
      dispatch({
        type: "TOGGLELOGIN",
      });
    }
  };

  const HandleRescheduleBookingCheck = async () => {
    setRescheduleLoading(true);
    if (!checkBalance(totalPrice)) {
      toast.error("Insufficient funds to reschedule", REACT_TOAST_CONFIG);
      setRescheduleLoading(false);
      return;
    }
    setRescheduleModal(false);
    setConfirmRescheduleModal(true);
    setRescheduleLoading(false);
  };

  const HandleRescheduleBooking = async () => {
    setRescheduleLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/rescheduleBooking/${data?._id}`,
        {
          newPrice: totalPrice,
          newStartDate: pickupTime,
          newEndDate: !!returnTime ? returnTime : pickupTime,
          userId: state.userId,

          serviceTypeId: data?.carId?.serviceTypes.filter(
            (val: any) => val.name === serviceType
          )[0]?._id,
        }
      );
      if (!response.data?.isError === true) {
        // setConfirmRescheduleModal(true);
        setConfirmRescheduleModal(false);
        setRescheduleModal(false);
        setRescheduleLoading(false);
      }
    } catch (error) {
      console.log(error);
      setRescheduleLoading(false);
    }
    setRescheduleLoading(false);
  };
  const handleConfirmBooking = async () => {
    setConfirmLoading(true);
    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/acceptBooking/${data?._id}/${state.userId}`
      );

      if (!(response.data?.isError === true)) {
        setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        navigate("/profile/bookings");
      } else {
        setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Already confirmed", REACT_TOAST_CONFIG);

      navigate("/profile/bookings");
      console.log(error);
    }
  };

  const HandleUserDeclineOffer = async () => {
    // setConfirmLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/offer/userRejectCounterOffer`,
        {
          bookingId: data?._id,
          userId: state.userId,
        }
      );

      if (!(response.data?.isError === true)) {
        // setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        // navigate("/profile/bookings");
        setUserOpenOffer(false);
      } else {
        // setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Already confirmed", REACT_TOAST_CONFIG);

      navigate("/profile/bookings");
      console.log(error);
    }
  };
  const HandleDeclineOffer = async () => {
    // setConfirmLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/offer/hostRejectOfferPrice`,
        {
          bookingId: data?._id,
          hostUserId: state.userId,
          reasonForRejection: offerDeclineReason,
        }
      );

      if (!(response.data?.isError === true)) {
        // setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        // navigate("/profile/bookings");
        setOpenOffer(false);
      } else {
        // setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Already confirmed", REACT_TOAST_CONFIG);

      navigate("/profile/bookings");
      console.log(error);
    }
  };

  const HandleCounterOffer = async () => {
    // setConfirmLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/offer/hostCounterOffer`,
        {
          bookingId: data?._id,
          hostUserId: state.userId,
          hostPrice: counterOffer,
        }
      );

      if (!(response.data?.isError === true)) {
        setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        navigate("/profile/bookings");
        setOpenOffer(false);
      } else {
        // setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Already confirmed", REACT_TOAST_CONFIG);

      navigate("/profile/bookings");
      console.log(error);
    }
  };
  const HandleUserAcceptOffer = async () => {
    // setConfirmLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api//offer/userAcceptCounterOffer`,
        {
          bookingId: data?._id,
          userId: state.userId,
        }
      );

      if (!(response.data?.isError === true)) {
        setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        navigate("/profile/bookings");
        setUserOpenOffer(false);
      } else {
        toast.error(response?.data?.message, REACT_TOAST_CONFIG);
      }
    } catch (error: any) {
      setConfirmLoading(false);
      toast.error(error?.response?.data?.message, REACT_TOAST_CONFIG);

      console.log(error);
    }
  };
  const HandleAcceptOffer = async () => {
    // setConfirmLoading(true);

    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/offer/hostApproveOfferPrice`,
        {
          bookingId: data?._id,
          hostUserId: state.userId,
        }
      );

      if (!(response.data?.isError === true)) {
        setConfirmLoading(false);

        toast.success("Confirmed", REACT_TOAST_CONFIG);

        navigate("/profile/bookings");
        setOpenOffer(false);
      } else {
        // setConfirmLoading(false);
      }

      console.log("response-->", response);
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Already confirmed", REACT_TOAST_CONFIG);

      navigate("/profile/bookings");
      console.log(error);
    }
  };

  const HandleCancelbooking = async () => {
    try {
      // const { data } = await axios.get(
      //   `https://dmm-backend-f562.onrender.com/api/booking/cancelBooking/${bookingData?._id}`
      // );  6618eb7360e6dd59552dce48

      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/cancelBooking/${data?._id}`,
        {
          hostId: data?.hostId,
          userId: state.userId,
          cancellerId: state.userId,
          reasonForDecline: textareaValue,
        }
      );

      if (!response.data?.isError === true) {
        setDeclineReasonModal(false);
        navigate("/profile/bookings");
      }
    } catch (error) {
      setDeclineReasonModal(false);
    }
  };

  const [textareaValue, setTextareaValue] = useState<string>("");

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextareaValue(event.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };
  const HandleRescheduleModal = () => {
    setRescheduleModal(true);
  };
  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);

    const fetchDriver = async () => {
      try {
        const response = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
        );

        setDriver(response.data.data);
        setIsDriverAvailable(
          response.data.data.firstName || response.data.data.lastName
        );
        // setAllServiceTypeData(response?.data?.data);
        // setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchServiceType = async () => {
      try {
        const response = await axios.get(
          "https://dmm-backend-f562.onrender.com/api/general/getServiceTypes"
        );

        setAllServiceTypeData(response?.data?.data);
        // setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    // fetchCarDetails();
    fetchDriver();
    fetchServiceType();
  }, [data]);
  useEffect(() => {
    const getBookingData = async () => {
      try {
        if (data?._id && role === "Host") {
          const resp = await axios.get(
            `https://dmm-backend-f562.onrender.com/api//booking/getDetails/${data?._id}`
          );
          console.log(resp);

          setBookingData(resp?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getBookingData();
  }, [data?._id, role]);
  useEffect(() => {
    const getBalance = async () => {
      try {
        if (state?.userId) {
          const { data } = await axios.get(
            `https://dmm-backend-f562.onrender.com/api/wallet/accountBalance/${state?.userId}`
          );

          setBalance(data?.data?.wallet?.balance);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getBalance();
  }, [state?.userId]);
  const removeSecondsInDate = (date: string) => {
    return date?.replace(".000Z", "");
  };
  useEffect(() => {
    setFromWhereInputValue(data?.startLocation);
    setFinalDestinationSelected(data?.endLocation);
    setFinalDestinationInputValue(data?.endLocation);
    setPickupTime(removeSecondsInDate(data?.bookingStartDate));
    setReturnTime(removeSecondsInDate(data?.bookingEndDate));
    setTotalPrice(data?.bookingPrice);
  }, []);
  
console.log(userData);

  return (
    <div>
      <ToastContainer />

      <Summary data={data} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 mb-32 lg:gap-20 lg:w-[85%]">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex items-center mb-3 gap-2">
            <AiOutlineExclamation className="text-[40px] md:text-[50px] bg-[#C7C727] rounded-full p-1 text-white rotate-180" />

            <h2 className="text-[20px] md:text-[25px] ml-2">
              Booking pending confirmation!
            </h2>
          </div>

          {
            
            userStatus=="you"? 
            <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi, {userData?.firstName} you have made a booking of the car{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span> with booking id {data?.bookingNumber}.
            <br></br>
            Please check below for details.
          </p>
          :
            <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi, {hostData?.firstName} you have a booking for your car{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span> with booking id {data?.bookingNumber}.
            <br></br>
            Please check below for details.
          </p>
          
          }

          <hr className="mb-3 mt-1" />

          {/* details */}
          <div className=" flex justify-between mb-4">
            <div className=" flex w-full flex-col md:flex-row py-3 hover:border-orange-300 cursor-pointer md:space-x-16">
              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Booking date:</p>

                  <p>
                    {formatDate(data?.bookingStartDate)} -{" "}
                    {formatDate(data?.bookingEndDate)}
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Driver:</p>

                  {role !== "Host" &&
                    (isDriverAvailable ? (
                      <p>
                        {driver?.firstName} {driver?.lastName}
                      </p>
                    ) : (
                      <p> Not Assigned</p>
                    ))}

                  {isCurrentCarHost &&
                    (isDriverAvailable ? (
                      <p>
                        {driver?.firstName} {driver?.lastName}
                      </p>
                    ) : (
                      <button
                        className="bg-indigo-950 text-white text-xs border rounded-full px-4 py-2"
                        onClick={HandleAssignDriver}
                      >
                        Assign a driver
                      </button>
                    ))}
                </div>
              </div>

              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Service type:</p>

                  <p className="">
                    {
                      allServiceTypeData.find(
                        (val) => val?._id === data?.serviceTypeId
                      )?.name
                    }
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Status:</p>

                  <p className="">{data?.status}</p>
                </div>
              </div>

              <div className="flex md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center w-full">
                  <p className="font-light">Amount offered:</p>

                  <p className="">{formatCurrency(data?.bookingPrice)}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-start items-center gap-4">
            {role === "Host" &&
              isCurrentCarHost &&
              !(
                data?.offerStatus === "PENDING" ||
                data?.offerStatus === "COUNTER_OFFER"
              ) && (
                <CustomButton
                  loading={confirmLoading}
                  disabled={!(driver?.firstName || driver?.lastName)}
                  type="submit"
                  className={` text-white text-sm  rounded-full w-[%] px-5 py-2 hover:shadow-md mb-5 ${
                    driver?.firstName || driver?.lastName
                      ? "bg-black border-indigo-950 border"
                      : " bg-gray-300 "
                  }`}
                  onClick={handleConfirmBooking}
                  // onClick={()=>console.log("heeeeey")
                  // }
                >
                  Confirm availability
                </CustomButton>
              )}

            {data?.offerStatus === "PENDING" &&
              role === "Host" &&
              isCurrentCarHost && (
                <CustomButton
                  // loading={confirmLoading}
                  disabled={!(driver?.firstName || driver?.lastName)}
                  type="submit"
                  className={` text-white text-sm  rounded-full w-[%] px-5 py-2 hover:shadow-md mb-5 ${
                    driver?.firstName || driver?.lastName
                      ? "bg-black border-indigo-950 border"
                      : " bg-gray-300 "
                  }`}
                  onClick={() => {
                    if (role === "Host") {
                      setOpenOffer(true);
                    } else {
                      setUserOpenOffer(true);
                    }
                  }}
                >
                  Check Offer
                </CustomButton>
              )}

            {data?.offerStatus === "COUNTER_OFFER" && !isCurrentCarHost && (
              <CustomButton
                disabled={!(driver?.firstName || driver?.lastName)}
                type="submit"
                className={` text-white text-sm  rounded-full w-[%] px-5 py-2 hover:shadow-md mb-5 ${
                  driver?.firstName || driver?.lastName
                    ? "bg-black border-indigo-950 border"
                    : " bg-gray-300 "
                }`}
                onClick={() => {
                  setUserOpenOffer(true);
                }}
                // onClick={()=>console.log("heeeeey")
                // }
              >
                Check Offer
              </CustomButton>
            )}

            {!isCurrentCarHost &&
              !(
                data?.offerStatus === "PENDING" ||
                data?.offerStatus === "COUNTER_OFFER"
              ) && (
                <button
                  className="text-black -mt-4 border rounded-full px-4 p-2"
                  onClick={HandleRescheduleModal}
                >
                  Reschedule
                </button>
              )}

            {!(
              data?.offerStatus === "PENDING" ||
              data?.offerStatus === "COUNTER_OFFER"
            ) && (
              <button
                className="text-red-600 -mt-4 hover:border rounded-full px-4 p-2"
                onClick={HandleDeclineBookingModal}
              >
                {data?.offerStatus !== "PENDING"
                  ? isCurrentCarHost
                    ? "Decline booking"
                    : "Cancel booking"
                  : "  "}
              </button>
            )}
          </div>

          <hr className="my-7" />

          {isCurrentCarHost && isDriverAvailable && (
            <p className="text-sm p-4 lg:p-0">
              Please note that you will have to assign a driver to complete
              booking confirmation.
            </p>
          )}
        </div>

        <OrderSummary data={data} otherData={bookingData} showContactDetails={false} />
      </div>

      <DmmDialog
        open={rescheduleModal}
        title="Reschedule booking"
        onClose={() => {
          setRescheduleModal(false);
        }}
        fullScreen={isMobile}
      >
        <div className="flex flex-col w-">
          <p className="text-xs">
            Please note that a penalty applies for rescheduling less than{" "}
            <span className="font-bold">24 hours</span> before the trip start
          </p>

          <div className="w-full max-w-[90%] ">
            <RescheduleSearchbox
              vehicleServiceTypeData={data?.carId?.serviceTypes}
              flexDirection="flex-col"
              searchboxHeight="h-full"
              width="w-full max-w-full"
              buttonTitle={"Edit"}
              searchBoxClasses={{
                buttonClass: "bg-white border border-black text-black",
              }}
              applyToolTip={true}
              showMobileVersion
            />
          </div>

          <div className="flex flex-col w-full my-3">
            <div className=" flex gap-2 ml-4">
              <p>Price</p>
              <p className=" font-medium">{formatCurrency(totalPrice)}</p>
            </div>
            <div className="w-full">
              <CustomButton
                loading={rescheduleLoading}
                type="button"
                className="text-white bg-[#0E1427] border-gray-500 border rounded-full px-10 py-2 hover:shadow-md mt-10 w-full"
                // onClick={handleEditClicked}
                onClick={HandleRescheduleBookingCheck}
              >
                Reschedule
              </CustomButton>
            </div>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={confirmRescheduleModal}
        onClose={() => {
          setConfirmRescheduleModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mt-[15px] mb-2">
            Please confirm!
          </h1>
          <p>Are you sure you want to reschedule this booking?</p>
          <div className="flex items-center space-x-16 mt-5 mb-3">
            <CustomButton
              type="button"
              loading={rescheduleLoading}
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-16 py-1 hover:shadow-md"
              onClick={HandleRescheduleBooking}
            >
              Yes
            </CustomButton>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-16 py-1 hover:shadow-md"
              onClick={() => {
                setConfirmRescheduleModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={partialInfoModal}
        onClose={() => {
          setPartialInfoModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold">Partial availability info</h1>

          <div className="flex flex-col justify-center">
            <div className="flex gap-5">
              <div className="flex flex-col items-center mt-9">
                <FaRegDotCircle />
                <span className="border-black border-dashed border-l w-[10px] h-[120px] ml-2"></span>
                <FaRegCircle />
              </div>
              <div>
                <div className="text-sm mt-4 border rounded-xl p-3">
                  <p>From</p>
                  <div className="flex justify-between space-x-16">
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <LuCalendarDays />
                        <p>Aug 24, 2020</p>
                      </div>
                      <hr />
                    </div>
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <CiClock2 />
                        <p>07:00 am</p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="text-sm mt-10 border rounded-xl p-3">
                  <p>To</p>
                  <div className="flex justify-between">
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <LuCalendarDays />
                        <p>Aug 25, 2020</p>
                      </div>
                      <hr />
                    </div>
                    <div>
                      <div className="flex items-center mt-3 gap-1 mb-1">
                        <CiClock2 />
                        <p>07:00 pm</p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md mt-10"
              onClick={HandlePleaseConfirmModal}
            >
              Reschedule
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={pleaseConfirmModal}
        onClose={() => {
          setPleaseConfirmModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm!</h1>
          <p>
            Are you sure you want to make this booking<br></br> partially
            available?
          </p>
          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandlePartialAvailableConfirmModal}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setPleaseConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={partialAvailableConfirmModal}
        onClose={() => {
          setPartialAvailableConfirmModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div>
          <h1 className="text-lg font-semibold mb-2">
            Partial availability confirmed!
          </h1>
          <p className="mt-2 mb-8">
            Booking has been made partially available.
          </p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md ml-4"
            onClick={() => {
              setPartialAvailableConfirmModal(false);
              navigate("/profile/bookings");
            }}
          >
            Go home
          </button>
        </div>
      </DmmDialog>

      <DmmDialog
        open={userOpenOffer}
        onClose={() => {
          setUserOpenOffer(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm offer!</h1>
          <div className=" flex  gap-2 ">
            <p className="text-sm">Offer:</p>
            <p className="text-sm">{formatCurrency(data?.bookingPrice)}</p>
          </div>

          {/* {isDeclineOffer && (
            <div className="mb-4 border border-gray-300 rounded-3xl">
              <textarea
                name="textarea"
                title="textarea"
                placeholder=""
                onChange={(e) => setOfferDeclineReason(e.target.value)}
                cols={50}
                rows={4}
              />
              <button
                type="button"
                className="text-white text-sm bg-indigo-950 border-gray-500 border rounded-full px-3 py-1 hover:shadow-md"
                onClick={HandleDeclineOffer}
              >
                Post and go home
              </button>
            </div>
          )} */}

          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleUserAcceptOffer}
            >
              Accept
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleUserDeclineOffer}
              // onClick={() => setIsDeclineOffer((prev) => !prev)}
            >
              Click to decline
            </button>
          </div>
          {/* {isCounterOffer && (
            <>
              <input
                type="number"
                placeholder=" Enter counter offer"
                onChange={(e) => setCounterOffer(e.target.value)}
              />
              <button
                type="button"
                className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
                onClick={HandleCounterOffer}
              >
                Submit
              </button>
            </>
          )}
          <p onClick={() => setIsCounterOffer((prev) => !prev)}>
            Counter Offer
          </p> */}
        </div>
      </DmmDialog>

      <DmmDialog
        open={openOffer}
        onClose={() => {
          setOpenOffer(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm offer!</h1>
          <div className=" flex  gap-2 ">
            <p className="text-sm">Offer:</p>
            <p className="text-sm">
              {formatCurrency((data?.bookingPrice / (1 + VAT_RATE)) * 0.8)}
            </p>
          </div>

          {isDeclineOffer && (
            <div className="mb-4  border-gray-300 rounded-3xl">
              <textarea
                //   type="textarea"
                name="textarea"
                title="textarea"
                placeholder=""
                // value={formData.textArea}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                onChange={(e) => setOfferDeclineReason(e.target.value)}
                cols={50}
                rows={4}
              />
              <button
                type="button"
                className="text-white text-sm bg-indigo-950 border-gray-500 border rounded-full px-3 py-1 hover:shadow-md"
                onClick={HandleDeclineOffer}
              >
                Decline and go home
              </button>
            </div>
          )}

          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleAcceptOffer}
            >
              Accept
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => setIsDeclineOffer((prev) => !prev)}
            >
              Click to decline
            </button>
          </div>
          {isCounterOffer && (
            <>
              <div className=" flex flex-col items-center gap-4">
                <input
                  type="number"
                  placeholder=" Enter counter offer"
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  onChange={(e) => setCounterOffer(e.target.value)}
                />
                <button
                  type="button"
                  className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
                  onClick={HandleCounterOffer}
                >
                  Submit
                </button>
              </div>
            </>
          )}
          <div
            className=" w-full flex items-center justify-center mt-2 cursor-pointer underline
          "
          >
            <p onClick={() => setIsCounterOffer((prev) => !prev)}>
              Click to counter Offer
            </p>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={declineBookingModal}
        onClose={() => {
          setDeclineBookingModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Please confirm!</h1>
          <p className="text-sm">
            Are you sure you want to decline this booking?
          </p>
          <div className="flex justify-center items-center space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleDeclineReason}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setDeclineBookingModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={declineReasonModal}
        onClose={() => {
          setDeclineReasonModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Decline reason</h1>
          <p className="text-sm mb-4">
            What is your reason for declining this booking?
          </p>
          <div className="mb-4  border-gray-300 rounded-3xl">
            <textarea
              //   type="textarea"
              className="mb-4 border border-gray-300 rounded-3xl p-2"
              name="textarea"
              title="textarea"
              placeholder=""
              // value={formData.textArea}
              onChange={handleTextareaChange}
              cols={50}
              rows={4}
            />
          </div>
          <div className="flex space-x-6 mt-7 mb-3 w-full">
            <button
              type="button"
              className="text-white text-sm bg-indigo-950 border-gray-500 border rounded-full px-3 py-1 hover:shadow-md w-full"
              onClick={() => {
                HandleCancelbooking();
              }}
            >
              Post and go home
            </button>
            <button
              type="button"
              className="text-indigo-950 text-sm bg-white-700 border-indigo-900 border rounded-full px-3 py-1 hover:shadow-md w-full"
              onClick={() => {
                setDeclineReasonModal(false);
              }}
            >
              Cancel and go back
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={assignDriverModal}
        onClose={() => {
          setAssignDriverModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-lg font-semibold mb-2">Assign a driver</h1>
          {/* <p className="text-sm">
            Select a driver below to assign to this booking
          </p> */}
          {/* <div className="text-sm font-light">
            <div className="flex items-center p-2 gap-2">
              <FiCircle className=" rounded-full w-3 h-3" />
              <p className="">Lawal Odimbu</p>
            </div>
            <hr />
            <div className="flex items-center p-2 gap-2">
              <FiCircle className=" rounded-full w-3 h-3" />
              <p className="">Lawal Odimbu</p>
            </div>
            <hr />
            <div className="flex items-center p-2 gap-2">
              <FiCircle className=" rounded-full w-3 h-3" />
              <p className="">Lawal Odimbu</p>
            </div>
            <hr />
            <div className="flex items-center p-2 gap-2">
              <FiCircle className=" rounded-full w-3 h-3" />
              <p className="">Lawal Odimbu</p>
            </div>
            <hr />
          </div> */}
          <div className="flex flex-col justify-center items-center mt-6 mb-">
            {/* <button
              type="button"
              className="text-white bg-[#0e1427] border-gray-500 border rounded-full px-20 py-1 hover:shadow-md"
              onClick={() => {
                navigate("/profile/driver-assigned");
              }}
            >
              Assign driver
            </button>
            <div className="relative text-center w-full h-6 md:py-8 mb-">
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <span className="bg-white px-3 text-[#9E9E9E]">or</span>
              </div>
              <div className="absolute inset-y-1/2 left-0 right-0 bg-gray-300 h-[1px] z-0"></div>
            </div> */}

            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-[70px] py-1 hover:shadow-md"
              onClick={() => {
                // navigate("/car/list-vehicle-from-booking", { state: data });
                navigate("/car/assign-driver", { state: data?.carId?._id });
              }}
              // onClick={HandleListDriver}
            >
              List a new driver
            </button>
            <button
              className="items-center mt-6"
              // onClick={HandleAssignDriver}
            >
              Hire a driver
            </button>
          </div>
        </div>
      </DmmDialog>

      <DmmDialog
        open={listDriverModal}
        onClose={() => {
          setListDriverModal(false);
        }}
        title=""
        fullScreen={isMobile}
      >
        <div className="flex flex-col p-0">
          <h1 className="text-lg font-semibold mb-2">List a driver</h1>
          <p className="text-xs">
            Enter driver information below to list him/her
          </p>
          <h2 className="text-base font-semibold mt-5 mb-2">
            Driver information
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between gap-3">
              <div className="mb-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email address"
                value={formData.email}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              />
            </div>

            <h2 className="text-base font-semibold mt-5 mb-2">
              Driving license information
            </h2>
            <div className="mb-4">
              <input
                type="licensenumber"
                name="licensenumber"
                placeholder="Driving license number"
                value={formData.email}
                onChange={handleInputChange}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              />
            </div>
            <div className="font-semibold flex justify-between gap-3">
              <div className="mb-4">
                <input
                  type="text"
                  name="issuedate"
                  placeholder="Issue date"
                  value={formData.issueDate}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="expirydate"
                  placeholder="Expiry Date"
                  value={formData.expiryDate}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <select
              name="license"
              id=""
              title="license"
              className=" border-2 mb-2 rounded-lg p-2 w-full"
            >
              <option value=" ">
                Upload driving license{" "}
                <span className="font-light">(Jpeg, PDF)</span>
              </option>
            </select>

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className=" bg-[#0e1427] text-white p-2 focus:outline-none rounded-full mt-4 w-[90%]"
                onClick={() => {
                  navigate("/profile/driver-assigned");
                }}
              >
                List driver
              </button>
            </div>
          </form>
          <p className="text-xs mt-3">
            This driver will be automatically assigned to this booking
          </p>
        </div>
      </DmmDialog>
    </div>
  );
};

export default PendingBookingConfirmation;
