import React from "react";
import { NavLink, useLocation } from "react-router-dom";

type Data = {
  id: number;
  title: string;
  link: string;
};

interface NavigationButtonsProps {
  data: Data[];
}

const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getRoutesList = (path: string): string[] => {
  // Split the path by '/'
  const parts = path.split("/");

  const routes = parts.map((route) => toSentenceCase(route));

  // Ensure lastPortion is a string before converting to sentence case
  return routes;
};

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ data }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = getRoutesList(currentPath);

  return (
    <div
      className={
        "hidden md:flex rounded-full justify-around p-1 items-center lg:bg-[#fff] border-[#DDE1E5] border lg:border-1 text-[14px] py-1 mb-6"
      }
    >
      {
        // displayData
        data?.map((item, i) => (
          <NavLink
            to={item.link}
            key={item.id || i}
            className={`${
              parts.includes(item.title)
                ? "text-[#f4f4f4] bg-[#0E1427] rounded-full items-center"
                : ""
            } py-3 px-10`}
          >
            <p>{item.title}</p>
          </NavLink>
        ))
      }
    </div>
  );
};

export default NavigationButtons;
