import React, { useEffect, useState } from "react";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { FormDetailsinterface } from "./FormDetailsInterface";
import { useAuth } from "../../context/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import TruckDetails from "./TruckDetails";
import TruckDocument from "./TruckDocument";
import TruckDriverDetails from "./TruckDriverDetails";
import BankDetailsForTruck from "./BankDetailsForTruck";
import HostDetails from "./HostDetails";
import TruckGuarantorDetails from "./TruckGuarantorDetails";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Declaration from "./Declaration";
import axios from "axios";

const baseURL = `https://dmm-backend-f562.onrender.com`;
const endpoint = `/api/host/createHost`;

const ListTruck = () => {
  const [minValue, setMinValue] = useState(25);
  const [maxValue, setMaxValue] = useState(75);
  const [id, setId] = useState(0);
  const { state, dispatch } = useAuth();
  const [formDetails, setFormDetails] = useState<FormDetailsinterface>({});
  const [hostForm, setHostForm] = useState({});
  const [truckDetailsForm, setTruckDetailsForm] = useState({});
  const [truckDocumentsForm, setTruckDocumentsForm] = useState({});
  const [truckGuarantorsForm, setTruckGuarantorsForm] = useState({});
  // const [serviceForm, setServiceForm] = useState({});
  const [truckDriversDetailsForm, setTruckDriversDetailsForm] = useState({});
  const location = useLocation();

  let inputId = location?.state - 1;

  console.log("input", inputId);

  const navigate = useNavigate();

  console.log("input", inputId);
  const handleNextClick = () => {
    setId((prev) => prev + 1);
    console.log("clicked");
  };
  const handleSkipClick = () => {
    setId((prev) => prev + 1);
    console.log("clicked");
  };

  const setHostForms = (inputFields: any) => {
    setHostForm(inputFields);
    console.log("result>>", inputFields);
  };
  const setTruckDetailsForms = (inputFields: any) => {
    setTruckDetailsForm(inputFields);
    console.log("result>>", inputFields);
  };
  const setTruckDocumentsForms = (inputFields: any) => {
    setTruckDocumentsForm(inputFields);
    console.log("result>>", inputFields);
  };
  const setTruckGuarantorsForms = (inputFields: any) => {
    setTruckGuarantorsForm(inputFields);
    console.log("result>>", inputFields);
  };
  const setTruckDriverDetailsForms = (inputFields: any) => {
    setTruckDriversDetailsForm(inputFields);
    console.log("result>>", inputFields);
  };
  const handleListAssetClick = async () => {
    const result = await axios.post(
      baseURL + endpoint,
      {
        userProfile: hostForm,
        host: {
          rCNumber: "0283932",
          businessName: "OSSOFT LLC",
          validIDCard: "jjdsds",
          passport: "jjdsds",
          utilityBill: "jjdsds",
          cacDocument: "jjdsds",
        },
        truck: truckDetailsForm,
        bank: {
          accountNumber: "jjdsds",
          bankName: "jjdsds",
          accountName: "jjdsds",
          sortCode: "jjdsds",
        },
        driver: truckDriversDetailsForm,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
      }
    );
    if (result) {
      toast.success(`${result?.data?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate("/");
    }

    console.log("finalresult>>", result);
  };

  const data = [
    {
      id: 1,
      title: "Truck Host",
      // element: <HostDetails formDetails={formDetails} setFormDetails={setFormDetails}/>,
      element: (
        <HostDetails
          setForms={setHostForms}
          handleNextClick={handleNextClick}
        />
      ),
    },
    {
      id: 2,
      title: "Truck details",
      element: (
        <TruckDetails
          setForms={setTruckDetailsForms}
          handleNextClick={handleNextClick}
        />
      ),
    },
    {
      id: 3,
      title: "Truck documents",
      element: (
        <TruckDocument
          setForms={setTruckDocumentsForms}
          handleNextClick={handleNextClick}
        />
      ),
    },
    {
      id: 4,
      title: "Guarantor Detaills",
      element: (
        <TruckGuarantorDetails
          setForms={setTruckGuarantorsForms}
          handleNextClick={handleNextClick}
        />
      ),
    },
    {
      id: 5,
      title: "Truck Drivers details",
      element: (
        <TruckDriverDetails
          setForms={setTruckDriverDetailsForms}
          handleNextClick={handleNextClick}
          handleSkipClick={handleSkipClick}
        />
      ),
    },
    {
      id: 6,
      title: "Bank details",
      element: (
        <BankDetailsForTruck
          setForms={setTruckDetailsForms}
          handleNextClick={handleNextClick}
        />
      ),
    },
    {
      id: 7,
      title: "Declaration",
      element: <Declaration handleListAssetClick={handleListAssetClick}  setForms={setTruckDetailsForms}/>,
    },
  ];

  const handleClick = (id: number) => {
    setId(id);
  };
  useEffect(() => {
    // setId(inputId);
  }, []);
  console.log("formdetails", formDetails);
  console.log("formdetails", state);

  return (
    <div className=" flex flex-col min-h-screen pb-8 gap-2 items-center w-full ">
      {/* ............top bar............... */}
      <div className=" bg-gray-200 flex py-4 justify-evenly w-full ">
        <div className=" w-full rounded-full flex items-center justify-between max-w-[1140px] mx-auto p-4">
          {data?.map((item, i) => (
            <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => handleClick(i)}
            >
              <p
                className={`${
                  id === i ? "font-bold" : " "
                } border-2 border-gray-300 rounded-full px-2 bg-white`}
              >
                {item.id}
              </p>
              <p className={`${id === i ? "font-bold" : " "}  `}>
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="  w-full flex flex-col items-center">
        {data?.map((item, i) => (
          <div className="w-full flex flex-col items-center ">
            {id === i && item.element}
          </div>
        ))}
      </div>
      <div>
        {/* <button className=" bg-black text-white px-4 rounded-full">Submit</button> */}
      </div>
    </div>
  );
};

export default ListTruck;
