import React from 'react';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Truck1 from "../../assets/images/truck1.jpg";
import Truck2 from "../../assets/images/truck2.jpg";
import Truck3 from "../../assets/images/truck3.jpg"


const TruckType = () => {
  return (
    <div className="max-w-[1240px]">
      <h2 className="font-semibold text-xl ml-14 mt-7">Browse by category</h2>
      <div className="flex justify-between items-center space-x-2 mt-5 mb-10">
        <IoIosArrowDropleftCircle className="w-8 h-8"/>
        <img 
          src={Truck1} 
          alt="truck1" 
          className="rounded-3xl w-64 h-40" 
        />
        <img
          src={Truck2}
          alt="truck2"
          className="rounded-3xl w-64 h-40"
        />
        <img
          src={Truck3}
          alt="truck3"
          className="rounded-3xl w-64 h-40"
        />
        <img 
            src={Truck1} 
            alt="truck4" 
            className="rounded-3xl w-64 h-40" 
        />
        <IoIosArrowDroprightCircle className="w-8 h-8"/>
      </div>
    </div>
  )
}

export default TruckType