import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RxDividerVertical } from "react-icons/rx";
import { FaAngleRight } from "react-icons/fa6";
import { AiFillExclamationCircle } from "react-icons/ai";
import CancelbookingModal from "../../components/modal/CancelbookingModal";
import Modal from "../../components/modal/Modal";

const AwaitingDriver = () => {
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);

  const HandleCancelbookingModal = () => {
    setCancelModal((prev) => !prev);
  };
  const HandleCancelbooking = () => {
    setCancelModal(false);
    setConfirmCancelModal(true);
  };

  return (
    <div className=" flex flex-col min-h-screen py-8 gap-2 items-center w-full">
      <div className=" bg-gray-200 flex p-4 justify-evenly w-full  mt-[-32px]">
        <p>Toyota Camry</p>
        <div>
          <p>5 Ojodu Abiodun Street, off Isheri Road, Ojodu Berger</p>
          <p>Mon, Aug 24, 2020 | 07:00 am</p>
        </div>
        <FaAngleRight />
        <div>
          <p>256 Broad Street, Lagos Island</p>
          <p>Tue, Aug 25, 2020 | 07:00 pm</p>
        </div>
      </div>

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 gap-20 w-[85%]">
        <div className=" flex flex-col ">
          <div className="flex">
            <AiFillExclamationCircle className="mr-1 text-lime-500 w-6 h-6" />
            <h2 className="font-bold">
              Booking received, awaiting driver availabity!
            </h2>
          </div>
          <p className="text-sm mt-4 mb-4">
            Thanks John Smith for booking a driver with DMM GO.<br></br>A
            message has been sent to a driver close to you.
          </p>
          <hr />
          {/* details */}
          <div className=" flex flex-col justify-between">
            <div className=" flex  p-3 hover:border-orange-300 cursor-pointer space-x-16">
              <div className="text-sm">
                <p className="">Booking date:</p>
                <p className="font-semibold">Aug 24, 2020 </p>
                <p className="mt-2">Driver:</p>
                <p className="font-semibold">None assigned</p>
              </div>
              <div className="text-sm">
                <p className="">Booking Time</p>
                <p className="font-semibold">07:00 pm</p>
                <p>Status:</p>
                <p className="font-semibold">Pending</p>
              </div>
              <div className="text-sm">
                <p>Service type:</p>
                <p className="font-semibold">Take Me Home</p>
              </div>
            </div>
            <div className=" flex  p-2 hover:border-orange-300 cursor-pointer gap-4 mb-3">
              <button
                type="button"
                className="text-amber bg-white-700 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
                onClick={() => {
                  navigate("/driver/take-me-home");
                }}
              >
                Edit booking
              </button>
              <button
                type="button"
                className="text-red-600 bg-white-700 hover:shadow-md"
                onClick={HandleCancelbookingModal}
              >
                Cancel booking
              </button>
            </div>

            <hr />
            <p className="text-sm mt-3">
              We'll send a successful transaction confirmation to your provided
              contact<br></br>Kindly reach us on info@dmm.com for anything you
              might need us for
            </p>
            <p className="text-base font-bold mt-4">
              Thanks for booking with us.
            </p>
          </div>
        </div>

        <div className=" flex flex-col gap-2 rounded-md bg-gray-200 w-[25%] h-[60%] p-2">
          <div className="  p-2 border-b-2 border-b-white">
            <p className="  font-semibold">Order Summary</p>
          </div>
          <div className="  p-2 border-b-2 border-b-white">
            <p className="  font-semibold">User Details</p>
            <p className="text-xs">John Smith</p>
            <p className="text-xs">johnsmith@gmail.com</p>
            <p className="text-xs">0700 000 0000</p>
          </div>
          <div className="p-2">
            <p className="  font-semibold">Transaction Details</p>
            <div className="text-xs flex justify-between">
              <p>Trip fare per day</p>
              <p>N 0000000</p>
            </div>
            <div className="text-xs flex justify-between">
              <p>Trip fare per day</p>
              <p>N 0000000</p>
            </div>
            <div className="text-xs flex justify-between">
              <p>Extra request</p>
              <p>N 0000000</p>
            </div>
            <div className="flex justify-between font-medium mt-4">
              <p className="text-sm">Subtotal</p>
              <p className="text-xs">N 0000000</p>
            </div>
            <div className="text-xs flex justify-between gap-4">
              <p>WAT(7.5%)</p>
              <p>N 0000000</p>
            </div>
            <div className="  flex justify-between font-medium mt-4">
              <p>Total</p>
              <p>N 0000000</p>
            </div>
          </div>
        </div>
      </div>
      {/* <CancelbookingModal isOpen={cancelModal} /> */}
      <Modal isOpen={cancelModal} isCloseButton={true}>
        <div className="flex flex-col items-center">
          <h1 className="text-lg font-semibold mt-2 mb-2">Please confirm!</h1>
          <p>Are you sure you want to cancel this booking?</p>
          <div className="flex space-x-16 mt-5 mb-3">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={HandleCancelbooking}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-indigo-950 bg-white-700 border-indigo-900 border rounded-full px-10 py-1 hover:shadow-md"
              onClick={() => {
                setCancelModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={confirmCancelModal} isCloseButton={false}>
        <div>
          <h1 className="text-lg font-semibold mb-2">Booking cancelled!</h1>
          <p className="mt-2 mb-8">
            Your booking has been cancelled successfully.
          </p>
          <button
            type="button"
            className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md"
            onClick={() => {
              setConfirmCancelModal(false);
              navigate("/driver");
            }}
          >
            Close and go home
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AwaitingDriver;
