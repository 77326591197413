import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

type Option = {
  value: any;
  label: any;
};

interface FieldSelectProps {
  control: any;
  errors: any;
  options: Option[];
  label: string;
  fieldName: string;
  disabled?: boolean;
  onChange?: (value: any) => void; // Optional onChange handler
  errMessage?: string;
}

const FieldSelect: React.FC<FieldSelectProps> = ({
  control,
  options,
  errors,
  label,
  fieldName,
  disabled = false,
  onChange, // Receive onChange prop
  errMessage,
}) => {
  const errorMessage = errMessage || errors?.[fieldName]?.message;

  return (
    <FormControl fullWidth error={!!errorMessage}>
      <InputLabel sx={{ color: !!errorMessage ? "red" : "" }}>
        {label}
      </InputLabel>
      <Controller
        name={fieldName}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            label={label}
            value={
              field.value === undefined ||
              field.value === null ||
              options.length === 0
                ? ""
                : field.value
            }
            onChange={(e) => {
              field.onChange(e);

              if (onChange) {
                onChange(e.target.value);
              }
            }}
            disabled={disabled}
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
              },
            }}
          >
            {options.map((option: Option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {!!errorMessage && (
        <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FieldSelect;

export const booleanOptions = {
  Yes: "true",
  No: "false",
};
