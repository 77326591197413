import Audi from "../images/topdeals/AudiA6.jpg"
import Toyota from "../images/topdeals/AudiA6.jpg"
import Truck from "../images/truckdeal.jpg"

export interface CardProps {
  image: string | typeof Audi | typeof Truck
  price: string
  name: string
  smallButtonName: string
  bigButtonName: string
  id: number
  route: string
  navDestination?: string
  status: string
}

export const upcomingBookings: CardProps[] = [
  {
    name: "60 TONS Open Truck",
    image: Truck,
    price: "N00,00",
    smallButtonName: "Awaiting acceptance",
    bigButtonName: "View quote",
    route: "/profile/quotation",
    status: "upcoming",
    id: 0,
  },
  {
    name: "Audi A6",
    image: Audi,
    price: "N00,00",
    smallButtonName: "Awaiting activation",
    bigButtonName: "View booking",
    route: "/profile/booking-pending",
    status: "upcoming",
    id: 1,
  },
]
