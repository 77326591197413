import React from "react";
import Skeleton from "@mui/material/Skeleton";

const VehicleTypeSkeletonCard: React.FC = () => {
  return (
    <div className="w-full cursor-pointer flex justify-center">
      <div className="rounded-3xl w-[150px] lg:w-full h-[100px] lg:h-[170px] overflow-hidden relative">
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <div className="hidden lg:flex absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white justify-center p-2">
          <Skeleton variant="text" width={100} />
        </div>
      </div>
    </div>
  );
};

export default VehicleTypeSkeletonCard;
