import React, { ChangeEvent, FC, useState } from "react";
import { FormDetailsinterface } from "./FormDetailsInterface";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import axios from "axios";
import { onChange } from "@material-tailwind/react/types/components/select";

interface handleNextClickProps {
  handleNextClick: () => void;
  setForms: (inputFields: any) => void;
}

const HostDetails: FC<handleNextClickProps> = ({
  handleNextClick,
  setForms,
}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const { state, dispatch } = useAuth();
  const [name, setname] = useState("");
  const [inputFields, setInputFields] = useState({
    firstName: "",
    email: "",
    currentAddress: "",
    businessName: "",
    lastName: "",
    phoneNumber: "",
    rcNumber: "",
  });

  // const append = () => {
  //   // setFormDetails?.({name:name})
  //   // dispatch({
  //   //   type: "FORMDETAILS",
  //   // //   payload: { key: "girl", value: username },
  //   // });
  // };

  const [selectedFile, setSelectedFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (
    fieldName: any,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      // setSelectedFile(file);
      handleUpload(fieldName, file);
    }
  };

  const handleUpload = async (fieldName: any, file: any) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append("image", selectedFile);

      // Send a POST request to the ImgBB API to upload the image
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_REACT_APP_IMGBB_KEY}`,
        formData
      );

      // Handle the response from the API
      const { data } = response.data;
      setImageUrl(data.url);
      setInputFields({
        ...inputFields,
        [fieldName]: data.url,
      });
      console.log("Image url", data.url);

      // Save the URL details to your database
      // You can send another request to your backend server to save the URL details
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldName: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: e.target.value,
    });
  };
  const handleAppendForm = () => {
    setForms(inputFields);
  };
  const append = () => {
    // setFormDetails?.({name:name})
    dispatch({
      type: "FORMDETAILS",
      payload: { key: "girl", value: username },
    });
  };

  return (
    <div className="mx-auto flex flex-col gap-8  w-[1140px]  justify-between">
      <div className="flex flex-col gap-2">
        <h1 className=" font-semibold">Host Details</h1>
        <p className=" font-light">
          Please provide accurate deals to speed up vehicle verification.
        </p>
      </div>

      <div className=" flex justify-between gap-8">
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="First Name"
            onChange={(e) => handleOnChange(e, "firstName")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Email Address"
            onChange={(e) => handleOnChange(e, "email")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Current address"
            onChange={(e) => handleOnChange(e, "currentAddress")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Business name"
            onChange={(e) => handleOnChange(e, "businessName")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            Upload passport photo{" "}
            <span className=" text-gray-100">(image, pdf)</span>{" "}
            <input
              type="file"
              placeholder="Upload passport"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("upload Passport", e)}
            />
          </div>

          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-1">
            Upload CAC document{" "}
            <span className=" text-gray-100">(image, pdf)</span>{" "}
            <input
              type="file"
              placeholder="CAC Document"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("cac Document", e)}
            />
          </div>
        </div>
        <div className=" flex flex-col w-1/2 gap-4">
          <input
            type="text"
            placeholder="Last Name"
            onChange={(e) => handleOnChange(e, "lastName")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          <input
            type="text"
            placeholder=" Phone number"
            onChange={(e) => handleOnChange(e, "phoneNumber")}
            className=" border-2 rounded-lg p-2 mb-20"
          />
          <input
            type="text"
            placeholder=" RC number"
            onChange={(e) => handleOnChange(e, "rcNumber")}
            className=" border-2 mb-2 rounded-lg p-2"
          />
          {/* <input type="text" placeholder=" Business name"  className=" border-2 mb-2 rounded-lg p-2" /> */}
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-">
            Upload valid ID
            <span className=" text-gray-100">(image, pdf)</span>{" "}
            <input
              type="file"
              placeholder=" Valid ID"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("valid id", e)}
            />
          </div>
          <div className=" border-2 mb-2 rounded-lg  flex justify-between gap-6 p-2 pr-">
            Upload utility bill
            <span className=" text-gray-100">(image, pdf)</span>
            <input
              type="file"
              placeholder="Utility bill"
              className="  rounded-lg w-[15rem] border-2"
              onChange={(e) => handleFileChange("utility bill", e)}
            />
          </div>
        </div>
      </div>

      {/* <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        required
        placeholder="Email or phone number"
      /> */}
      <div>
        <button
          className=" bg-black text-white px-5 py-1 rounded-full"
          onClick={() => {
            handleNextClick();
            handleAppendForm();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default HostDetails;
