import React, { useEffect, useState } from "react";
import MobileDealCards from "../card/MobileDealCards";
import WebDealCards from "../card/WebDealCards";

export type Deal = {
  id: string;
  carImages: string[];
  carMakeID: { make?: string; mode?: string; year?: number };
  carCode: string;
  serviceTypes: { price: number }[];
  location?: any;
  numberOfDoors: number | string;
  carSeries: string;
  dmmRating: number;
  city: string;
  address: string;
  _id: string;
};

interface Props {
  items: Deal[];
}

const TodaysTopDeals: React.FC<Props> = ({ items }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  const calculateItemsPerPage = () => {
    return window.innerWidth < 1024 ? 2 : 3;
  };

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener("resize", handleResize);

    setItemsPerPage(calculateItemsPerPage());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const showNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const showPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems: Deal[] = items.slice(startIndex, endIndex);

  return (
    <div className="w-full mx-auto mt-[3rem] max-w-[1480px] max-lg:px-6">
      <WebDealCards
        showPreviousPage={showPreviousPage}
        itemsPerPage={itemsPerPage}
        displayedItems={displayedItems}
        showNextPage={showNextPage}
      />

      <MobileDealCards
        showPreviousPage={showPreviousPage}
        currentPage={currentPage}
        endIndex={endIndex}
        items={items}
        itemsPerPage={itemsPerPage}
        displayedItems={displayedItems}
        showNextPage={showNextPage}
      />
    </div>
  );
};

export default TodaysTopDeals;
