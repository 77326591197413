import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBox from "../../components/searchbox/SearchBox";
import DmmFilter from "../../components/filter/DmmFilter";
import CarRentalFilter from "../../components/filter/CarRentalFilter";
import { useDataContext } from "../../providers/DataProvider";
import CarCard from "../../components/card/CarCard";
import { DmmSpinner } from "../../components/Spinner";

const MIN_PRICE = 0;
const MAX_PRICE = 1_000_000;

const CarRental = () => {
  const { searchData, ref, loading, hasMore } = useDataContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const vehicleTypes = queryParams.get("vehicleType")?.split(",");
  const isNegotiable = queryParams.get("negotiable");
  const isSelfDrive = queryParams.get("selfDrive");
  const minPrice = queryParams.get("min");
  const maxPrice = queryParams.get("max");
  const carYear = queryParams.get("year");
  const serviceType = queryParams.get("serviceType");
  const displayNumber = isFiltered
    ? filteredItems.length
    : searchData.numberOfItems;

  useEffect(() => {
    const filterData = (data: any[]) => {
      let filteredData = data;

      if (vehicleTypes) {
        filteredData = filteredData.filter((item) =>
          vehicleTypes.every((type) => item.vehicleType.includes(type))
        );
      }

      if (isNegotiable) {
        const negotiableFlag = isNegotiable === "true";
        filteredData = filteredData.filter(
          (item) => item.allowClientOfferPrice === negotiableFlag
        );
      }

      if (isSelfDrive) {
        const selfDriveFlag = isSelfDrive === "true";
        filteredData = filteredData.filter(
          (item) => item.canGuestDriveYourCar === selfDriveFlag
        );
      }

      if (minPrice || maxPrice) {
        filteredData = filteredData.filter((item) => {
          const selectedService = item.serviceTypes.find(
            (service: { name: any }) => service?.name === serviceType
          );
          const selectedServicePrice = selectedService?.price;

          return (
            selectedServicePrice >= parseInt(`${minPrice || MIN_PRICE}`) &&
            selectedServicePrice <= parseInt(`${maxPrice || MAX_PRICE}`)
          );
        });
      }

      if (carYear) {
        filteredData = filteredData.filter(
          (item) => item.carMakeID.year >= parseInt(carYear)
        );
      }

      return filteredData;
    };

    if (
      vehicleTypes ||
      isNegotiable ||
      isSelfDrive ||
      minPrice ||
      maxPrice ||
      carYear
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    setFilteredItems(filterData(searchData.data));
  }, [
    vehicleTypes,
    serviceType,
    isNegotiable,
    isSelfDrive,
    minPrice,
    maxPrice,
    carYear,
    searchData,
  ]);

  return (
    <div className="max-w-[1480px] w-full flex flex-col items-center justify-center mx-auto">
      <div className="w-full">
        <div className="flex mb-4 bg-[#F2F3F5] py-4 justify-center items-center w-full min-h-[100px]">
          <div className="w-full max-w-[90%]">
            <SearchBox
              buttonTitle={"Edit"}
              searchBoxClasses={{
                buttonClass: "bg-white border border-black text-black",
              }}
              outsideButton
              showMobileVersion
            />
          </div>
        </div>
      </div>

      <div className="w-full lg:max-w-[90%] items-center mx-auto lg:pt-10 pb-10">
        <div className="lg:flex lg:flex-row flex flex-col lg:justify-between lg:gap-5 w-full">
          <DmmFilter
            showMobileFilter={showMobileFilter}
            setShowMobileFilter={setShowMobileFilter}
          >
            <CarRentalFilter
              setShowMobileFilter={setShowMobileFilter}
              displayNumber={displayNumber}
            />
          </DmmFilter>

          {/* ........right component.......... */}
          <div
            className={`${
              showMobileFilter ? "hidden lg:block" : "block"
            } md:flex-1 w-full`}
          >
            <div className="hidden lg:block">
              {displayNumber > 0 && (
                <p className="font-medium text-xl mb-4">{`${
                  displayNumber > 99 ? "99+" : displayNumber
                } ${displayNumber === 1 ? "car" : "cars"} available`}</p>
              )}
            </div>

            <div className="md:w-full flex-1">
              <div className="lg:hidden flex w-full justify-between items-center px-6 mb-3">
                <div>
                  {displayNumber > 0 && (
                    <p className="font-medium text-lg">{`${
                      displayNumber > 99 ? "99+" : displayNumber
                    } ${displayNumber === 1 ? "car" : "cars"} available`}</p>
                  )}
                </div>

                <div
                  className="w-[70px] text-center px-4 py-1 border border-1 border-black rounded-full text-[13px] cursor-pointer"
                  onClick={() => {
                    setShowMobileFilter(true);
                  }}
                >
                  Filter
                </div>
              </div>

              <div className="flex md:grid lg:flex grid-cols-2 flex-col px-6 lg:px-0 gap-4 lg:gap-10 w-full">
                {/* ..........LIST OF CARS........ */}
                {filteredItems?.map((item, idx) => (
                  <CarCard key={idx} item={item} queryParams={queryParams} />
                ))}
              </div>

              {filteredItems?.length === 0 && !hasMore && (
                <div className="flex items-center justify-center w-full rounded-[3rem] gap-2 h-[80%]">
                  No Car found!
                </div>
              )}
            </div>

            <div
              ref={ref}
              className="flex justify-center items-center w-full my-4"
            >
              {loading && <DmmSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarRental;
