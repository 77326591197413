// Modal.tsx
import React, { ReactNode, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: String;
  isCloseButton: boolean;
  classNames?: String;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  isCloseButton,
  classNames,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    // Add your login logic here
    console.log("Logging in with:", username, password);
  };

  return (
    // The modal backdrop
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-black ${
        isOpen ? "" : "hidden"
      }`}
    >
      {/* The modal content */}
      <div
        className={`bg-white p-8 min-w-80 rounded-2xl shadow-md ${classNames} `}
      >
        <div className="">
          <div className=" flex justify-between">
            <p className="font-bold text-xl">{title}</p>
            {isCloseButton && (
              <button
                className="  text-gray-500 hover:text-gray-700 border-2 rounded-full px-2 shadow-md"
                onClick={onClose}
              >
                X
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
