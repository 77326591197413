import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/elements/CustomButton";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { formatDate } from "../car-rental/CarRentalBooking";
import { formatCurrency } from "../../utils/stringFunctions";
import { useData, useIsMobile } from "../../Hooks/useData";
import { useAuth } from "../../context/authContext";
import { TbCircleDotted } from "react-icons/tb";
import DmmDialog from "../../components/modal/DmmDialog";
import Summary from "../../components/bookingSummary.tsx/Summary";
import OrderSummary from "../../components/bookingSummary.tsx/OrderSummary";
import { REACT_TOAST_CONFIG } from "../../utils/utilsFunctions";
import { DmmSpinner } from "../../components/Spinner";
import { useUserStatus } from "../../context/userStatusContext";

const OngoingBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { userData } = useData();
  const role = userData?.role?.name;
  const {userStatus} = useUserStatus();
  const [allServiceTypeData, setAllServiceTypeData] = useState<any[]>([]);
  const [driver, setDriver] = useState<any>({});
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [carCity, setCarCity] = useState<any>("");
  const [isActivate, setIsActivate] = useState(false);
  const [code, setCode] = useState<String>(" ");
  const [confirmSendPinLoading, setConfirmSendPinLoading] = useState(false);
  const [fulfillBooking, setFulfillBooking] = useState(false);
  const [declineReasonModal, setDeclineReasonModal] = useState(false);
  const { state, dispatch } = useAuth();
  const isMobile = useIsMobile();

  const HandleSendPin = async () => {
    // setSendPin(true);
    setConfirmSendPinLoading(true);
    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/generateEndBookingCode/${state.userId}/${data?._id}`,
        {}
      );

      if (!response.data?.isError === true) {
        setConfirmSendPinLoading(false);

        toast.success("Pin successful sent", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });

        // navigate("/car/car-rental-booking", {state:response?.data?.data?._id});
        // onClose();
        setDeclineReasonModal(false);
        // navigate("");
      } else {
        toast.error("An error occured", REACT_TOAST_CONFIG);
        setConfirmSendPinLoading(false);
      }
    } catch (error: any) {
      toast.error(error?.message, REACT_TOAST_CONFIG);
      setDeclineReasonModal(false);
      setConfirmSendPinLoading(false);
    }
  };

  const HandleActivateBooking = async () => {
    setFulfillBooking(true);
    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/fulfillBooking/${data?._id}/${state.userId}/${code}`,
        {}
      );

      if (!response.data?.isError === true) {
        // setBookLoading(false);

        toast.success("Booking Activated", REACT_TOAST_CONFIG);

        // navigate("/car/car-rental-booking", {state:response?.data?.data?._id});
        // onClose();
        setDeclineReasonModal(false);
        navigate("/profile/bookings");
      } else {
        toast.error("Invalid pin", REACT_TOAST_CONFIG);
      }
    } catch (error) {
      setDeclineReasonModal(false);
      toast.error("Invalid Booking Pin Code", REACT_TOAST_CONFIG);
      setFulfillBooking(false);
    }
    setFulfillBooking(false);
  };

  const HandleFulfill = async () => {
    setFulfillBooking(true);
    try {
      const response = await axios.put(
        `https://dmm-backend-f562.onrender.com/api/booking/fulfillBooking/${data?._id}`,
        {}
      );

      if (!response.data?.isError === true) {
        // setBookLoading(false);
        setFulfillBooking(false);

        toast.success("Booking fullfilled", REACT_TOAST_CONFIG);

        // navigate("/car/car-rental-booking", {state:response?.data?.data?._id});
        // onClose();
        // setDeclineReasonModal(false);
        // navigate("");
      }
    } catch (error) {
      // setDeclineReasonModal(false);
      setFulfillBooking(false);
      toast.error("Booking fullfilled", REACT_TOAST_CONFIG);
    }
  };

  useEffect(() => {
    setCarCity(data?.data?.city);
    setServiceTypeData(data?.carId?.serviceTypes);
    // const fetchCarDetails = async () => {
    //   const { data } = await axios.get(baseUrl + endPoint);
    //   // console.log("result", data.data);
    //   setTopDeals(data.data);
    //   setItems(data.data);
    // };

    const fetchDriver = async () => {
      try {
        const response = await axios.get(
          `https://dmm-backend-f562.onrender.com/api/host/getDriverDetails/${data?.carId?._id}/${data?.hostId}`
        );
        console.log("fetch Driver---->", response.data);
        setDriver(response.data.data);
        // setAllServiceTypeData(response?.data?.data);
        // setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchServiceType = async () => {
      const response = await axios.get(
        "https://dmm-backend-f562.onrender.com/api/general/getServiceTypes"
      );
      setAllServiceTypeData(response?.data?.data);
      // setLoading(false);
    };

    // fetchCarDetails();
    fetchDriver();
    fetchServiceType();
  }, []);

  return (
    <div>
      <ToastContainer />

      <Summary data={data} />

      <div className="mx-auto md:flex md:flex-row flex flex-col justify-center mt-10 w-full max-w-[1480px] lg:gap-20 lg:mb-10">
        <div className="w-full flex flex-col px-6 lg:px-10">
          <div className="flex items-center mb-3 gap-2">
            <TbCircleDotted className="text-[40px] md:text-[50px]" />

            <h2 className="text-[20px] md:text-[25px] ml-2">
              Booking ongoing!
            </h2>
          </div>

          <p className="text-[15px] md:text-[18px] font-light mt-4 mb-4 py-3">
            Hi {data?.userId?.firstName} <span>{data?.userId?.lastName}</span>,
            the booking for{" "}
            <span className="font-medium">{data?.carId?.carMakeID?.make}</span>{" "}
            is ongoing.<br></br>
            Please check below for details.
          </p>

          <hr className="mb-3 mt-1" />

          {/* details */}
          <div className=" flex justify-between mb-4">
            <div className=" flex w-full flex-col md:flex-row py-3 hover:border-orange-300 cursor-pointer md:space-x-16">
              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Booking date:</p>

                  <p>
                    {formatDate(data?.bookingStartDate)} -{" "}
                    {formatDate(data?.bookingEndDate)}
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Driver:</p>

                  <p>
                    {driver?.firstName} {driver?.lastName}
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center">
                  <p className="font-light">Service type:</p>

                  <p className="">
                    {
                      allServiceTypeData.find(
                        (val) => val?._id === data?.serviceTypeId
                      )?.name
                    }
                  </p>
                </div>

                <div className="flex justify-between md:block items-center">
                  <p className="md:mt-4 font-light">Status:</p>

                  <p className="">{data?.status}</p>
                </div>
              </div>

              <div className="flex md:block text-[14px] md:text-[16px]">
                <div className="flex justify-between md:block items-center w-full">
                  <p className="font-light">Amount offered:</p>

                  <p className="">{formatCurrency(data?.bookingPrice)}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-start items-center gap-4">
            {role === "Host" &&  userStatus !== "you" &&
 (
              <>
                <button
                  type="button"
                  className="text-white bg-indigo-950 border-gray-500 border rounded-full w-full md:w-[30%] px-5 py-2 hover:shadow-md "
                  onClick={() => {
                    setIsActivate(true);
                  }}
                >
                  Fulfill
                </button>

                <CustomButton
                  loading={confirmSendPinLoading}
                  type="submit"
                  className="text-white bg-indigo-950 border-gray-500 border rounded-full w-full md:w-[30%] px-5 py-2 hover:shadow-md  "
                  onClick={HandleSendPin}
                >
                  Send Pin
                </CustomButton>
              </>
            )}
          </div>

          <hr className="my-7" />
        </div>

        <OrderSummary data={data} />
      </div>

      <DmmDialog
        open={isActivate}
        onClose={() => {
          setIsActivate(false);
        }}
        title={"Fulfill Booking"}
        fullScreen={isMobile}
      >
        <div className="flex flex-col">
          <h1 className="text-base  mb-2">Enter Pin</h1>
          <input
            type="number"
            className=" border-1 border rounded-lg p-2"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />

          <div className="flex space-x-6 mt-7 mb-3 w-full justify-center">
            <CustomButton
              loading={fulfillBooking}
              type="button"
              className="text-white w-full bg-indigo-950 rounded-full px-5 py-2 hover:shadow-md"
              onClick={() => {
                HandleActivateBooking();
              }}
              disabled={fulfillBooking}
            >
              {/* {fulfillBooking && <DmmSpinner size={25} />} */}
              Continue
            </CustomButton>
          </div>
        </div>
      </DmmDialog>
    </div>
  );
};

export default OngoingBooking;
