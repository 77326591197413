import _ from "lodash";

export const compareObjects = (obj1: any, obj2: any) => {
  if (_.isPlainObject(obj1) && _.isPlainObject(obj2)) {
    const diff: any = {};
    const keys = _.union(_.keys(obj1), _.keys(obj2));

    keys.forEach((key) => {
      if (key in obj1 && key in obj2) {
        if (!_.isEqual(obj1[key], obj2[key])) {
          const subDiff = compareObjects(obj1[key], obj2[key]);
          diff[key] = subDiff;
        }
      } else if (key in obj2) {
        diff[key] = obj2[key];
      }
    });

    return diff;
  } else if (!_.isEqual(obj1, obj2)) {
    return obj2;
  } else {
    return {};
  }
};
