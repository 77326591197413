import { Rating } from "@material-tailwind/react";
import React from "react";

interface CarDetailsRatingProps {
  title: string;
  review: string;
  stars: number;
  date: string;
  reviewer: string;
}

const CarDetailsRating: React.FC<CarDetailsRatingProps> = ({
  title,
  review,
  stars,
  date,
  reviewer,
}) => {
  return (
    <div className="my-3">
      <h4 className="text-lg font-normal mt-2 mb-2">{title}</h4>

      <p className="font-light text-[13px] md:text-[15px]">{review}</p>
      <div className="text-xs flex items-center mt-2 mb-2 space-x-3 h-full">
        <Rating value={stars} readonly className="text-[#EFCB76] text-xs" />
        <span className="text-xs font-light">{`${date} by ${reviewer}`}</span>
      </div>
    </div>
  );
};

export default CarDetailsRating;
