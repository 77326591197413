import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

interface GetInTouchModalProps {
  isOpen: boolean;
  onClose: () => void;
  cols?: string;
  rows?: string;
}

const GetInTouchModal: React.FC<GetInTouchModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    textArea: "",
  });

  const navigate = useNavigate();

  const [textareaValue, setTextareaValue] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [confirmMessageSentModal, setConfirmMessageSentModal] = useState(false);
  const [isGetInTouchModal, setIsGetInTouchModal] = useState(true);

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextareaValue(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    console.log("Form data submitted:", formData);
    onClose(); // Close the modal after submission
  };

  const handleMessageSent = () => {
    setIsGetInTouchModal(false);
    setConfirmMessageSentModal(true);
  };

  return (
    <>
      {isGetInTouchModal ? (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-black ${
            isOpen ? "" : "hidden"
          }`}
        >
          <div className="bg-white p-8 rounded-2xl ">
            <div className="">
              <h2 className="text-lg font-semibold mb-4">
                Get in touch for a more accurate price
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4 border border-gray-300 rounded-md">
                <textarea
                  //   type="textarea"
                  name="textarea"
                  placeholder="Enter your message"
                  value={formData.textArea}
                  onChange={handleTextareaChange}
                  cols={30}
                  rows={5}
                />
              </div>

              <div className="">
                <button
                  type="submit"
                  className="w-full bg-black text-white p-2  hover:bg-gray-700 focus:outline-none rounded-full mt-2"
                  onClick={handleMessageSent}
                >
                  Get in touch
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Modal isOpen={confirmMessageSentModal} isCloseButton={false}>
          <div>
            <h1 className="text-lg font-semibold mb-2">Message sent!</h1>
            <p className="mt-2 mb-8">
              Thank you for getting in touch. You will receive an<br></br>{" "}
              update via email.
            </p>
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md"
              onClick={() => {
                setConfirmMessageSentModal(false);
                navigate("/truck");
              }}
            >
              Close and go home
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GetInTouchModal;
