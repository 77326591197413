import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface FieldSwitchProps {
  control: any;
  label: string;
  fieldName: string;
}

const FieldSwitch: React.FC<FieldSwitchProps> = ({
  control,
  label,
  fieldName,
}) => {
  return (
    <div className="flex justify-start items-center w-full px-4 h-[60px] border border-1 border-[#BABABA] rounded-[8px]">
      <Controller
        name={fieldName}
        control={control}
        defaultValue={false} // Ensure default value is set
        render={({ field }) => (
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                {...field}
                checked={!!field.value} // Ensure boolean value
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            label={label}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: { xs: 14, md: 16 }, // Change font size here
                color: "#565656",
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default FieldSwitch;
