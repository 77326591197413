import { ReactNode } from "react";
import Truck from "../../assets/images/truckdeal.jpg";
import { IoIosStar } from "react-icons/io";

export interface TruckCardProps {
  image: string;
  location: string;
  icon: typeof IoIosStar;
  rating: number;
  name: string;
  id: number;
}

export const truckTopDeals: TruckCardProps[] = [
  {
    name: "60 TONS Open Truck",
    image: Truck,
    location: "Abuja",
    icon: IoIosStar,
    rating: 4.5,
    id: 0,
  },

  {
    name: "60 TONS Open Truck",
    image: Truck,
    location: "Abuja",
    icon: IoIosStar,
    rating: 4.5,
    id: 1,
  },
  {
    name: "60 TONS Open Truck",
    image: Truck,
    location: "Abuja",
    icon: IoIosStar,
    rating: 4.5,
    id: 2,
  },
];
