import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Option {
  id?: string;
  name: string;
  description?: string;
}

interface DmmSelectProps {
  handleChange: (event: SelectChangeEvent<string>) => void;
  options: Option[];
  value?: string;
  applyToolTip?: Boolean;
}

const DmmSelect: React.FC<DmmSelectProps> = ({
  handleChange,
  options,
  value,
  applyToolTip = true,
}) => {
  return (
    <div>
      <FormControl variant="standard" className="w-full">
        <Select
          value={options.length === 0 ? "" : value}
          onChange={handleChange}
          disableUnderline
          displayEmpty
          renderValue={(value: string) => (value === "" ? "Select" : value)}
          sx={{
            "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
              fontWeight: 300,
              fontSize: 15,
            },
          }}
        >
          {options.map((data, index) => (
            <MenuItem key={data?.id || index} value={data?.name}>
              <span className="w-[95%]">{data?.name}</span>
              {applyToolTip && (
                <Tooltip
                  title={data?.description || ""}
                  arrow
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "light",
                        marginLeft: "50px",
                        width: "200px",
                        boxShadow: "0px 0px 0px 1px lightgray",
                        fontSize: 15,
                      },
                    },
                    arrow: {
                      sx: {
                        "&:before": {
                          border: "1px solid lightgray",
                        },
                        color: "white",
                      },
                    },
                  }}
                >
                  {/* <span className=" w-full ">{data?.name}</span> */}
                  <span className="w-fit flex justify-end ml-5">
                    <InfoOutlinedIcon
                      sx={{ color: "lightgray", fontSize: 19 }}
                    />
                  </span>
                </Tooltip>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DmmSelect;
