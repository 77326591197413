export const serviceTypes = [
  {
    _id: "65e115e81a2eceb4eea73832",
    name: "Full Day",
    isReturned: true,
    isQuoteRequired: false,
    description: "Vehicle rental service between 7 am - 7 pm",
  },
  {
    _id: "65e116001a2eceb4eea73836",
    name: "Airport Pickup",
    isReturned: false,
    isQuoteRequired: false,
    description: "One-way trip from your pickup location to the Airport",
  },
  {
    _id: "65e1160f1a2eceb4eea73838",
    name: "Airport Dropoff",
    isReturned: false,
    isQuoteRequired: false,
    description: "One-way trip from your pickup location to the Airport",
  },
  {
    _id: "65e1161b1a2eceb4eea7383a",
    name: "Evening Hangout",
    isReturned: false,
    isQuoteRequired: false,
    description: "Chauffeur service in your car of choice between 6 pm - 11 pm",
  },
  {
    _id: "65e116261a2eceb4eea7383c",
    name: "Goods Transportation",
    isReturned: true,
    isQuoteRequired: true,
    description: "Moving houses or goods from point A to point B",
  },
  {
    _id: "65e116371a2eceb4eea7383e",
    name: "Inter-state Travel",
    isReturned: true,
    isQuoteRequired: true,
    description: "Trips to destinations outside of your current state",
  },
  {
    _id: "65e116441a2eceb4eea73840",
    name: "Short-term rental",
    isReturned: true,
    isQuoteRequired: true,
    description: "Rental services for less than 3 months",
  },
  {
    _id: "65e116571a2eceb4eea73842",
    name: "Mid/Long-term rental (3 months - 1 year)",
    isReturned: true,
    isQuoteRequired: true,
    description: "Rental services for less than 6 months",
  },
  {
    _id: "65e116621a2eceb4eea73844",
    name: "Short-term rental (1-3 months)",
    isReturned: true,
    isQuoteRequired: true,
    description: "Rental services for more than 6 months",
  },
];

export const serviceTypesWithQuoteRequired = serviceTypes
  .filter((service) => service.isQuoteRequired)
  .map((service) => service.name);

export const serviceTypesWithId = serviceTypes.map(
  (service: any, index: number) => ({
    ...service,
    uniqueId: `service_${String(index + 1).padStart(3, "0")}`,
  })
);

export const acceptedIDs = [
  "International passport",
  "Voters card",
  "National ID card",
  "Drivers license",
];

export const specialFeatures: string[] = [
  "Airbags",
  "USB input",
  "Bulletproof",
  "Electric Vehicle (EV)",
  "Heated seat",
  "Backup camera",
  "Power windows",
  "GPS",
  "ABS",
  "Panoramic roof",
  "Leather seats",
  "DVD System",
  "Parking sensors",
  "7-Seater SUV",
  "Parking cameras",
  "Night Vision",
  "Navigation",
  "Android Auto",
  "Apple CarPlay",
  "Long-term Car",
  "Tinted windows",
];

export const vehicleTypes: string[] = [
  "Sedan",
  "SUV (Sport Utility Vehicle)",
  "Classic",
  "Sports Car",
  "Super Deluxe & Luxury",
  "Special Purpose Vehicles",
  "Buses & Minivans",
  "Pickup Truck",
];

export const exteriorColors: string[] = [
  "Black",
  "White",
  "Gray",
  "Silver",
  "Red",
  "Blue",
  "Brown",
  "Green",
  "Other color",
];

export const interiorColors: string[] = [
  "Black",
  "White",
  "Gray",
  "Beige",
  "Red",
  "Oxblood",
  "Cream",
  "Brown",
  "Red and Black",
  "Other color",
];

export const OTHERS_SPECIFY_MESSAGE = "Other color";

export const daysAvailable: { [key: number]: string } = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
};

export const noticePeriod = [
  "Instant booking",
  "4 hours",
  "12 hours",
  "1 day",
  "2 days",
];

export const driversLanguage: { [key: string]: string } = {
  0: "English",
  1: "Igbo",
  2: "Hausa",
  3: "Yoruba",
  4: "French",
};
