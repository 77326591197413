import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import Truck from "../../assets/images/truckhommepage.jpg";
import { FaTruck } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { LiaPaperPlane } from "react-icons/lia";
import MobileApp from "../../components/mobileapp/MobileApp";
import Newsletter from "../../components/newsletter/Newsletter";
import TopOffer from "../../components/top-offers/Topoffer";
import TruckType from "../../components/card/TruckType";
import Truck4 from "../../assets/images/truck4.jpg";
import TruckTopDeal from "../../components/topdeal/TruckTopDeal";
import Modal from "../../components/modal/Modal";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import GetInTouchModal from "../../components/modal/GetInTouchModal";
import SteeringWheel from "../../assets/images/steering-wheel.svg";
import VectorSmartObject from "../../assets/images/VectorSmartObject.svg";
import VectorSmartObject1 from "../../assets/images/VectorSmartObject1.svg";
import Booking, { Step } from "../../components/bookingSteps/Booking";

interface GetInTouchModalProps {
  isOpen: boolean;
  onClose: () => void;
  rows: number;
}

const data = [
  { id: 1, value: "truck1" },
  { id: 2, value: "truck2" },
  { id: 3, value: "truck3" },
];

const TruckLandingPage = () => {
  const [type, setType] = useState<String>(" ");
  const navigate = useNavigate();
  const [QuoteModal, setQuoteModal] = useState(false);
  const [GetinTouchModal, setGetinTouchModal] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [isGetInTouchModalOpen, setIsGetInTouchModalOpen] = useState(false);

  // const [confirmQuoteModal, setConfirmQuoteModal] = useState(false);

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
  };

  const handleRequestQuoteModal = () => {
    setQuoteModal((prev) => !prev);
  };

  const handleRequestQuote = () => {
    setQuoteModal(false);
    // setConfirmQuoteModal(true);
  };

  const handleGetinTouch = () => {
    setGetinTouchModal(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleGetInTouchModalClose = () => {
    setIsGetInTouchModalOpen(false);
  };

  const handleQuoteClose = () => {
    setQuoteModal(false);
    setIsGetInTouchModalOpen(true);
  };

  const truckHireSteps: Step[] = [
    {
      img: SteeringWheel,
      title: "Find The Best Deals",
      description:
        "Pick the perfect automobile that matches your needs and taste! Browse through thousands of amazing vehicles shared by local hosts close to you.",
    },
    {
      img: VectorSmartObject,
      title: "Book on DMM",
      description:
        "Book on DMM for peace of mind and guaranteed quality cars! Free cancellations up to 24 hours before your trip",
    },
    {
      img: VectorSmartObject1,
      title: "You're Done!",
      description:
        "Have the vehicle delivered or pick up from your host. Check in with DMM, grab the keys, get ready and hit the road.",
    },
  ];

  return (
    <div className="mx-auto">
      <div
        className="relative mt-18 h-screen flex flex-col justify-end pb-14 w-full items-center"
        style={{
          backgroundImage: `url(${Truck})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {/* <img src={Bentley} alt="homepage bg" className="" /> */}
        <div className="flex flex-col gap-4 font-semibold items-center justify-center w-full">
          <h1 className="absolute text-4xl text-white text-left font-semibold bottom-[45%] left-[20%] w-full pl-24">
            Welcome to DMM Marketplace
          </h1>

          {/* ..............................services menu............................ */}
          <div className="absolute w-full overflow-x-auto overflow-y-hidden  min-w-[300px] bottom-[36%]">
            <div className=" ">
              <ul className="flex  font-normal md:text-lg items-center justify-center border-blue-600  ">
                <li className=" ">
                  <Link
                    className={
                      "block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/car"}
                  >
                    Car Rental
                  </Link>
                </li>
                <li className=" ">
                  <Link
                    className={
                      "block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/jet"}
                  >
                    Private Jet
                  </Link>
                </li>
                <li className=" ">
                  <Link
                    className={
                      "block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/boat"}
                  >
                    Boat Charter
                    <div className=" text-black " />
                  </Link>
                </li>
                <li className=" ">
                  <Link
                    className={
                      "block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/driver"}
                  >
                    Hire Drivers
                    <div className=" text-black " />
                  </Link>
                </li>
                <li className=" ">
                  <Link
                    className={
                      "block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/helicopter"}
                  >
                    Helicopter Charter
                    <div className=" text-black " />
                  </Link>
                </li>
                <li className=" ">
                  <Link
                    className={
                      " block py-2 pl-3 pr-4 text-white rounded-full   hover:text-black hover:bg-[#efcb76]  dark:text-white text-center"
                    }
                    to={"/truck"}
                  >
                    Truck Hire
                    <div className=" text-black " />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* ..............................input fields............................ */}
          <div className="absolute bg-white p-2 rounded-3xl bottom-[10%]">
            <h1 className="mt-2 mb-2 ml-2">Haulage details</h1>
            <div className=" flex items-center justify-between gap-8">
              <div className="hidden md:flex bg-white items-center justify-center rounded-full border border-black-300  px-2 py-2 gap-4 mb-2">
                <div className="bg-zinc-100 rounded-full  p-2 flex flex-col justify-center items-center text-center">
                  <p className="text-xs">Pickup location</p>

                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="bg-transparent rounded-full text-center text-xs"
                  />
                </div>

                <div className="  p-2 px-0 flex flex-col justify-center items-center text-center border-r-2">
                  <p className="text-xs">Dropoff location</p>

                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="bg-transparent rounded-full text-center text-xs"
                  />
                </div>

                <div className=" w-fit ">
                  <p className="text-xs">Service Type</p>
                  <select title="service" name="" id="">
                    <option value="">Select </option>
                    {data?.map((items, i) => (
                      <option value="">{items?.value} </option>
                    ))}
                  </select>
                </div>

                <div className=" w-fit border-l-2 pl-2">
                  <p className="text-xs">Pick-up & time</p>
                  <input title="date" type="date" />
                </div>

                {/* <div className=" w-fit ">
                <p className="text-xs">Return Date & time</p>
                <input title="date" type="date" />
              </div> */}

                <div className="">
                  <p className="text-xs">Truck Type</p>
                  <select title="transmission" name="" id="">
                    <option value="">Select </option>
                    {data?.map((items, i) => (
                      <option value="">{items?.value} </option>
                    ))}
                  </select>
                </div>
                {/* <div className="">
                <p className="text-xs">Vehicle Brand</p>
                <select
                  name=""
                  title="vehicle"
                  id=""
                  onChange={(e) => {
                    handleTypeChange(e);
                  }}
                >
                  <option value="">Select </option>
                  {data?.map((items, i) => (
                    <option value="">{items?.value} </option>
                  ))}
                </select>
              </div> */}
              </div>
              <div>
                <Button
                  className="rounded-full bg-blue-950 px-10 py-4"
                  onClick={handleRequestQuoteModal}
                >
                  Request quote
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Booking steps={truckHireSteps} />

      <div className="mx-auto flex justify-center items-center">
        <TruckTopDeal />
      </div>

      <div className="mx-auto flex justify-center items-center p-10">
        <TruckType />
      </div>

      <div className=" flex max-w-[1240px] h-56 mb-10 mx-auto p-5">
        <div className="relative w-1/2">
          <img
            src={Truck4}
            alt=""
            className="absolute w-full h-full rounded-l-3xl"
          />
          <div className="absolute bg-gradient-to-l from-gray-900 h-full w-full"></div>
        </div>
        <div className="bg-gray-900 text-white w-1/2 rounded-r-3xl p-6">
          <h3 className="text-lg mt-2">List your Truck</h3>
          <p className="text-sm mt-2">
            Earn big on your Truck! Sublease your truck and earn while you
            <br></br>rest. We manage and run it on your behalf.
          </p>
          <Button
            className="rounded-full text-black bg-[#efcb76] px-10 py-2 mt-4 mb-4"
            onClick={() => {
              navigate("/truck/list-truck");
            }}
          >
            Post
          </Button>
        </div>
      </div>

      <MobileApp />
      <Newsletter />
      <TopOffer />

      <Modal isOpen={QuoteModal} isCloseButton={false}>
        <div>
          <div>
            <div className="flex justify-between">
              <p className="text-xs">Your shipment will cost</p>
              <div className="flex items-center space-x-2">
                <p className="text-xs">Tax exclusive</p>
                <AiOutlineExclamationCircle className="text-xs" />
              </div>
            </div>
            <h1 className="text-xl font-bold mb-3">N00,000</h1>
          </div>
          <hr />
          <div className="mt-3 mb-4">
            <div className="text-xs flex justify-between mb-2">
              <p>Pickup Location</p>
              <p className="font-semibold">Lagos</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Drop off Location</p>
              <p className="font-semibold">Abuja</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Commodity</p>
              <p className="font-semibold">Cement</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Capacity</p>
              <p className="font-semibold">20 tonnes</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Truck Type</p>
              <p className="font-semibold">Flatbed</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Haulage distance</p>
              <p className="font-semibold">15km</p>
            </div>
            <div className="text-xs flex justify-between mb-2">
              <p>Estimated haulage time</p>
              <p className="font-semibold">2 hours</p>
            </div>
          </div>
          <hr />
          <p className="text-xs mt-2 mt-4 mb-8">
            <span className="text-bold">Disclaimer:</span>Pricing quotes are
            only directional estimates and <br></br>
            may vary significantly due to various real time market conditions.
            <br></br>Get in touch for a more precise estimate.
          </p>
          <div className="flex flex-col items-center justify-center">
            <button
              type="button"
              className="text-white bg-indigo-950 border-gray-500 border rounded-full px-24 py-1 hover:shadow-md"
              onClick={handleQuoteClose}
            >
              Get in touch
            </button>
          </div>
        </div>
      </Modal>
      <GetInTouchModal
        isOpen={isGetInTouchModalOpen}
        onClose={handleGetInTouchModalClose}
      />
    </div>
  );
};

export default TruckLandingPage;
