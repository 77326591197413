import {
  Context,
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";

interface AuthProps {
  children: ReactNode;
}

interface PayloadInterface {
  key?: string;
  value?: string;
  lastName?: string;
  firstName?: string;
  userImage?: string;
  userId?: string;
}
interface AuthAction {
  type: string;
  payload?: PayloadInterface;
}

interface formDetailsInterface {
  name: string;
  [key: string]: string | undefined;
}
interface AuthState {
  isRegistered: boolean;
  isUserType: boolean;
  isLoggedInToggle: boolean;
  isSignUpToggle: boolean;
  isLoggedIn: boolean;
  formDetails: formDetailsInterface;
  lastName: string;
  firstName: string;
  userId: string;
}

const initialState: AuthState = {
  isRegistered: false,
  isUserType: false,
  isLoggedInToggle: false,
  isSignUpToggle: false,
  isLoggedIn: false,
  lastName: "",
  firstName: "",
  userId: "",

  formDetails: {
    name: " ",
  },
};

const reducer: React.Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case "TOGGLESIGNUP":
      return { ...state, isSignUpToggle: true };
    case "TOGGLESIGNUPOFF":
      return { ...state, isSignUpToggle: false };
    case "TOGGLELOGIN":
      return { ...state, isLoggedInToggle: true };
    case "TOGGLELOGINOFF":
      return { ...state, isLoggedInToggle: false };
    case "SIGNUP":
      return { ...state, isUserType: true };
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        firstName: action.payload?.firstName || "",
        lastName: action.payload?.lastName || "",
        userImage: action.payload?.userImage || "",
        userId: action.payload?.userId || "",
      };
    case "LOGOUT":
      localStorage.removeItem("lastName");
      localStorage.removeItem("firstName");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("UserData");
      return {
        ...state,
        isLoggedIn: false,
        firstName: "",
        lastName: "",
        userImage: "",
        userId: "",
      };
    case "USERTYPE":
      return { ...state, isUserType: !state.isUserType };
    case "FORMDETAILS":
      return {
        ...state,
        formDetails: {
          ...state.formDetails,
          [action.payload?.key as string]: action.payload?.value,
        },
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<
  { state: AuthState; dispatch: React.Dispatch<AuthAction> } | undefined
>(undefined);

const AuthProvider: React.FC<AuthProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId: string | undefined = localStorage.getItem("userId") as
      | string
      | undefined;
    const role = localStorage.getItem("role");
    const firstName: string | undefined = localStorage.getItem("firstName") as
      | string
      | undefined;
    const lastName: string | undefined = localStorage.getItem("lastName") as
      | string
      | undefined;
    // const role = localStorage.getItem("role")

    // isLoggedIn: false,

    if (token && !(token === "undefined") && userId) {
      (function () {
        try {
          dispatch({
            type: "LOGIN",
            payload: {
              firstName: firstName,
              lastName: lastName,
              userId: userId,
              userImage: "path/to/image.jpg",
            },
          });
        } catch (error) {
          if (role) {
            dispatch({
              type: "LOGOUT",
            });
            window.location.href = "/";
          } else {
            dispatch({
              type: "LOGOUT",
            });
            window.location.href = "/";
          }
        }
      })();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
export { AuthProvider, useAuth };
