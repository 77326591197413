import React from "react";
import Bentley from "../../assets/images/bentley_continental.jpg";
import SearchBox from "../searchbox/SearchBox";

const HeroSection = () => {
  return (
    <div
      className="relative bg-no-repeat h-[300px] md:h-[400px] lg:h-[600px] flex flex-col justify-end w-full items-center object-cover"
      style={{
        backgroundImage: `url(${Bentley})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-20"></div>

      <div className="flex flex-col gap-4 items-center justify-center order-4 w-full pb-5 z-10">
        <div className="w-full max-w-[1480px] h-full flex flex-col items-center p-2 gap-4">
          <SearchBox
            searchBoxClasses={{ buttonClass: "text-white" }}
            insideButton
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
