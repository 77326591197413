import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useData } from "../Hooks/useData";

interface userStatusProviderProps {
  children: ReactNode;
}
interface UserStatusContextType {
  userStatus: string;
  setUserStatus: React.Dispatch<React.SetStateAction<string>>;
  handleSetUserStatus: (val: string) => void;
}

const initialContext: UserStatusContextType = {
  userStatus: "",
  setUserStatus: () => {},
  handleSetUserStatus: (val: string) => {},
};

export const UserStatusContext = createContext(initialContext);

const UserStatusProvider: React.FC<userStatusProviderProps> = ({
  children,
}) => {
  const [userStatus, setUserStatus] = useState("you");
  const { userData } = useData();
  const role = userData?.role?.name;

  const handleSetUserStatus = (val: string) => {
    setUserStatus(val);
    sessionStorage.setItem("userStatus", val);
  };
  useEffect(() => {
    if(sessionStorage.getItem("userStatus") && role =="Host"){

      setUserStatus(sessionStorage.getItem("userStatus") as string);
    }
    else if( role =="Host"){

      setUserStatus("guest");
    }
    else{

      setUserStatus("you");
    }

  }, [role]);
  

  return (
    <UserStatusContext.Provider
      value={{ userStatus, setUserStatus, handleSetUserStatus }}
    >
      {children}
    </UserStatusContext.Provider>
  );
};

const useUserStatus = () => {
  const context = useContext(UserStatusContext);
  if (!context) {
    throw new Error("An error occured");
  }
  return context;
};
export { UserStatusProvider, useUserStatus };
