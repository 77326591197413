import React, { useState, useEffect } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import VehicleTypeSkeletonCard from "../skeletons/VehicleTypeSkeletonCard";
import { updateParams } from "../../utils/utilsFunctions";
import { useDataContext } from "../../providers/DataProvider";

const VehicleType = () => {
  const [loading, setLoading] = useState(false);
  const [vehicleData, setVehicleData] = useState<
    {
      id: number;
      index: number;
      name: string;
      imageUrl: string;
    }[]
  >([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    serviceType,
    fromWhereSelected,
    finalDestinationSelected,
    pickupTime,
    returnTime,
  } = useDataContext();

  useEffect(() => {
    const calculateItemsPerPage = () => {
      return window.innerWidth < 1024 ? vehicleData.length : 4;
    };

    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener("resize", handleResize);

    setItemsPerPage(calculateItemsPerPage());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vehicleData.length]);

  const totalPages = Math.ceil(vehicleData.length / itemsPerPage);

  const showNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const showPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = vehicleData?.slice(startIndex, endIndex);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://dmm-backend-f562.onrender.com/api/general/vehicleTypes"
        );
        setVehicleData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const handleVehicleTypeClick = async (vehicleType: any) => {
    const params = updateParams({
      serviceType,
      fromWhereSelected,
      finalDestinationSelected,
      pickupTime,
      returnTime,
      queryParams,
    });

    params.set("vehicleType", vehicleType);

    const url = `/car?${params.toString()}`;
    navigate(url);
  };

  return (
    <div className="w-full mx-auto my-2 max-w-[1480px] max-lg:px-6 flex">
      <div className="flex items-center justify-center flex-wrap w-full">
        <div className="flex justify-center items-center w-full lg:overflow-x-none overflow-x-auto gap-4">
          <button
            onClick={showPreviousPage}
            type="button"
            title="button"
            className="hidden lg:flex"
          >
            <IoIosArrowDropleftCircle className="w-10 h-10 text-[#BFC4CD]" />
          </button>

          <div className="w-full">
            <h2 className="font-medium text-xl lg:text-2xl text-left w-full">
              Browse by vehicle body type
            </h2>

            <div className="flex lg:grid grid-cols-4 justify-start lg:justify-center mt-5 mb-10 w-full overflow-x-auto gap-2 lg:gap-4 hide-scroll-bar">
              {loading
                ? Array.from({ length: itemsPerPage }).map((_, index) => (
                    <VehicleTypeSkeletonCard key={index} />
                  ))
                : displayedItems.map((type, index) => (
                    <div
                      className="w-full cursor-pointer flex justify-center"
                      key={type.id || index}
                      onClick={() => handleVehicleTypeClick(type.name)}
                    >
                      <div className="rounded-3xl w-[150px] lg:w-full h-[100px] lg:h-[170px] overflow-hidden relative">
                        <img
                          src={type.imageUrl}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                        <div className="hidden lg:flex absolute bottom-0 left-0 right-0 bg-black bg-opacity- text-white justify-center p-2">
                          <div className="text-center">{type.name}</div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>

          <button
            onClick={showNextPage}
            type="button"
            title="button"
            className="hidden lg:flex"
          >
            <IoIosArrowDroprightCircle className="w-10 h-10 text-[#BFC4CD]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleType;
